import { request, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/pm-rate-set-category-templates-pace'

export default {
  getAll (filter) {
    return request(`${url}${queryString.stringify({ filter: JSON.stringify(filter) })}`)
  },
  importFile (values) {
    return request(`${url}/file-start`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  listByPage (page, size, filter, sorting, text) {
    return request(`${url}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  saveAll (values) {
    return request(`${url}/all`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  }
}
