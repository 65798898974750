import { request, queryString } from '../../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/settings/files'
const urlSub = `${url}/sub`

export default {
  getAllFileCatDropdowns (filter, text) {
    return request(`${url}/dropdown${queryString.stringify({ filter: JSON.stringify(filter || {}), text })}`)
  },
  getFileCat (id) {
    return request(`${url}/${id}`)
  },
  addFileCat (values) {
    return request(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  saveFileCat (id, values) {
    return request(`${url}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  removeFileCat (id) {
    return request(`${url}/${id}`, {
      method: 'DELETE',
      headers
    })
  },
  listFileCatByPage (page, size, filter, sorting, text) {
    return request(`${url}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  getAllSubCatDropdowns (filter, text) {
    return request(`${urlSub}/dropdown${queryString.stringify({ filter: JSON.stringify(filter || {}), text })}`)
  },
  getFileCatSub (id) {
    return request(`${urlSub}/${id}`)
  },
  addFileCatSub (values) {
    return request(urlSub, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  saveFileCatSub (id, values) {
    return request(`${urlSub}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  removeFileCatSub (id) {
    return request(`${urlSub}/${id}`, {
      method: 'DELETE',
      headers
    })
  },
  listFileCatSubByPage (page, size, filter, sorting, text) {
    return request(`${urlSub}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
}
