import { TOGGLE_MODAL_PLAN_PERIOD } from '../actions/planPeriodPace'

const INITIAL_STATE = {
  planPeriod: {
    modal: {
      item: {},
      visible: false
    }
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_MODAL_PLAN_PERIOD:
      return { ...handleToggleModalPlanPeriod(state, action) }
    default:
      return state
  }
}

function handleToggleModalPlanPeriod(state, action) {
  const { planPeriod } = state
  const { modal } = planPeriod
  modal.visible = !modal.visible
  return { ...state, planPeriod: { ...planPeriod, modal } }
}
