import React, { Component, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'

import { Permissions } from '../../../../constants'
import { pmRateSetPaceService, pmRateSetCategoryPaceService } from '../../../../services'
import { auth, formatter, validator } from '../../../../util'
import debounce from 'lodash.debounce'

// UI
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Table from 'antd/lib/table'

import { Button, ControlLabel, Page, Panel, SearchInput } from '../../../../components'

import './styles.css'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const { Item: FormItem } = Form

const defaultPageSize = 50
const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 7 },
  wrapperCol: { sm: 14, md: 14, lg: 16 }
}

function CategoryForm ({ item, props }) {
  const { form } = props || {}
  const { getFieldDecorator } = form || {}

  return (
    <Panel>
      <Row>
        <Col lg={10}>
          <FormItem label='Rate Set' {...formItemLayout}>
            {getFieldDecorator('rate_set_name', {
              initialValue: item.rate_set_name
            })(<Input readOnly />)}
          </FormItem>
        </Col>
      </Row>

      <Row>
        <Col lg={10}>
          <FormItem label='Category Name' {...formItemLayout}>
            {getFieldDecorator('category_name', {
              initialValue: item.category_name,
              rules: [
                { min: 2, message: 'Name must be between 2 and 128 characters' },
                { max: 128, message: 'Name must be between 2 and 128 characters' },
                { whitespace: true, message: 'Please enter category name' }
              ]
            })(<Input readOnly />)}
          </FormItem>
        </Col>

        <Col lg={10}>
          <FormItem label='Group' {...formItemLayout}>
            {getFieldDecorator('category_group_name', {
              initialValue: item.category_group_name
            })(<Input readOnly />)}
          </FormItem>
        </Col>
      </Row>

      <Row>
        <Col lg={10}>
          <FormItem label='Category Number' {...formItemLayout}>
            {getFieldDecorator('category_number', {
              initialValue: item.category_number
            })(<Input readOnly />)}
          </FormItem>
        </Col>

        <Col lg={10}>
          <FormItem label='Funding Type' {...formItemLayout}>
            {getFieldDecorator('funding_type_label', {
              initialValue: item.funding_type_label
            })(<Input readOnly />)}
          </FormItem>
        </Col>
      </Row>
    </Panel>
  )
}

function getRateSetValuesColumns () {
  return Object.freeze([
    {
      dataIndex: 'item_name',
      key: 'item_name',
      title: 'Support Item',
      width: 300,
      fixed: 'left'
    },
    {
      dataIndex: 'item_number',
      key: 'item_number',
      title: 'Support Item Number',
      width: 180,
      fixed: 'left'
    },
    {
      dataIndex: 'start_date',
      key: 'start_date',
      title: 'Start Date',
      render: (start_date) => start_date ? formatter.toShortDate(start_date) : null
    },
    {
      dataIndex: 'end_date',
      key: 'end_date',
      title: 'End Date',
      render: (end_date) => end_date ? formatter.toShortDate(end_date) : null
    },
    {
      dataIndex: 'd0',
      key: 'd0',
      title: <div>ACT</div>,
      render: (d0) => <div>{d0 ? formatter.toPrice(d0, '') : ''}</div>
    },
    {
      dataIndex: 'd1',
      key: 'd1',
      title: <div>NSW</div>,
      render: (d1) => <div>{d1 ? formatter.toPrice(d1, '') : ''}</div>
    },
    {
      dataIndex: 'd2',
      key: 'd2',
      title: <div>NT</div>,
      render: (d2) => <div>{d2 ? formatter.toPrice(d2, '') : ''}</div>
    },
    {
      dataIndex: 'd3',
      key: 'd3',
      title: <div>QLD</div>,
      render: (d3) => <div>{d3 ? formatter.toPrice(d3, '') : ''}</div>
    },
    {
      dataIndex: 'd4',
      key: 'd4',
      title: <div>SA</div>,
      render: (d4) => <div>{d4 ? formatter.toPrice(d4, '') : ''}</div>
    },
    {
      dataIndex: 'd5',
      key: 'd5',
      title: <div>TAS</div>,
      render: (d5) => <div>{d5 ? formatter.toPrice(d5, '') : ''}</div>
    },
    {
      dataIndex: 'd6',
      key: 'd6',
      title: <div>VIC</div>,
      render: (d6) => <div>{d6 ? formatter.toPrice(d6, '') : ''}</div>
    },
    {
      dataIndex: 'd7',
      key: 'd7',
      title: <div>WA</div>,
      render: (d7) => <div>{d7 ? formatter.toPrice(d7, '') : ''}</div>
    },
    {
      dataIndex: 'd20',
      key: 'd20',
      title: <div>Remote</div>,
      render: (d20) => <div>{d20 ? formatter.toPrice(d20, '') : ''}</div>
    },
    {
      dataIndex: 'd30',
      key: 'd30',
      title: <div>Very<br />Remote</div>,
      render: (d30) => <div>{d30 ? formatter.toPrice(d30, '') : ''}</div>
    },
    {
      dataIndex: 'unit',
      key: 'unit',
      title: <div>Unit</div>
    },
    {
      dataIndex: 'is_quote',
      key: 'is_quote',
      title: <div>Quote?</div>,
      render: (is_quote) => is_quote
        ? <Icon className='rsp-icon active' type='check-circle' theme='filled' />
        : <Icon className='rsp-icon inactive' type='close-circle' theme='filled' />
    },
    {
      dataIndex: 'is_nf2f_support_provision',
      key: 'is_nf2f_support_provision',
      title: <div>Non-Face-<br />To-Face?</div>,
      render: (is_nf2f) => is_nf2f
        ? <Icon className='rsp-icon active' type='check-circle' theme='filled' />
        : <Icon className='rsp-icon inactive' type='close-circle' theme='filled' />
    },
    {
      dataIndex: 'is_provider_travel',
      key: 'is_provider_travel',
      title: <div>Provider<br />Travel?</div>,
      render: (is_provider_travel) => is_provider_travel
        ? <Icon className='rsp-icon active' type='check-circle' theme='filled' />
        : <Icon className='rsp-icon inactive' type='close-circle' theme='filled' />
    },
    {
      dataIndex: 'is_short_notice_cancel',
      key: 'is_short_notice_cancel',
      title: <div>Short Notice<br />Cancellations?</div>,
      render: (is_short_notice_cancel) => is_short_notice_cancel
        ? <Icon className='rsp-icon active' type='check-circle' theme='filled' />
        : <Icon className='rsp-icon inactive' type='close-circle' theme='filled' />
    },
    {
      dataIndex: 'is_ndia_requested_reports',
      key: 'is_ndia_requested_reports',
      title: <div>NDIA<br />Requested<br />Reports?</div>,
      render: (is_ndia_requested_reports) => is_ndia_requested_reports
        ? <Icon className='rsp-icon active' type='check-circle' theme='filled' />
        : <Icon className='rsp-icon inactive' type='close-circle' theme='filled' />
    },
    {
      dataIndex: 'is_irregular_sil_supports',
      key: 'is_irregular_sil_supports',
      title: <div>Irregular<br />SIL<br />Supports?</div>,
      render: (is_irregular_sil_supports) => is_irregular_sil_supports
        ? <Icon className='rsp-icon active' type='check-circle' theme='filled' />
        : <Icon className='rsp-icon inactive' type='close-circle' theme='filled' />
    }
  ])
}

function RateSetValuesTable ({ categoryId, rateSetId }) {
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [searching, setSearching] = useState(false)

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  const searchName = useCallback(() => {
    const onSearchName = async (value) => {
      setLoading(true)
      setSearching(true)

      try {
        const response = await pmRateSetPaceService.getAllValues({ category_id: categoryId, rate_set_id: rateSetId }, value)

        if (Array.isArray(response)) {
          setList(response)
        }
      } finally {
        setLoading(false)
        setSearching(false)
      }
    }
    return debounce(onSearchName, 500)
  }, [categoryId, rateSetId])

  useEffect(() => {
    if (!hasAccess(Permissions.SETTING.CATEGORIES_PACE.READ)) {
      return
    }

    let mounted = true
    setLoading(true)
    pmRateSetPaceService.getAllValues({ category_id: categoryId, rate_set_id: rateSetId })
      .then((response) => {
        if (mounted && Array.isArray(response)) {
          setList(response)
        }
      })
      .finally(() => {
        if (mounted) {
          setLoading(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [hasAccess, categoryId, rateSetId])

  return (
    <>
      <Row className='rsp-margin-bottom' gutter={8}>
        <Col lg={6}>
          <ControlLabel>Support Item, Support Item Number</ControlLabel>

          <SearchInput isSearching={searching} placeholder='Search' onChange={searchName()} />
        </Col>
      </Row>

      <Spin spinning={loading}>
        <Table
          columns={getRateSetValuesColumns()}
          dataSource={list}
          loading={loading}
          pagination={{
            pageSize: defaultPageSize,
            showTotal: (total) => `Total ${total} items`,
          }}
          rowKey='id'
          scroll={{ x: true, scrollToFirstRowOnChange: true }}
        />
      </Spin>
    </>
  )
}

function CategoryEdit (props) {
  const { history, match } = props || {}
  const { params } = match || {}
  const { id } = params || {}
  const [item, setItem] = useState({})
  const [loading, setLoading] = useState(false)
  const [saving] = useState(false)
  // const [showEdit, setShowEdit] = useState(true)
  const [showSave] = useState(false)

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  const isEdit = useCallback(() => {
    return id !== 'add'
  }, [id])

  // const toggleEdit = useCallback(() => {
  //   setShowEdit(!showEdit)
  //   setShowSave(true)
  // }, [showEdit])

  useEffect(() => {
    if (!hasAccess(Permissions.SETTING.CATEGORIES_PACE.READ)) {
      return
    }

    if (isEdit()) {
      let mounted = true
      setLoading(true)
      pmRateSetCategoryPaceService.get(id).then((response) => {
        if (mounted && validator.isObject(response) && validator.isId(response.id)) {
          setItem(response)
        }
      }).finally(() => {
        if (mounted) {
          setLoading(false)
        }
      })
      return () => {
        mounted = false
      }
    }
  }, [hasAccess, isEdit, id])

  return (
    <Page.Body>
      <Page.Content nomenu>
        <Page.Header title={!isEdit() ? 'Add New Rate Set' : item.id ? `${item.category_name} - ${item.category_number}` : null}>
          {/* {hasAccess(Permissions.SETTING.RATESETS.UPDATE) && isEdit() && !loading && showEdit
            ? <Button onClick={toggleEdit}>Edit</Button>
            : null} */}

          {(
            (hasAccess(Permissions.SETTING.RATESETS_PACE.CREATE) && !isEdit()) ||
            (hasAccess(Permissions.SETTING.RATESETS_PACE.UPDATE) && isEdit() && showSave)
          )
            ? <Button>Save</Button>
            : null
          }

          <Button onClick={history.goBack}>Back</Button>
        </Page.Header>

        <Skeleton active loading={loading}>
          <Spin spinning={saving}>
            <CategoryForm item={item} props={props} />

            <RateSetValuesTable categoryId={id} rateSetId={item.rate_set_id} />
          </Spin>
        </Skeleton>
      </Page.Content>
    </Page.Body>
  )
}
export class PaceCategoryEdit extends Component {
  render () {
    return <CategoryEdit {...this.props} />
  }
}

export default Form.create()(PaceCategoryEdit)
