export default Object.freeze({
  All: {
    value: 'all',
    name: 'All',
    reference: null,
    reference_2: null,
    color: null
  },
  Cancelled: {
    value: 'cancelled',
    name: 'Cancelled',
    reference: '000',
    reference_2: 'opts7_pm_std',
    color: '#222222'
  },
  Drafted: {
    value: 'inv-drafted',
    name: 'Drafted',
    reference: '001',
    reference_2: 'opts5_pm',
    color: '#aaaaaa'
  },
  Processing: {
    value: 'processing',
    name: 'Processing',
    reference: '002',
    reference_2: null,
    color: '#db6b3b'
  },
  PendingAuthorise: {
    value: 'client-pending-auth',
    name: 'Pending Authorise',
    reference: '003',
    reference_2: null,
    color: '#f3a703'
  },
  ToAuthorise: {
    value: 'client-pending-pms',
    name: 'To Authorise',
    reference: '004',
    reference_2: 'opts1_pm',
    color: '#cc6600'
  },
  ToClaim: {
    value: 'client-authed',
    name: 'To Claim',
    reference: '005',
    reference_2: 'opts2_pm_std',
    color: '#007724'
  },
  ToReceive: {
    value: 'pymt-req-submit',
    name: 'To Receive',
    reference: '006',
    reference_2: 'opts3_pm_std',
    color: '#02d4c9'
  },
  ToPay: {
    value: 'pymt-to-pay',
    name: 'To Pay',
    reference: '007',
    reference_2: 'opts4_pm',
    color: '#1303f3'
  },
  PartiallyPaid: {
    value: 'pymt-partial-paid',
    name: 'Partially Paid',
    reference: '008',
    reference_2: null,
    color: '#f37703'
  },
  Closed: {
    value: 'inv-closed',
    name: 'Closed',
    reference: '009',
    reference_2: null,
    color: '#3bdb4b'
  },
  Rejected: {
    value: 'rejected',
    name: 'Rejected',
    reference: '010',
    reference_2: 'opts6_pm_std',
    color: '#770000'
  }
})
