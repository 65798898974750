import React, { Component, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'

import { Permissions } from '../../../../constants'
import {
  pmRateSetPaceService, pmRateSetCategoryPaceService, pmRateSetCategoryConfigPaceService, pmRateSetCategoryGroupPaceService,
  pmRateSettingsPaceService
} from '../../../../services'
import { refreshRateSets } from '../../../../states/actions'
import { auth, validator } from '../../../../util'

// UI
import { Col, Row } from 'antd/lib/grid'
import Icon from 'antd/lib/icon'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'

import { Button, ControlLabel, List, Page, Pager } from '../../../../components'
import notify from '../../../../components/Notification'

import './styles.css'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const { Option } = Select

const defaultPageSize = 20

function getCategoryColumns (hasAccess, isEdit, categoryGroups, fundingTypes, loadingDropdown, onChangeItem) {
  return Object.freeze([
    {
      key: 'category_name',
      title: 'Category Name',
      width: 10,
    },
    {
      key: 'category_number',
      title: 'Category Number',
      width: 4
    },
    {
      key: 'category_group_id',
      title: 'Group Name',
      width: 4,
      render: (item) => isEdit ? (
        <Select
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          loading={loadingDropdown}
          placeholder='Select Group'
          showSearch
          value={item.category_group_id}
          onChange={typeof onChangeItem === 'function' ? onChangeItem('category_group_id', item) : null}
          style={{ width: '100%' }}
        >
          <Option value={null}>&nbsp;</Option>
          {Array.isArray(categoryGroups) && categoryGroups.map(({ id, name, active }) => (
            <Option key={id} disabled={!active} value={id}>{name}</Option>
          ))}
        </Select>
      ) : item.category_group_name
    },
    {
      key: 'funding_type',
      title: 'Funding Type',
      width: 4,
      render: (item) => isEdit ? (
        <Select
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          loading={loadingDropdown}
          placeholder='Select Funding Type'
          showSearch
          value={item.funding_type}
          onChange={typeof onChangeItem === 'function' ? onChangeItem('funding_type', item) : null}
          style={{ width: '100%' }}
        >
          <Option value={null}>&nbsp;</Option>
          {Array.isArray(fundingTypes) && fundingTypes.map(({ id, label, value, active }) => (
            <Option key={id} disabled={!active} value={value}>{label}</Option>
          ))}
        </Select>
      ) : item.funding_type_label
    },
    {
      title: 'Action',
      width: 1,
      render: ({ id }) => (
        <div className='action-buttons'>
          {hasAccess(Permissions.SETTING.CATEGORIES_PACE.READ)
            ? (
              <Tooltip mouseLeaveDelay={0} title='View'>
                <Link to={`/settings/rate-set-pace/categories/${id}`}>
                  <div><Icon type='form' /></div>
                </Link>
              </Tooltip>
            )
            : null}
        </div>
      )
    }
  ])
}

function Category (props) {
  const { rateSet, refreshRateSets } = props || {}
  const { refresh } = rateSet
  const [categoryGroups, setCategoryGroups] = useState([])
  const [filter, setFilter] = useState({})
  const [fundingTypes, setFundingTypes] = useState([])
  const [init, setInit] = useState(true)
  const [isEdit, setIsEdit] = useState(false)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingDropdown, setLoadingDropdown] = useState(false)
  const [page, setPage] = useState(1)
  const [rateSets, setRateSets] = useState([])
  const [saving, setSaving] = useState(false)
  const [total, setTotal] = useState(0)

  const listByPage = useCallback(async ({ page, filter: newFilter }) => {
    const _filter = { ...filter, ...newFilter }
    const _page = typeof page === 'number' && page > 0 ? page : 1
    setFilter(_filter)
    setLoading(true)
    setPage(_page)
    pmRateSetCategoryPaceService
      .listByPage(_page, defaultPageSize, _filter)
      .then((response) => {
        if (validator.isObject(response)) {
          const { list, total } = response

          if (Array.isArray(list)) {
            setList(list)
          }

          if (typeof total === 'number') {
            setTotal(total)
          }
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [filter])

  const changeEditItem = useCallback((key, item) => (value) => {
    item[`_${key}`] = item[key]
    item[key] = value
    item.changed = item[`_${key}`] !== value
    setList([...list])
  }, [list])

  const changePage = useCallback((page) => {
    listByPage({ page })
  }, [listByPage])

  const changeRateSet = useCallback((rateSetId) => {
    listByPage({ page: 1, filter: { rate_set_id: rateSetId } })
  }, [listByPage])

  const handleCancel = useCallback(async () => {
    setIsEdit(false)

    if (list.some(({ changed }) => changed)) {
      listByPage({ page })
    }
  }, [listByPage, list, page])

  const handleSave = useCallback(async () => {
    if (loading || saving) {
      return
    }

    try {
      setSaving(true)
      const response = await pmRateSetCategoryConfigPaceService.saveAll(list.map(({
        id: categoryId, category_config_id: categoryConfigId, category_group_id: categoryGroupId, funding_type: fundingType, changed
      }) => {
        return {
          id: categoryConfigId, category_id: categoryId, category_group_id: categoryGroupId, funding_type: fundingType,
          changed: changed || false
        }
      }))

      if (validator.isObject(response) && validator.isId(response.id)) {
        notify.success('Saved successfully', `Group & funding type saved successfully.`)
        listByPage({ page })
      } else {
        notify.error('Unable to save', `Unable to save group & funding type. Please try again later.`)
      }
    } catch (e) {
      notify.error('Unable to save', `Unable to save group & funding type. Please try again later.`)
    } finally {
      setIsEdit(false)
      setSaving(false)
    }
  }, [listByPage, list, loading, page, saving])

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  const openEdit = useCallback(() => {
    Promise
      .all([
        pmRateSetCategoryGroupPaceService.getAll(),
        pmRateSettingsPaceService.getAllFundingTypes()
      ])
      .then(([categoryGroupResponse, fundingTypeResponse]) => {
        setCategoryGroups(categoryGroupResponse)
        setFundingTypes(fundingTypeResponse)
      })
      .finally(() => {
        setLoadingDropdown(false)
      })
    setIsEdit(true)
    setLoadingDropdown(true)
  }, [])

  useEffect(() => {
    if (!hasAccess(Permissions.SETTING.CATEGORIES_PACE.LIST)) {
      setInit(false)
      return
    }

    const now = moment().toISOString()
    let mounted = true
    setLoading(true)
    setLoadingDropdown(true)
    Promise.all([
      pmRateSetPaceService.getAll(),
      pmRateSetCategoryGroupPaceService.getAll(),
      pmRateSettingsPaceService.getAllFundingTypes()
    ])
      .then(async ([rateSetResponse, categoryGroupResponse, fundingTypeResponse]) => {
        if (mounted) {
          if (Array.isArray(rateSetResponse) && rateSetResponse.length > 0) {
            const currRateSetResponse = await pmRateSetPaceService.getAll({
              $and: [
                { start_date: { condition: '<=', value: now } },
                { end_date: { condition: '>=', value: now } }
              ]
            }, 1)
            const hasCurrRateSet = Array.isArray(currRateSetResponse) && currRateSetResponse.length > 0
            const filter = { rate_set_id: hasCurrRateSet ? currRateSetResponse[0].id : rateSetResponse[0].id }
            setFilter(filter)
            setRateSets(rateSetResponse)
            pmRateSetCategoryPaceService
              .listByPage(1, defaultPageSize, filter).then((response) => {
                if (mounted && validator.isObject(response)) {
                  const { list, total } = response

                  if (Array.isArray(list)) {
                    setList(list)
                  }

                  if (typeof total === 'number') {
                    setTotal(total)
                  }
                }
              })
              .finally(() => {
                if (mounted) {
                  setInit(false)
                  setLoading(false)
                }
              })
          }

          if (Array.isArray(categoryGroupResponse)) {
            setCategoryGroups(categoryGroupResponse)
          }

          if (Array.isArray(fundingTypeResponse)) {
            setFundingTypes(fundingTypeResponse)
          }
        }
      })
      .finally(() => {
        if (mounted) {
          setInit(false)
          setLoading(false)
          setLoadingDropdown(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [hasAccess])

  useEffect(() => {
    if (!hasAccess(Permissions.SETTING.CATEGORIES_PACE.LIST)) {
      return
    }

    let mounted = true

    if (refresh === true) {
      setLoadingDropdown(true)
      pmRateSetPaceService.getAll()
        .then((rateSetResponse) => {
          if (mounted && Array.isArray(rateSetResponse)) {
            setRateSets(rateSetResponse)
          }
        })
        .finally(() => {
          if (mounted) {
            refreshRateSets(false)
            setLoadingDropdown(false)
          }
        })
    }

    return () => {
      mounted = false
    }
  }, [hasAccess, refreshRateSets, refresh])

  return (
    <Skeleton active loading={init}>
      <div className='rsp-category'>
        <Page.Header>
          <Row>
            <Col lg={6}>
              <ControlLabel>Rate Set</ControlLabel>

              <Select
                defaultValue={filter.rate_set_id}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                loading={loadingDropdown}
                placeholder='Select Rate Set'
                showSearch
                onChange={changeRateSet}
                style={{ width: '100%' }}
              >
                {rateSets.map(({ id, name, active }) => (
                  <Option key={id} className={active ? '' : 'rsp-text-grey'} value={id}>{name}</Option>
                ))}
              </Select>
            </Col>

            <Col lg={18}>
              <ControlLabel>&nbsp;</ControlLabel>

              <div className='btn-box'>
                {hasAccess(Permissions.SETTING.CATEGORIES_PACE.UPDATE) && isEdit
                  ? <Button feedback={saving} ghost onClick={handleCancel}>Cancel</Button>
                  : null}

                {hasAccess(Permissions.SETTING.CATEGORIES_PACE.UPDATE)
                  ? <Button feedback={saving} onClick={isEdit ? handleSave : openEdit}>{isEdit ? 'Save' : 'Edit'}</Button>
                  : null}
              </div>
            </Col>
          </Row>
        </Page.Header>

        <Spin spinning={loading}>
          <List cols={getCategoryColumns(hasAccess, isEdit, categoryGroups, fundingTypes, loadingDropdown, changeEditItem)} rows={list} />

          <Pager
            size={defaultPageSize}
            total={total}
            totalText={`Total ${total} categories`}
            current={page}
            onChange={changePage}
            style={{ marginTop: '15px' }}
          />
        </Spin>
      </div>
    </Skeleton>
  )
}

export class PaceCategory extends Component {
  render () {
    return <Category {...this.props} />
  }
}

const mapDispatchToProps = {
  refreshRateSets
}

const mapStateToProps = (state) => {
  return { ...state.RateSetPace }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaceCategory)
