import React, { Component, useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import clsx from 'clsx'

import { apiHostname } from '../../../../config'
import { FileUploadMsg, Permissions } from '../../../../constants'
import { pmRateSetCategoryGroupPaceService, pmRateSetCategoryTemplatePaceService, pmRateSettingsPaceService } from '../../../../services'
import { refreshRateSetCategoryTemplates } from '../../../../states/actions'
import { auth, validator } from '../../../../util'

// UI
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Radio from 'antd/lib/radio'
import Select from 'antd/lib/select'
import Switch from 'antd/lib/switch'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'
import Upload from 'antd/lib/upload'

import { Button, List, Page, Pager, SideModal } from '../../../../components'
import notify from '../../../../components/Notification'

import './styles.css'

const { Item: FormItem } = Form
const { Option } = Select
const defaultPageSize = 20

function getTemplateColumns (isEdit, categoryGroups, fundingTypes, loadingDropdown, onChangeItem) {
  return Object.freeze([
    {
      key: 'category_name',
      title: 'Category Name',
      width: 10
    },
    {
      key: 'category_number',
      title: 'Category Number',
      width: 4
    },
    {
      key: 'category_group_id',
      title: 'Group Name',
      width: 4,
      render: (item) => isEdit ? (
        <Select
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          loading={loadingDropdown}
          placeholder='Select Group'
          showSearch
          value={item.category_group_id}
          onChange={typeof onChangeItem === 'function' ? onChangeItem('category_group_id', item) : null}
          style={{ width: '100%' }}
        >
          <Option value={null}>&nbsp;</Option>
          {Array.isArray(categoryGroups) && categoryGroups.map(({ id, name, active }) => (
            <Option key={id} disabled={!active} value={id}>{name}</Option>
          ))}
        </Select>
      ) : item.category_group_name
    },
    {
      key: 'funding_type',
      title: 'Funding Type',
      width: 4,
      render: (item) => isEdit ? (
        <Select
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          loading={loadingDropdown}
          placeholder='Select Funding Type'
          showSearch
          value={item.funding_type}
          onChange={typeof onChangeItem === 'function' ? onChangeItem('funding_type', item) : null}
          style={{ width: '100%' }}
        >
          <Option value={null}>&nbsp;</Option>
          {Array.isArray(fundingTypes) && fundingTypes.map(({ id, label, value, active }) => (
            <Option key={id} disabled={!active} value={value}>{label}</Option>
          ))}
        </Select>
      ) : item.funding_type_label
    },
    {
      key: 'active',
      title: 'Enable',
      width: 6,
      render: (item) => isEdit ? (
        <Switch
          checkedChildren='Enable'
          unCheckedChildren='Disable'
          checked={item.active}
          onChange={typeof onChangeItem === 'function' ? onChangeItem('active', item) : null}
        />
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <Icon
            className={clsx('rsp-icon', item.active ? 'active' : 'inactive')}
            type='check-circle'
            theme='filled'
          />
        </div>
      )
    }
  ])
}

function RateSetUpload ({ props, saving }) {
  const { refreshRateSetCategoryTemplates } = props || {}
  const [showImportModal, setShowImportModal] = useState(false)
  const [uploadList, setUploadList] = useState([])
  const [uploadMessage, setUploadMessage] = useState()
  const [uploaded, setUploaded] = useState({})
  const [uploading, setUploading] = useState(false)

  const changeFile = useCallback((info) => {
    if (validator.isObject(info) && info.file) {
      const f = info.file
      const { percent, response: r, status, uid } = f

      if (percent === 100 && r && status === 'done') {
        const uploaded = {
          fileName: r.filePath ? r.filePath.filename : '',
          fileUrl: r.fileUrl,
          filePath: r.filePath ? r.filePath.path : '',
          uid: uid
        }
        setUploadMessage()
        setUploaded(uploaded)
      }
    }
  }, [])

  const checkUpload = useCallback((file) => {
    if (file && (
      file.type === 'text/csv' ||
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'application/vnd.ms-excel' ||
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.template'
    )) {
      setUploadList([file])
      setUploadMessage()
      return true
    } else {
      setUploadList([])
      setUploadMessage(FileUploadMsg.UploadMsgWrongFormatCSV)
      return false
    }
  }, [])

  const closeImportModal = useCallback(() => {
    setShowImportModal(false)
    setUploadList([])
    setUploadMessage()
    setUploaded({})
    setUploading(false)
  }, [])

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  const importFile = useCallback(async () => {
    if (!Array.isArray(uploadList) || uploadList.length < 1) {
      setUploadMessage(FileUploadMsg.UploadMsgNoFile)
    } else if (!validator.isObject(uploaded) || !uploaded.uid) {
      setUploadMessage(FileUploadMsg.UploadMsgInProgress)
    } else {
      try {
        setUploading(true)
        const response = await pmRateSetCategoryTemplatePaceService.importFile(uploaded)

        if (validator.isObject(response) && validator.isId(response.id)) {
          notify.success('Import Successfully', 'Template imported successfully.')
          refreshRateSetCategoryTemplates(true)
        } else {
          notify.error('Unable to import successfully', 'Unable to import template successfully. Please try again later.')
        }
      } catch (e) {
        notify.error('Unable to import successfully', 'Unable to import template successfully. Please try again later.')
        console.log(e)
      } finally {
        closeImportModal()
        setUploading(false)
      }
    }
  }, [closeImportModal, refreshRateSetCategoryTemplates, uploadList, uploaded])

  const openImportModal = useCallback(() => {
    setShowImportModal(true)
  }, [])

  const removeFile = useCallback((file) => {
    const idx = uploadList.indexOf(file)

    if (idx > -1) {
      const newList = uploadList.slice()
      newList.shift()
      setUploadList(newList)
    }
  }, [uploadList])

  return (
    <>
      {hasAccess(Permissions.SETTING.RATESET_IMPORT_PACE.UPDATE)
        ? <Button onClick={openImportModal} feedback={saving}>Import</Button>
        : null}

      <Modal
        className='rsp-upload-modal'
        footer={[
          <Button key='cancel' ghost type='primary' onClick={closeImportModal}>Cancel</Button>,
          <Button key='ok' disabled={!uploaded.uid} feedback={uploading} ghost={!uploaded.uid} type='primary' onClick={importFile}>Start Import</Button>
        ]}
        maskClosable={false}
        title='Import Rate Sets and Categories'
        visible={showImportModal}
        width={700}
        onCancel={uploading ? null : closeImportModal}
        onOk={importFile}
      >
        <div>Select the latest rate set CSV or Excel file to upload and this may take a while to complete.</div>

        <div className='rsp-margin-bottom alert'>Be reminded that this action cannot be undone!</div>

        <div>
          <Upload
            headers={{ Authorization: `Bearer ${auth.getCurrentToken()}` }}
            method='POST'
            action={`${apiHostname}/private/api/pm-rate-set-category-template-paces/file`}
            disabled={uploading}
            fileList={uploadList}
            multiple={false}
            name='file'
            beforeUpload={checkUpload}
            onChange={changeFile}
            onRemove={removeFile}
          >
            <Button className='btn-upload' feedback={uploading}><Icon type="upload" /> Select File</Button>
          </Upload>
        </div>

        {uploadMessage ? <div className='alert text-error'>{uploadMessage}</div> : null}
      </Modal>
    </>
  )
}

function Template (props) {
  const { rateSetCategoryTemplate, refreshRateSetCategoryTemplates } = props || {}
  const { refresh } = rateSetCategoryTemplate
  const [categoryGroups, setCategoryGroups] = useState([])
  const [fundingTypes, setFundingTypes] = useState([])
  const [init, setInit] = useState(true)
  const [isEdit, setIsEdit] = useState(false)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingDropdown, setLoadingDropdown] = useState(false)
  const [page, setPage] = useState(1)
  const [saving, setSaving] = useState(false)
  const [total, setTotal] = useState(0)

  const listByPage = useCallback(async ({ page }) => {
    const _page = typeof page === 'number' && page > 0 ? page : 1
    setLoading(true)
    setPage(_page)
    pmRateSetCategoryTemplatePaceService
      .listByPage(_page, defaultPageSize)
      .then((response) => {
        if (validator.isObject(response)) {
          const { list, total } = response

          if (Array.isArray(list)) {
            setList(list)
          }

          if (typeof total === 'number') {
            setTotal(total)
          }
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const changeEditItem = useCallback((key, item) => (value) => {
    item[`_${key}`] = item[key]
    item[key] = value
    item.changed = item[`_${key}`] !== value
    setList([...list])
  }, [list])

  const changePage = useCallback((page) => {
    listByPage({ page })
  }, [listByPage])

  const handleCancel = useCallback(async () => {
    setIsEdit(false)

    if (list.some(({ changed }) => changed)) {
      listByPage({ page })
    }
  }, [listByPage, list, page])

  const handleSave = useCallback(async () => {
    if (loading || saving) {
      return
    }

    try {
      setSaving(true)
      const response = await pmRateSetCategoryTemplatePaceService.saveAll(list.map(({
        id, category_group_id: categoryGroupId, category_number: categoryNumber, category_name: categoryName,
        funding_type: fundingType, changed
      }) => {
        return {
          id, category_group_id: categoryGroupId, category_number: categoryNumber, category_name: categoryName,
          funding_type: fundingType, changed: changed || false
        }
      }))

      if (validator.isObject(response) && validator.isId(response.id)) {
        notify.success('Saved successfully', `Category template saved successfully.`)
        listByPage({ page })
      } else {
        notify.error('Unable to save', `Unable to save category template. Please try again later.`)
      }
    } catch (e) {
      notify.error('Unable to save', `Unable to save category template. Please try again later.`)
    } finally {
      setIsEdit(false)
      setSaving(false)
    }
  }, [listByPage, list, loading, page, saving])

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  const showEdit = useCallback(() => {
    Promise
      .all([
        pmRateSetCategoryGroupPaceService.getAll(),
        pmRateSettingsPaceService.getAllFundingTypes()
      ])
      .then(([categoryGroupResponse, fundingTypeResponse]) => {
        setCategoryGroups(categoryGroupResponse)
        setFundingTypes(fundingTypeResponse)
      }).finally(() => {
        setLoadingDropdown(false)
      })
    setIsEdit(true)
    setLoadingDropdown(true)
  }, [])

  useEffect(() => {
    if (!hasAccess(Permissions.SETTING.RATESET_GROUPINGS_PACE.LIST)) {
      setInit(false)
      return
    }

    let mounted = true
    setLoading(true)
    setLoadingDropdown(true)
    Promise.all([
      pmRateSetCategoryTemplatePaceService.listByPage(1, defaultPageSize),
      pmRateSetCategoryGroupPaceService.getAll(),
      pmRateSettingsPaceService.getAllFundingTypes()
    ]).then(([response, categoryGroupResponse, fundingTypeResponse]) => {
      if (mounted) {
        if (validator.isObject(response)) {
          const { list, total } = response

          if (Array.isArray(list)) {
            setList(list)
          }

          if (typeof total === 'number') {
            setTotal(total)
          }
        }

        if (Array.isArray(categoryGroupResponse)) {
          setCategoryGroups(categoryGroupResponse)
        }

        if (Array.isArray(fundingTypeResponse)) {
          setFundingTypes(fundingTypeResponse)
        }
      }
    }).finally(() => {
      if (mounted) {
        setInit(false)
        setLoading(false)
      }
    })

    return () => {
      mounted = false
    }
  }, [hasAccess])

  useEffect(() => {
    if (!hasAccess(Permissions.SETTING.RATESET_GROUPINGS_PACE.LIST)) {
      return
    }

    let mounted = true

    if (refresh === true) {
      setLoading(true)
      pmRateSetCategoryTemplatePaceService
        .listByPage(1, defaultPageSize).then((response) => {
          if (mounted && validator.isObject(response)) {
            const { list, total } = response
            setList(list)
            setTotal(total)
          }
        })
        .finally(() => {
          if (mounted) {
            refreshRateSetCategoryTemplates(false)
            setLoading(false)
          }
        })
    }

    return () => {
      mounted = false
    }
  }, [hasAccess, refreshRateSetCategoryTemplates, refresh])

  return (
    <Skeleton active loading={init}>
      <div className='rsp-grouping-template'>
        <Page.Header>
          {hasAccess(Permissions.SETTING.RATESET_GROUPINGS_PACE.UPDATE) && isEdit
            ? <Button feedback={saving} ghost onClick={handleCancel}>Cancel</Button>
            : null}

          {hasAccess(Permissions.SETTING.RATESET_GROUPINGS_PACE.UPDATE)
            ? <Button feedback={saving} onClick={isEdit ? handleSave : showEdit}>{isEdit ? 'Save' : 'Edit'}</Button>
            : null}

          {hasAccess(Permissions.SETTING.RATESET_IMPORT_PACE.UPDATE) && (
            <div>
              <RateSetUpload props={props} saving={saving} />
            </div>
          )}
        </Page.Header>

        <Spin spinning={loading}>
          <List cols={getTemplateColumns(isEdit, categoryGroups, fundingTypes, loadingDropdown, changeEditItem)} rows={list} />

          <Pager
            current={page}
            size={defaultPageSize}
            total={total}
            totalText={`Total ${total} category templates`}
            onChange={changePage}
            style={{ marginTop: '15px' }}
          />
        </Spin>
      </div>
    </Skeleton>
  )
}

function getGroupColumns (hasAccess, handleEdit) {
  return Object.freeze([
    {
      key: 'name',
      title: 'Group Name',
      width: 16,
      render: ({ name, active }) => (
        <span className={clsx(active ? '' : 'inactive')}>{name}</span>
      )
    },
    {
      key: 'active',
      title: 'Enable',
      width: 6,
      render: ({ active }) => (
        <Icon className={clsx('rsp-icon', active ? 'active' : 'inactive')} type='check-circle' theme='filled' />
      )
    },
    {
      key: 'action',
      title: 'Action',
      width: 1,
      render: ({ id, name, active }) => {
        return (
          <div className='action-buttons'>
            {hasAccess(Permissions.SETTING.RATESET_GROUPINGS_PACE.UPDATE)
              ? (
                <Tooltip mouseLeaveDelay={0} title='Edit'>
                  <div className={clsx('rsp-btn-icon', active ? 'active' : 'inactive')} onClick={handleEdit(id, name, active)}>
                    <Icon type='form' />
                  </div>
                </Tooltip>
              )
              : null}
          </div>
        )
      }
    }
  ])
}

function Group (props) {
  const { form } = props || {}
  const { getFieldDecorator, resetFields, validateFields } = form || {}
  const [init, setInit] = useState(true)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [saving, setSaving] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [total, setTotal] = useState(0)

  const closeModal = useCallback(() => {
    setShowModal(false)
    resetFields()
  }, [resetFields])

  const listByPage = useCallback(({ page }) => {
    const _page = typeof page === 'number' && page > 0 ? page : 1
    setLoading(true)
    setPage(_page)
    pmRateSetCategoryGroupPaceService.listByPage(_page, defaultPageSize)
      .then((response) => {
        if (validator.isObject(response)) {
          const { list, total } = response

          if (Array.isArray(list)) {
            setList(list)
          }

          if (typeof total === 'number') {
            setTotal(total)
          }
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const openModal = useCallback(() => {
    setShowModal(true)
  }, [])

  const changePage = useCallback((page) => {
    listByPage({ page })
  }, [listByPage])

  const handleAdd = useCallback(() => {
    setSelectedItem({})
    openModal()
  }, [openModal])

  const handleEdit = useCallback((id, name, active) => () => {
    setSelectedItem({ id, name, active })
    openModal()
  }, [openModal])

  const handleSave = useCallback(() => {
    if (loading || saving) {
      return
    }

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id } = selectedItem || {}
        setSaving(true)

        try {
          const response = id
            ? await pmRateSetCategoryGroupPaceService.save(id, values)
            : await pmRateSetCategoryGroupPaceService.add(values)

          if (response.id) {
            notify.success('Saved successfully', `Group saved successfully.`)
            closeModal()
            listByPage(1, defaultPageSize)
          } else {
            notify.error('Unable to save successfully', `Unable to save group successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save group successfully. Please try again later.`)
        } finally {
          setSaving(false)
        }
      }
    })
  }, [closeModal, listByPage, validateFields, loading, saving, selectedItem])

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  useEffect(() => {
    if (!hasAccess(Permissions.SETTING.RATESET_GROUPINGS_PACE.LIST)) {
      setInit(false)
      return
    }

    let mounted = true
    setLoading(true)
    pmRateSetCategoryGroupPaceService.listByPage(1, defaultPageSize)
      .then((response) => {
        if (mounted && validator.isObject(response)) {
          const { list, total } = response

          if (Array.isArray(list)) {
            setList(list)
          }

          if (typeof total === 'number') {
            setTotal(total)
          }
        }
      })
      .finally(() => {
        if (mounted) {
          setInit(false)
          setLoading(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [hasAccess])

  return (
    <Skeleton active loading={init}>
      <div className='rsp-grouping-group'>
        <Page.Header>
          {hasAccess(Permissions.SETTING.RATESET_GROUPINGS_PACE.CREATE)
            ? <Button feedback={loading} onClick={handleAdd}>Add Group</Button>
            : null}
        </Page.Header>

        <Spin spinning={loading}>
          <List cols={getGroupColumns(hasAccess, handleEdit)} rows={list} />

          <Pager
            size={defaultPageSize}
            total={total}
            totalText={`Total ${total} groups`}
            current={page}
            onChange={changePage}
            style={{ marginTop: '15px' }}
          />
        </Spin>
      </div>

      <SideModal
        title={`${selectedItem?.id ? 'Edit' : 'Add'} Group`}
        showModal={showModal}
        onClose={closeModal}
        buttons={[
          <Button key='0' feedback={saving} onClick={handleSave}>Save</Button>
        ]}
      >
        <Form layout='vertical'>
          <FormItem label='Group Name'>
            {getFieldDecorator('name', {
              initialValue: selectedItem.name,
              rules: [
                { required: true, min: 2, max: 128, message: 'Group name must be between 2 and 128 characters' },
                { whitespace: true, message: 'Please enter group name' },
              ],
            })(<Input disabled={saving} />)}
          </FormItem>

          <FormItem label=''>
            {getFieldDecorator('active', {
              initialValue: typeof selectedItem.active === 'boolean' ? selectedItem.active : true,
              valuePropName: 'checked',
            })(<Switch checkedChildren='Enable' unCheckedChildren='Disable' disabled={saving} />)}
          </FormItem>
        </Form>
      </SideModal>
    </Skeleton>
  )
}

function getDefaultCurrentTab (props, tabs) {
  const { location } = props || {}
  const { search } = location || {}
  const qs = new URLSearchParams(search)
  const tabPath = qs.get('tp')
  const tab = tabs.find(({ path: p }) => p === tabPath)
  return isValidTab(tab) ? tab : tabs[0]
}

function getTabs () {
  return Object.freeze([
    { key: '1', title: 'Category Template', path: 'templates' },
    { key: '2', title: 'Group', path: 'groups' },
  ])
}

function getTabComponent (props, tab) {
  if (tab && validator.isObject(tab)) {
    if (tab.key === '1') {
      return <Template {...props} />
    } else if (tab.key === '2') {
      return <Group {...props} />
    }
  }

  return null
}

function isValidTab (tab) {
  return !!tab && !validator.isEmptyString(tab.key)
}

function Grouping (props) {
  const { history, location } = props || {}
  const { pathname, search } = location || {}
  const tabs = useMemo(() => getTabs(props), [props])
  const [currentTab, setCurrentTab] = useState(getDefaultCurrentTab(props, tabs))

  const changeTab = useCallback((key) => {
    const tab = tabs.find(({ key: k }) => k === key)
    setCurrentTab(tab)

    if (isValidTab(tab)) {
      const params = new URLSearchParams({ tp: tab.path })
      history.replace({ pathname, search: params.toString() })
    }
  }, [history, pathname, tabs])

  const handleChangeTab = useCallback((e) => {
    const { target } = e || {}
    const { value } = target || {}
    changeTab(value)
  }, [changeTab])

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  useEffect(() => {
    if (!hasAccess(Permissions.SETTING.RATESET_GROUPINGS_PACE.LIST)) {
      return
    }

    let mounted = true

    if (mounted && pathname.lastIndexOf('groupings') > -1) {
      const qs = new URLSearchParams(search)
      const tabPath = qs.get('tp')

      if (tabPath === null || tabPath === undefined || validator.isEmptyString(tabPath)) {
        changeTab(tabs[0].key)
      } else {
        const tab = tabs.find(({ path: p }) => p === tabPath)

        if (currentTab.key !== tab.key) {
          changeTab(tab.key)
        }
      }
    }

    return () => {
      mounted = false
    }
  }, [changeTab, hasAccess, currentTab, pathname, search, tabs])

  return (
    <div>
      <Radio.Group onChange={handleChangeTab} value={currentTab.key}>
        {tabs.map(({ key, title }) => <Radio.Button key={key} value={key}>{title}</Radio.Button>)}
      </Radio.Group>

      {getTabComponent(props, currentTab)}
    </div>
  )
}

export class PaceGrouping extends Component {
  render () {
    return <Grouping {...this.props} />
  }
}

const mapDispatchToProps = {
  refreshRateSetCategoryTemplates
}

const mapStateToProps = (state) => {
  return { ...state.RateSetPace }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(PaceGrouping))
