import BigNumber from 'bignumber.js'
import moment from 'moment-timezone'
import numeral from 'numeral'
import randomize from 'randomatic'

import validator from './validator'

moment.tz.setDefault('Australia/Melbourne')

const SBPeriodDuration1 = 60
const SBPeriodDuration2 = 90

export default {
  capitalize (str, toLowerCase = true) {
    if (typeof str === 'string') {
      const words = str.split(' ')
      return words.map((word) => word.charAt(0).toUpperCase() + (toLowerCase ? word.substring(1).toLowerCase() : word.substring(1).toUpperCase())).join(' ')
    }

    return ''
  },
  disablePreviousDates (current) {
    return current && current < moment().startOf('day')
  },
  duration (date) {
    const duration = moment.duration(moment().endOf('days').diff(moment(date).endOf('days')))
    return duration.asDays().toFixed(0)
  },
  expiringDuration (date) {
    const duration = moment.duration(moment(date).endOf('days').diff(moment().endOf('days')))
    return duration.asDays().toFixed(0)
  },
  formatABN (text) {
    if (text && text.length > 10) {
      return text.substring(0, 2) + ' ' + text.substring(2, 5) + ' ' + text.substring(5, 8) + ' ' + text.substring(8, 11)
    } else if (!text) {
      return 'ABN Not Available'
    }

    return text
  },
  getHash (length = 8) {
    const options = { chars: 'abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ' }
    const pattern = '?'

    return randomize(pattern, length, options)
  },
  parseAbn (str) {
    return typeof str === 'string' ? str.replace(/\D+/g, '') : str
  },
  parseAmount (str) {
    return typeof str === 'string' ? str.replace(/[^0-9,.]/g, '') : str
  },
  round (number) {
    if (typeof number === 'number') {
      return Math.round(number * 100) / 100
    }

    return 0
  },
  toABNFormat (str) {
    if (!validator.isDigit(str) || str.length !== 11) {
      return str
    } else {
      const newStr = String(str)
      const newStr2 = newStr.substring(0, 2) + ' ' + newStr.substring(2, 5) + ' ' + newStr.substring(5, 8) + ' ' + newStr.substring(8, 11)
      return newStr2
    }
  },
  toBigNumber (value) {
    if (typeof value === 'string') {
      return BigNumber(value.replace(/[^0-9.-]/g, ''))
    }

    return BigNumber(value)
  },
  toClassName (arr) {
    if (arr && arr.length > 0) {
      return arr.filter((str) => typeof str === 'string' && str).join(' ')
    }

    return ''
  },
  toDate (str, format) {
    if (str) {
      if (moment.isMoment(str)) {
        return str.format(format)
      } else if ((typeof str === 'string' || str) && typeof format === 'string') {
        return moment(str).format(format)
      }
    }

    return ''
  },
  toDay (str) {
    return this.toDate(str, 'dddd')
  },
  toDayCount (str) {
    return this.toMomentCount(str, 'day')
  },
  toDayHourText (minutes = 0) {
    if (minutes === 0) {
      return 'N/A'
    }

    const dd = numeral(Math.floor(minutes / 1440)).format('0')
    const hh = numeral(Math.floor((minutes % 1440) / 60)).format('0')
    const mm = numeral((minutes % 1440) % 60).format('0')

    let text = ''
    text += `${dd === '0' ? '' : `${dd} day${dd === '1' ? '' : 's'}`}`
    text = `${text ? `${text} ` : ''}${hh === '0' ? '' : `${hh} hr${hh === '1' ? '' : 's'}`}`
    text = `${text ? `${text} ` : ''}${mm === '0' ? '' : `${mm} min${mm === '1' ? '' : 's'}`}`
    return text
  },
  toDBDate (str) {
    return this.toDate(str, 'YYYY-MM-DD')
  },
  toDBPcpUserId (value) {
    if (typeof value === 'string') {
      const dbValue = BigNumber(value.replace(/[a-zA-Z]+/g, ''))
      return dbValue.isNaN() || !dbValue.isFinite() ? '' : dbValue.toFixed(0)
    }

    return ''
  },
  toDBStringDate (str) {
    return this.toDate(str, 'YYYYMMDD')
  },
  toDecimal (number) {
    const value1 = this.toBigNumber(number)

    if (!value1.isNaN() && value1.isFinite()) {
      const isNegative = value1.isLessThan(0)
      const str = value1.toFormat()

      if (isNegative) {
        return `- ${str.replace('-', '')}`
      } else {
        return str
      }
    }

    return 0
  },
  toErrorMessage (r) {
    if (validator.isNotEmptyArray(r)) {
      return r.map(({ message }) => message).join('\n')
    } else if (r && r.errors && validator.isNotEmptyArray(r.errors)) {
      return r.errors.map(({ message }) => message).join('\n')
    }
    return ''
  },
  toFloatDecimal (number, decimalFormat = '[00]') {
    if (typeof number === 'number' || typeof number === 'string') {
      const r1 = validateNumeral(number, `0.[0000]`)
      return validateNumeral(r1, `0.${decimalFormat}`)
    }

    return number
  },
  toHourText (hr) {
    const val = numeral(hr / 60).format('0.0')
    return hr === 60 ? `(${val} Hr) ` : `(${val} Hrs)`
  },
  toHtmlLineBreak (str) {
    return validator.isEmptyString(str) ? str : str.replace(/\n/g, '<br />')
  },
  toMoment (str) {
    if (moment.isMoment(str)) {
      return str
    } else if ((typeof str === 'string' || str)) {
      return moment(str)
    }

    return null
  },
  toMomentClone (str) {
    if (moment.isMoment(str)) {
      return str.clone()
    } else if ((typeof str === 'string' || str)) {
      return moment(str)
    }

    return null
  },
  toMomentCount (str, type = 'day') {
    let count
    const date = moment.isMoment(str) ? str : moment(str)
    const now = moment()

    // update isSame reference field if used for calculate the diff of hour/minute/second
    if (date.isSame(now, 'day')) {
      return 0
    } else {
      if (type === 'day' || type === 'days') {
        return Math.floor(Math.abs(date.startOf('day').diff(now.endOf('day'), type, true)))
      } else if (type === 'year' || type === 'years') {
        return Math.floor(Math.abs(date.diff(now, type, true)))
      } else {
        return Math.floor(Math.abs(date.diff(now, type, true)))
      }
    }
  },
  toNumber (number) {
    const num = numeral(number)
    return num.value()
  },
  toPcpUserId (value) {
    if (typeof value === 'string') {
      const userId = `${value}`.trim().padStart(6, '0')
      return `C${userId}`
    }

    return ''
  },
  toPercentage (value, decimal = 1) {
    return value ? Number(Math.round(value + `e${decimal}`) + `e-${decimal}`) + '%' : '0%'
  },
  toPeriodStatus (endDate, today = moment(new Date())) {
    let result = { isDue: false, isDuePeriod1: false, isDuePeriod2: false, dayCount: null }
    const d = moment.isMoment(endDate) ? endDate : moment(endDate)
    const diff = today.diff(d, 'day')

    if (diff > 0) {
      result.isDue = true
      if (diff > SBPeriodDuration1) {
        result.isDuePeriod1 = true
      }

      if (diff > SBPeriodDuration2) {
        result.isDuePeriod2 = true
      }
    }

    result.dayCount = diff

    return result
  },
  toPrice (number, currency = '$') {
    const value1 = this.toBigNumber(number)

    if (!value1.isNaN() && value1.isFinite()) {
      const isNegative = value1.isLessThan(0)
      const str = `${currency ? `${currency} ` : ''}${value1.toFormat(2)}`

      if (isNegative) {
        return `- ${str.replace('-', '')}`
      } else {
        return str
      }
    }

    return `${currency} 0.00`
  },
  toPriceValue (number) {
    if (typeof number === 'number' || typeof number === 'string') {
      return validateNumeral(number, '0,0.00')
    }

    return 0
  },
  toPriceFloat (number) {
    if (typeof number === 'number' || typeof number === 'string') {
      return parseFloat(this.toFloatDecimal(number))
    }

    return 0
  },
  toFloatDecimal (number, decimalFormat = '[00]') {
    if (typeof number === 'number' || typeof number === 'string') {
      const r1 = validateNumeral(number, `0.[0000]`)
      return validateNumeral(r1, `0.${decimalFormat}`)
    }

    return number
  },
  toShortDate (str) {
    return this.toDate(str, 'DD/MM/YYYY')
  },
  toStandardDate (str) {
    return this.toDate(str, 'DD/MM/YYYY hh:mm A')
  },
  toStandardFileName (str) {
    if (!str) return str
    const fileNameIndex = str.lastIndexOf('_')
    const fileExtIndex = str.lastIndexOf('.')
    const name = decodeURI(str.substring(0, fileNameIndex))
    const extName = str.substring(fileExtIndex)
    return name + extName
  },
  toStandardLongDate (str) {
    return this.toDate(str, 'DD/MM/YYYY hh:mm:ss A')
  },
  toShortTime (str) {
    return this.toDate(str, 'hh:mm A')
  },
  toShortenText (str, range = 30) {
    let length = str.length
    let index = str.indexOf(' ', range)
    index = index === -1 ? length : index
    return length <= range ? str : str.substring(0, index) + (index === length ? '' : '...')
  },
  toYearCount (str) {
    return this.toMomentCount(str, 'year')
  },
  toYesNo (value) {
    return value ? 'YES' : 'NO'
  },
  toIcon (mediaType, defaultIcon) {
    if (typeof mediaType === 'string') {
      if (mediaType.startsWith('audio')) {
        return '/icon/audio.svg'
      } else if (mediaType.startsWith('video')) {
        return '/icon/video.svg'
      } else {
        switch (mediaType) {
          case 'application/pdf':
            return '/icon/pdf.svg'
          case 'application/msword':
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
          case 'application/vnd.ms-word.document.macroEnabled.12':
          case 'application/vnd.ms-word.template.macroEnabled.12':
            return '/icon/doc.svg'
          case 'application/vnd.ms-excel':
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
          case 'application/vnd.ms-excel.sheet.macroEnabled.12':
          case 'application/vnd.ms-excel.template.macroEnabled.12':
          case 'application/vnd.ms-excel.addin.macroEnabled.12':
          case 'application/vnd.ms-excel.sheet.binary.macroEnabled.12':
            return '/icon/xls.svg'
          case 'application/vnd.ms-powerpoint':
          case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          case 'application/vnd.openxmlformats-officedocument.presentationml.template':
          case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
          case 'application/vnd.ms-powerpoint.addin.macroEnabled.12':
          case 'application/vnd.ms-powerpoint.presentation.macroEnabled.12':
          case 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12':
            return '/icon/ppt.svg'
          case 'image/jpeg':
            return '/icon/jpg.svg'
          case 'image/png':
            return '/icon/png.svg'
          default:
            return '/icon/file.svg'
        }
      }
    }

    return defaultIcon || '/icon/resource.svg'
  }
}

/**
 * numeral.js has a critical issue which the number input will generate NaN if it is decimal exponential form (< 0.000001 i.e. 1e-7)
 * so need to validate the numeral result and fix the number if it generates NaN
 */
function validateNumeral (value, format = '0.00') {
  try {
    const a = numeral(value).format('0.0000')

    if (isNaN(a)) {
      // round to 6 decimals
      const b = parseFloat(Math.round(value * 100000) / 100000)
      return numeral(b).format(format)
    } else {
      return numeral(value).format(format)
    }
  } catch (e) {
    console.log('numeral validate error', e)
    return value
  }
}
