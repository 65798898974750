import React, { Component, useState, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { creditPaceService } from '../../../services'
import debounce from 'lodash.debounce'
import clsx from 'clsx'
// UI
import { Loading, Panel, ControlLabel, Page, Pager, SearchInput } from '../../../components'
import { Permissions } from '../../../constants'
import Col from 'antd/lib/col'
import Icon from 'antd/lib/icon'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Tooltip from 'antd/lib/tooltip'
import { auth, formatter, validator } from '../../../util'
import Radio from 'antd/lib/radio'
import Spin from 'antd/lib/spin'
import Alert from 'antd/lib/alert'
import './styles.css'
const { Group: RadioGroup, Button: RadioButton } = Radio

const dateFormat = 'DD/MM/YYYY'
const defaultPageSize = 10
const defaultFilter = { closed: false }
const RadioType = Object.freeze({
  Active: Object.freeze({ label: 'Active', value: 'active' }),
  Others: Object.freeze({ label: 'Others', value: 'others' }),
})


function CreditList (props) {
  const { clientId, clientRefId } = props || {}
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState(defaultFilter)
  const [init, setInit] = useState(true)
  const [list, setList] = useState([])
  const [total, setTotal] = useState(0)
  const [searchText, setSearchText] = useState()
  const [searching, setSearching] = useState(false)
  const [selectedRadioType, setSelectedRadioType] = useState(RadioType.Active.value)
  const [page, setPage] = useState(1)

  const listByPage = useCallback(({ page, filter: newFilter, text }) => {
    const _filter = { ...filter, ...newFilter, client_id: clientId }
    const _page = typeof page === 'number' && page > 0 ? page : 1
    const _text = searchText || text
    setFilter(_filter)
    setLoading(true)
    setPage(_page)
    creditPaceService.listByPage(_page, defaultPageSize, _filter, {}, _text)
      .then((response) => {
        if (validator.isObject(response)) {
          const { list, total } = response
          setList(list)
          setTotal(total)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [filter, searchText, clientId])

  const changePage = useCallback((page) => {
    listByPage({ page })
  }, [listByPage])

  const changeRadioType = useCallback(({ target }) => {
    const { value } = target || {}
    setSelectedRadioType(value)
    listByPage({ page: 1, filter: { closed: value !== RadioType.Active.value } })
  }, [listByPage])

  const searchName = useCallback(() => {
    const onSearchName = async (value) => {
      setLoading(true)
      setPage(1)
      setSearchText(value)
      setSearching(true)
      const _filter = { ...filter, client_id: clientId }
      try {
        const response = await creditPaceService.listByPage(1, defaultPageSize, _filter, {}, value)

        if (validator.isObject(response)) {
          const { list, total } = response
          setList(list)
          setTotal(total)
        }
      } finally {
        setLoading(false)
        setSearching(false)
      }
    }
    return debounce(onSearchName, 500)
  }, [filter, clientId])

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  useEffect(() => {
    let mounted = true

    if (!hasAccess(Permissions.CREDIT.INFO_PACE.LIST)) {
      setInit(false)
      return
    }

    setLoading(true)
    const updatedFilter = { ...defaultFilter, client_id: clientId }
    creditPaceService.listByPage(1, defaultPageSize, updatedFilter)
      .then((response) => {
        if (mounted && validator.isObject(response)) {
          const { list, total } = response
          setList(list)
          setTotal(total)
        }
      })
      .finally(() => {
        if (mounted) {
          setInit(false)
          setLoading(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [hasAccess, clientId])


  return (
    <Loading loading={init}>
      <Panel title={'(PACE) Credits'}
        subtitle={hasAccess(Permissions.CREDIT.INFO_PACE.CREATE)
          ? <div className='btn'>
            <a href={`/credits-pace/add?cid=${clientRefId}`} rel='noopener noreferrer' target='_blank'>
              Add Credit
            </a>
          </div>
          : null
        }
      >
        {hasAccess(Permissions.CREDIT.INFO_PACE.LIST)
          ? (
            <Page.Filter>
              <Row gutter={16}>
                <Col lg={12} xs={24}>
                  <ControlLabel>Category, Provider, Invoice Number</ControlLabel>
                  <SearchInput isSearching={searching} placeholder='Search' onChange={searchName()} />
                </Col>
                <Col lg={12} xs={24}>
                  <ControlLabel>&nbsp;</ControlLabel>
                  <RadioGroup value={selectedRadioType} onChange={changeRadioType}>
                    {Object.values(RadioType).map(({ label, value }) => (
                      <RadioButton key={value} value={value}>{label}</RadioButton>
                    ))}
                  </RadioGroup>
                </Col>
              </Row>
            </Page.Filter>
          )
          : null}

        <Skeleton active loading={init} blur>
          <Spin spinning={loading}>
            <div className='cp-credit-list'>
              {Array.isArray(list) ? list.map((item) => {
                const {
                  id, provider_ref_id: providerRefId, provider_name: providerName, invoice_ref_id: invoiceRefId,
                  invoice_number: invoiceNumber, invoice_jid_number: invoiceJidNumber, ref_id: refId,
                  category_number: categoryNumber, category_name: categoryName, amount, remaining_amount: remainingAmount,
                  comment, private_comment: privateComment, applied, created_at: createdAt
                } = item

                return (
                  <div className={clsx('list-item', applied ? 'list-item-active' : '')} key={id}>
                    <Row>
                      <Col lg={23}>
                        <Row style={{ borderBottom: '1px dotted #ccc', paddingBottom: 2 }}>
                          <Col lg={6}>
                            <span className='subtitle'>Category</span>
                            <span className='name'>{categoryName} ({categoryNumber})</span>
                          </Col>

                          {providerRefId ? (
                            <Col lg={6}>
                              <span className='subtitle'>Provider</span>
                              <span className='name'>
                                {hasAccess(Permissions.PROVIDER.INFO.READ)
                                  ? (
                                    <Link to={`/providers/${providerRefId}/info`} rel='noopener noreferrer' target='_blank'>
                                      {providerName}
                                    </Link>
                                  )
                                  : <>{providerName}</>}
                              </span>
                            </Col>
                          ) : null}

                          {invoiceRefId ? (
                            <Col lg={6}>
                              <span className='subtitle'>Invoice</span>
                              <span className='name'>
                                {hasAccess(Permissions.INVOICE.INFO_PACE.READ)
                                  ? (
                                    <Link to={`/invoices/${invoiceRefId}/info`} rel='noopener noreferrer' target='_blank'>
                                      {invoiceNumber} ({invoiceJidNumber.toUpperCase()})
                                    </Link>
                                  )
                                  : <>{invoiceNumber} ({invoiceJidNumber.toUpperCase()})</>}
                              </span>
                            </Col>
                          ) : null}
                        </Row>

                        <Row style={{ paddingTop: '6px' }}>
                          <Col lg={6}>
                            <span className='header date'>Credit Date</span>
                            <span className='value date'>{formatter.toDate(createdAt, dateFormat)}</span>
                          </Col>

                          <Col lg={6}>
                            <span className='header inv-amount'>Credit Amt</span>
                            <span className='value inv-amount'>{formatter.toPrice(amount)}</span>
                          </Col>

                          <Col lg={5}>
                            <span className='header rcv-amount'>Balance Amt</span>
                            <span className='value rcv-amount'>{formatter.toPrice(remainingAmount)}</span>
                          </Col>
                        </Row>

                        <Row gutter={16} style={{ marginTop: '6px' }}>
                          {comment ? (
                            <Col span={12}>
                              <Alert message='Credit Notes on Statement' description={comment} showIcon type='info' />
                            </Col>
                          ) : null}
                          {privateComment ? (
                            <Col span={12}>
                              <Alert message='Private Notes' description={privateComment} showIcon type='warning' />
                            </Col>
                          ) : null}
                        </Row>
                      </Col>

                      <Col lg={1} className='action-icon'>
                        {hasAccess(Permissions.CREDIT.INFO_PACE.READ)
                          ? (
                            <Link to={`/credits-pace/${refId}`} rel='noopener noreferrer' target='_blank'>
                              <div style={{ color: '#D66E00' }}>
                                <Tooltip mouseLeaveDelay={0} title='Manage credit'>
                                  <Icon type='form' />
                                </Tooltip>
                              </div>
                            </Link>
                          )
                          : null}
                      </Col>
                    </Row>
                  </div>
                )
              }) : null}
            </div>

            <Pager
              size={defaultPageSize}
              total={total}
              totalText={`Total ${total} credits`}
              current={page}
              onChange={changePage}
              style={{ marginTop: '15px' }}
            />
          </Spin>
        </Skeleton>
      </Panel>
    </Loading>
  )

}

export class ClientCredit extends Component {
  render () {
    return <CreditList {...this.props} />
  }
}

export default ClientCredit
