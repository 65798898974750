import React, { Component, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import clsx from 'clsx'

import { Permissions } from '../../../../constants'
import { pmRatePaceService } from '../../../../services'
import { refreshRates } from '../../../../states/actions'
import { auth, formatter, validator } from '../../../../util'

// UI
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Switch from 'antd/lib/switch'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'

import { Button, List, Page, Pager, SideModal } from '../../../../components'
import notify from '../../../../components/Notification'

import './styles.css'

const defaultPageSize = 20

function getRateColumns (hasAccess, handleEdit) {
  return Object.freeze([
    {
      key: 'name',
      title: 'Rate Name',
      width: 14,
      render: ({ name, active }) => <span className={clsx(active ? '' : 'inactive')}>{name}</span>
    },
    {
      key: 'created_at',
      title: 'Created At',
      width: 4,
      render: ({ active, created_at: createdAt }) => (
        <span className={clsx(active ? '' : 'inactive')}>{formatter.toShortDate(createdAt)}</span>)
    },
    {
      key: 'active',
      title: 'Enable',
      width: 4,
      render: ({ active }) => (
        <Icon className={clsx('rsp-icon', active ? 'active' : 'inactive')} type='check-circle' theme='filled' />
      )
    },
    {
      key: 'action',
      title: 'Action',
      width: 1,
      render: ({ id, name, active }) => {
        return (
          <div className='action-buttons'>
            {hasAccess(Permissions.SETTING.RATESETS_PACE.UPDATE)
              ? (
                <Tooltip mouseLeaveDelay={0} title='Edit'>
                  <div className={clsx('rsp-btn-icon', active ? 'active' : 'inactive')} onClick={handleEdit(id, name, active)}>
                    <Icon type='form' />
                  </div>
                </Tooltip>
              )
              : null}
          </div>
        )
      }
    }
  ])
}

function Rate (props) {
  const { form, refreshRates } = props || {}
  const { getFieldDecorator, resetFields, validateFields } = form || {}
  const [init, setInit] = useState(true)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [saving, setSaving] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [total, setTotal] = useState(0)

  const closeModal = useCallback(() => {
    setShowModal(false)
    resetFields()
  }, [resetFields])

  const listByPage = useCallback(({ page }) => {
    const _page = typeof page === 'number' && page > 0 ? page : 1
    setLoading(true)
    setPage(_page)
    pmRatePaceService.listByPage(_page, defaultPageSize)
      .then((response) => {
        if (validator.isObject(response)) {
          const { list, total } = response

          if (Array.isArray(list)) {
            setList(list)
          }

          if (typeof total === 'number') {
            setTotal(total)
          }
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const openModal = useCallback(() => {
    setShowModal(true)
  }, [])

  const changePage = useCallback((page) => {
    listByPage({ page })
  }, [listByPage])

  const handleAdd = useCallback(() => {
    setSelectedItem({})
    openModal()
  }, [openModal])

  const handleEdit = useCallback((id, name, active) => () => {
    setSelectedItem({ id, name, active })
    openModal()
  }, [openModal])

  const handleSave = useCallback(() => {
    if (loading || saving) {
      return
    }

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id } = selectedItem || {}
        setSaving(true)

        try {
          const response = id
            ? await pmRatePaceService.save(id, values)
            : await pmRatePaceService.add(values)

          if (response.id) {
            notify.success('Saved successfully', `Rate saved successfully.`)
            closeModal()
            listByPage(1, defaultPageSize)
            refreshRates(true)
          } else {
            notify.error('Unable to save successfully', `Unable to save rate successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save rate successfully. Please try again later.`)
        } finally {
          setSaving(false)
        }
      }
    })
  }, [closeModal, listByPage, refreshRates, validateFields, loading, saving, selectedItem])

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  useEffect(() => {
    if (!hasAccess(Permissions.SETTING.RATESETS_PACE.LIST)) {
      setInit(false)
      return
    }

    let mounted = true
    setLoading(true)
    pmRatePaceService.listByPage(1, defaultPageSize)
      .then((response) => {
        if (mounted && validator.isObject(response)) {
          const { list, total } = response

          if (Array.isArray(list)) {
            setList(list)
          }

          if (typeof total === 'number') {
            setTotal(total)
          }
        }
      })
      .finally(() => {
        if (mounted) {
          setInit(false)
          setLoading(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [hasAccess])

  return (
    <Skeleton active loading={init}>
      <div className='rsp-rate'>
        <Page.Header>
          {hasAccess(Permissions.SETTING.RATESETS_PACE.CREATE)
            ? <Button feedback={loading} onClick={handleAdd}>Add Rate</Button>
            : null}
        </Page.Header>

        <Spin spinning={loading}>
          <List cols={getRateColumns(hasAccess, handleEdit)} rows={list} />

          <Pager
            size={defaultPageSize}
            total={total}
            totalText={`Total ${total} rates`}
            current={page}
            onChange={changePage}
            style={{ marginTop: '15px' }}
          />
        </Spin>
      </div>

      <SideModal
        title={`${selectedItem?.id ? 'Edit' : 'Add'} Rate`}
        showModal={showModal}
        onClose={closeModal}
        buttons={[
          <Button key='0' feedback={saving} onClick={handleSave}>Save</Button>
        ]}
      >
        <Form layout='vertical'>
          <Form.Item label='Rate Name'>
            {getFieldDecorator('name', {
              initialValue: selectedItem.name,
              rules: [
                { required: true, min: 2, max: 128, message: 'Rate name must be between 2 and 128 characters' },
                { whitespace: true, message: 'Please enter rate name' },
              ],
            })(<Input disabled={saving} />)}
          </Form.Item>
          <Form.Item label=''>
            {getFieldDecorator('active', {
              initialValue: typeof selectedItem.active === 'boolean' ? selectedItem.active : true,
              valuePropName: 'checked',
            })(<Switch checkedChildren='Enable' unCheckedChildren='Disable' disabled={saving} />)}
          </Form.Item>
        </Form>
      </SideModal>
    </Skeleton>
  )
}

export class PaceRate extends Component {
  render () {
    return <Rate {...this.props} />
  }
}

const mapDispatchToProps = {
  refreshRates
}

const mapStateToProps = (state) => {
  return { ...state.RateSetPace }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(PaceRate))
