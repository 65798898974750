export const REFRESH_RATES = 'REFRESH_RATES'
export const REFRESH_RATE_SETS = 'REFRESH_RATE_SETS'
export const REFRESH_RATE_SET_CATEGORY_TEMPLATES = 'REFRESH_RATE_SET_CATEGORY_TEMPLATES'

export const refreshRates = (refresh) => {
  return {
    type: REFRESH_RATES,
    refresh
  }
}

export const refreshRateSets = (refresh) => {
  return {
    type: REFRESH_RATE_SETS,
    refresh
  }
}

export const refreshRateSetCategoryTemplates = (refresh) => {
  return {
    type: REFRESH_RATE_SET_CATEGORY_TEMPLATES,
    refresh
  }
}