import {
  SET_FILE_ATTACHMENT_COUNT, SET_FORM_ATTACHMENT_COUNT, SET_ON_SAVE, SET_ON_SAVE_AND_PROCESS, SET_ON_SAVE_AS_DRAFT,
  SET_SELECTED_INVOICE, SET_SELECTED_INVOICE_TYPE
} from '../actions/invoicePace'
import { InvoiceTypePace as InvoiceType } from '../../constants'

const INITIAL_STATE = {
  fileAttachmentCount: 0,
  formAttachmentCount: 0,
  onSave: undefined,
  onSaveAndProcess: undefined,
  onSaveAsDraft: undefined,
  selectedInvoice: undefined,
  selectedInvoiceType: InvoiceType.INV_TYPE_PM
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_FILE_ATTACHMENT_COUNT:
      return { ...handleSetFileAttachmentCount(state, action) }
    case SET_FORM_ATTACHMENT_COUNT:
      return { ...handleSetFormAttachmentCount(state, action) }
    case SET_ON_SAVE:
      return { ...handleSetOnSave(state, action) }
    case SET_ON_SAVE_AND_PROCESS:
      return { ...handleSetOnSaveAndProcess(state, action) }
    case SET_ON_SAVE_AS_DRAFT:
      return { ...handleSetOnSaveAsDraft(state, action) }
    case SET_SELECTED_INVOICE:
      return { ...handleSetSelectedInvoice(state, action) }
    case SET_SELECTED_INVOICE_TYPE:
      return { ...handleSetSelectedInvoiceType(state, action) }
    default:
      return state
  }
}

function handleSetFileAttachmentCount (state, action) {
  const { fileAttachmentCount } = action
  return { ...state, fileAttachmentCount }
}

function handleSetFormAttachmentCount (state, action) {
  const { formAttachmentCount } = action
  return { ...state, formAttachmentCount }
}

function handleSetOnSave (state, action) {
  const { onSave } = action
  return { ...state, onSave }
}

function handleSetOnSaveAndProcess (state, action) {
  const { onSaveAndProcess } = action
  return { ...state, onSaveAndProcess }
}

function handleSetOnSaveAsDraft (state, action) {
  const { onSaveAsDraft } = action
  return { ...state, onSaveAsDraft }
}

function handleSetSelectedInvoice (state, action) {
  const { selectedInvoice } = action
  return { ...state, selectedInvoice }
}

function handleSetSelectedInvoiceType (state, action) {
  const { selectedInvoiceType } = action
  return { ...state, selectedInvoiceType }
}
