import { request, requestFile, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/invoice-export-pace'

export default {
  listExportByPage (page, size, filter, sorting, text) {
    return request(`${url}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  getExport (exportId, exportType) {
    return requestFile(`${url}/file/${exportType}/${exportId}`)
  },
  getExportDetail (exportId, exportType) {
    return request(`${url}/detail/${exportType}/${exportId}`)
  },
  updateExport (exportId, exportType, values) {
    return request(`${url}/update/${exportType}/${exportId}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  getExportPost (values) {
    return requestFile(`${url}/file`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  getExportInvPost (values) {
    return requestFile(`${url}/file-inv`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  }
}
