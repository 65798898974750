import { request, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/comm-pace'

export default {
  listInvoiceComms (page, size, filter, sorting, text) {
    return request(`${url}/invoice/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  closeInvoiceComm (id, values) {
    return request(`${url}/invoice/${id}/close`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  rescheduleInvoiceComm (id, values) {
    return request(`${url}/invoice/${id}/reschedule`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  scheduleInvoiceComm (id, values) {
    return request(`${url}/invoice/${id}/schedule`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  stopInvoiceComm (id, values) {
    return request(`${url}/invoice/${id}/stop`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  saveInvoiceComm (id, values) {
    return request(`${url}/invoice/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  }
}
