import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import debounce from 'lodash.debounce'
import { invoiceService } from '../../../services'
import { setRefreshActivityLog } from '../../../states/actions'

// UI
import { Button, ControlLabel, Loading, List, Notification, Panel, Checkbox, Pager, SearchInput, Spin } from '../../../components'
import { Permissions } from '../../../constants'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'
import { auth, formatter, exportFile, validator } from '../../../util'
import Modal from 'antd/lib/modal'
import DatePicker from 'antd/lib/date-picker'
import './styles.css'

const TabPane = Tabs.TabPane
const notify = Notification
const { RangePicker } = DatePicker
const pageSize = 20

export class ClientInvoices extends Component {
  constructor (props) {
    super(props)
    this.state = {
      filterParam: 'incompleted',
      loading: false,
      loadingNext: false,
      invoices: { list: [], total: 0 },
      currentPage: 1,
      filter: {},
      searching: false,
      searchText: '',
      sort: {},
      currentTab: '1',
      total: 0,
      isShowDateRangePicker: false,
      isGenerating: false,
      isCheckMsgShow: false,
      isCheckFile: true,
      isCheckInvoice: true,
      isCheckDateRange: false
    }
    this.onSearchName = debounce(this.onSearchName, 500)
  }

  componentDidMount () {
    this.fetch()
  }

  componentWillReceiveProps (nextProps) {
    const { currentPage, filter, filterParam, searchText, sort } = this.state
    if (nextProps.clientId !== this.props.clientId) {
      if (nextProps.clientId) {
        this.fetchInvoices({ currentPage, filter, filterParam, searchText, sort }, nextProps.clientId)
      }
    }
  }

  onSearchName (value) {
    const { filter, filterParam, sort } = this.state
    this.setState({ searching: true })

    if (value.indexOf(' ') >= 0) {
      const words = value.split(' ')

      if (Array.isArray(words)) {
        filter.$and = []

        for (let i = 0; i < words.length; i++) {
          filter.$and.push({
            $or: [
              // { client_first_name: { condition: 'ilike', value: `%${words[i]}%` } },
              // { client_last_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { provider_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { string_invoice_date: { condition: 'ilike', value: `%${words[i]}%` } },
              { string_invoice_created_date: { condition: 'ilike', value: `%${words[i]}%` } },
              { invoice_number: { condition: 'ilike', value: `%${words[i]}%` } },
              { invoice_provider_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { status: { condition: 'ilike', value: `%${words[i]}%` } }
            ]
          })
        }
      }
    } else {
      if (Array.isArray(filter.$and)) {
        delete filter.$and
      }
    }
    this.fetchInvoices({ currentPage: 1, filter, filterParam, searchText: (filter.$and ? '' : value), sort })
    this.setState({ searchText: value })
  }

  render () {
    const { currentTab, isShowDateRangePicker, isGenerating, isCheckMsgShow, isCheckFile, isCheckInvoice, isCheckDateRange } = this.state

    return (
      <div>
          <div style={{ display: 'flex', justifyContent: 'right' }}>
             {this.hasAccess(Permissions.INVOICE.INFO.LIST) && (
               <Button feedback={isGenerating} onClick={() => this.toggleExportSelect()}>EXPORT</Button>
              )}
          </div>
        <Tabs defaultActiveKey={currentTab} type="card" onChange={this.changeTab} style={{ marginBottom: 32 }}>
          <TabPane tab="PM Invoices" key='1'>
            { this.renderBody('PM Invoices') }
          </TabPane>
          <TabPane tab="Standard Invoices" key='2'>
            { this.renderBody('Standard Invoices') }
          </TabPane>
        </Tabs>

        {isShowDateRangePicker
            ? <Modal
              width='450px'
              title='Select Invoices Export Items'
              visible={isShowDateRangePicker}
              onCancel={() => this.toggleExportSelect(false)}
              footer={[
                <Button key='close' ghost feedback={isGenerating} onClick={() => this.toggleExportSelect(false)}>Cancel</Button>,
                <Button key='submit' feedback={isGenerating} onClick={() => this.preCheckExport()}>Download</Button>
              ]}
            >
               <Spin spinning={isGenerating} blur>
                <Form>
                  <div className='inv-title'>Please select item(s) to export:</div>
                  <div>
                    <RangePicker format='DD/MM/YYYY' onChange={this.handleDateChange} />
                    {isCheckDateRange && (
                      <div className='checkbox-warning-text'>Please select a date range.</div>
                    )}
                  </div>
                  <div>
                    <Checkbox
                      checked={isCheckFile}
                      onClick={f => this.handleCheckboxClick(f, { isCheckFile: true })}
                    />
                    <span className='checkbox-text'>Invoice Export</span>
                  </div>
                  <div>
                    <Checkbox
                      checked={isCheckInvoice}
                      onClick={f => this.handleCheckboxClick(f, { isCheckInvoice: true })}
                    />
                    <span className='checkbox-text'>Invoice Details Export</span>
                  </div>
                  {isCheckMsgShow && (
                    <div className='checkbox-warning-text'>Please select at least one item.</div>
                  )}
                </Form>
              </Spin>
            </Modal>
            : null}
      </div>
    )
  }

  renderBody = (title) => {
    const { currentPage, currentTab, filterParam, loading, loadingNext, invoices, searching } = this.state

    const listColumns = [
      {
        title: 'JID',
        width: 2.5,
        render: ({ id_numbering, invoice_private_comment }) => {
          return <span>
            { formatter.capitalize(id_numbering, false) }
            { invoice_private_comment
              ? <span>&nbsp;
                <Tooltip mouseLeaveDelay={0} title={`Private Notes: ${invoice_private_comment || ''}`} >
                  <Icon type='edit' theme='filled' style={{color: '#eb7a34', fontSize: '11pt', cursor: 'pointer'}}/>
                </Tooltip>
              </span>
              : null}
          </span>
        }
      },
      {
        title: 'Created Date',
        width: 3,
        render: ({ created_at }) => <span>{formatter.toShortDate(created_at)}</span>
      },
      {
        title: 'Process Date',
        width: 3,
        render: ({ processed_at }) => <span>{formatter.toShortDate(processed_at)}</span>
      },
      {
        title: 'Inv Date',
        width: 3,
        render: ({ invoice_date }) => <span>{formatter.toShortDate(invoice_date)}</span>
      },
      {
        title: 'Inv No',
        width: 3,
        render: ({ invoice_number }) => <span>{invoice_number}</span>
      },

      // {
      //   title: 'Participant',
      //   width: 4,
      //   render: ({ client_name }) => <div>{client_name}</div>
      // },

      {
        title: 'Provider',
        width: 3,
        render: ({ provider_ref_id, provider_name, invoice_provider_name }) => {
          return provider_ref_id
            ? <div><a href={`/providers/${provider_ref_id}/info`} rel='noopener noreferrer' target='_blank'>{provider_name}</a></div>
            : invoice_provider_name
              ? <span style={{fontStyle: 'italic'}}>
                <span className='label-indicator reimb'>R</span>
                {invoice_provider_name}
              </span>
              : <div>N/A</div> }
      },
      {
        title: 'Subtotal',
        width: 3,
        render: ({ subtotal }) => <div>{formatter.toPrice(subtotal)}</div>
      },
      {
        title: 'Rcv Amt',
        width: 3,
        render: ({ received_subtotal }) => <div>{formatter.toPrice(received_subtotal)}</div>
      },
      {
        title: 'Item Count',
        width: 2,
        render: ({ qty }) => <div>{qty}</div>
      },
      {
        title: 'Status',
        width: 3,
        render: ({ status, status_name, status_color }) => <div className={'status'} style={{backgroundColor: status_color}}>
        {status_name ? status_name : formatter.capitalize(status)}
      </div>
      },

      {
        title: 'Action',
        width: 1,
        render: ({ id, ref_id }) => <div className='action-buttons'>
          { this.hasAccess(Permissions.PARTICIPANT.INVOICE.READ)
            ? <Link to={`/invoices/${ref_id}/info`}>
            { this.hasAccess('readInvoice') || this.hasAccess('updateInvoice')
              ? <div style={{ color: '#D66E00' }}>
                <Tooltip mouseLeaveDelay={0} title='Edit'>
                  <Icon type='form' />
                </Tooltip>
              </div>
              : null }
          </Link>
            : null }
        </div>
      }
    ]

    return (
      <Panel title={title}>
        <div className='client-inv-list'>
          { this.hasAccess(Permissions.PARTICIPANT.INVOICE.LIST)
            ? <Row style={{marginBottom: '20px'}} gutter={8}>
              <Col lg={8}>
                <ControlLabel>JID #, Date, Provider, Invoice Number, Status</ControlLabel>
                <SearchInput placeholder='Search' onChange={(v) => this.onSearchName(v)} isSearching={searching} />
              </Col>
              <Col lg={10}>
                <div style={{marginLeft: '12.5pt'}}>
                  <ControlLabel>Invoices Completion</ControlLabel>
                </div>
                <Radio.Group onChange={this.filterInvoice} value={filterParam} style={{ marginLeft: 20 }}>
                  <Radio.Button value='incompleted'>Incompleted</Radio.Button>
                  <Radio.Button value='closed'>Closed</Radio.Button>
                  <Radio.Button value='all'>All</Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
            : null }

          <Skeleton loading={loading} active>
            <Loading loading={loadingNext} blur>
              <List cols={listColumns} rows={invoices.list} />

              <Pager
                size={pageSize}
                total={invoices.total}
                totalText={`Total ${invoices.total} invoices`}
                current={currentPage}
                onChange={this.changePage}
                style={{ marginTop: '15px' }}
              />
            </Loading>
          </Skeleton>
        </div>
      </Panel>
    )
  }

  changeTab = (index) => {
    this.setState({
      filterParam: 'incompleted',
      invoices: { list: [], total: 0 },
      currentPage: 1,
      filter: {},
      searching: false,
      searchText: '',
      sort: {},
      currentTab: index,
      total: 0
    }, () => {
      this.fetch()
    })
  }

  changePage = (currentPage) => {
    this.setState({ currentPage }, () => {
      this.fetch()
    })
    // const { filter, filterParam, searchText, sort } = this.state
    // this.fetchInvoices({ currentPage, filter, searchText, sort, filterParam })
  }

  fetch = () => {
    const { currentPage, filter, filterParam, searchText, sort } = this.state
    this.fetchInvoices({ currentPage, filter, filterParam, searchText, sort })
  }

  fetchInvoices = async ({ loading = true, currentPage = 1, filter = {}, sort = {}, searchText, filterParam }, cid = null) => {
    if (!this.hasAccess(Permissions.PARTICIPANT.INVOICE.LIST)) return
    try {
      const { total, currentTab } = this.state
      const clientId = cid || this.props.clientId
      this.setState({ currentPage, loading: currentPage !== 1 || total > 0 ? false : true, loadingNext: currentPage === 1 && total === 0 ? false : true })

      if (!clientId) return

      if (filterParam === 'incompleted') {
        filter.status = { condition: '<>', value: 'inv-closed' }
      } else if (filterParam === 'closed') {
        filter.status = { condition: '=', value: 'inv-closed' }
      } else if (filterParam === 'all') {
        filter = {}
      }

      if (currentTab === '1') {
        filter.is_sdb_invoice = { condition: '=', value: false }
      } else if (currentTab === '2') {
        filter.is_sdb_invoice = { condition: '=', value: true }
      } else {
        delete filter.is_sdb_invoice
      }

      filter.client_id = clientId
      const r = await invoiceService.listViewByPage(currentPage, pageSize, filter, sort, searchText)

      this.setState({
        loading: false,
        loadingNext: false,
        invoices: validator.isNotEmptyArray(r.list) ? r : { list: [], total: 0 },
        total: validator.isNotEmptyArray(r.list) ? r.total : 0,
        searching: false
      })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load invoices successfully. Please try again later.')
    }
  }

  filterInvoice = (e) => {
    const { filter, loading, searchText, sort } = this.state
    const filterValue = e.target.value

    this.setState({ filterParam: filterValue, total: 0 }, () => {
      this.fetchInvoices({ currentPage: 1, filter, loading, searchText, sort, filterParam: filterValue })
    })
  }

    /** export related */
    toggleExportSelect = (visible) => {
      if (!visible) {
        // Refresh the modal content when it is closed
        this.resetModalState()
      }

      this.setState({ isShowDateRangePicker: !this.state.isShowDateRangePicker })
    }

    resetModalState = () => {
      this.setState({
        isCheckFile: true,
        isCheckInvoice: true,
        isCheckMsgShow: false,
        isCheckDateRange: true
      })
    }

    handleDateChange = (dates) => {
      if (Array.isArray(dates) && dates.length > 1) {
        this.setState({ startDate: dates[0], endDate: dates[1], isCheckDateRange: false })
      } else {
        this.setState({ startDate: null, endDate: null, isCheckDateRange: true })
      }
    }

    handleCheckboxClick(e, { isCheckFile, isCheckInvoice }) {
      const check = e.target.checked
      this.setState({
        isCheckFile: isCheckFile === undefined ? this.state.isCheckFile : check,
        isCheckInvoice: isCheckInvoice === undefined ? this.state.isCheckInvoice : check
      }, () => {
        const {isCheckFile,isCheckInvoice } = this.state

        if (!(isCheckFile || isCheckInvoice)) {
          this.setState({ isCheckMsgShow: true })
        } else {
          this.setState({ isCheckMsgShow: false })
        }
      })
    }

    preCheckExport() {
      const {startDate, endDate, isCheckFile, isCheckInvoice , isCheckMsgShow, isCheckDateRange } = this.state

      if (!startDate || !endDate) {
        this.setState({ isCheckDateRange: true })
        return
      }

      if (!isCheckMsgShow && !isCheckDateRange) {
        this.export(startDate, endDate, isCheckFile, isCheckInvoice)
      }
    }

    async export(startDate, endDate, isCheckFile, isCheckInvoice) {
      const { clientId } = this.props
      this.setState({ isShowDateRangePicker: false, isGenerating: true })
      const data = {
        start_date: startDate,
        end_date: endDate,
        export_file: isCheckFile,
        export_invoice: isCheckInvoice,
        client_id: clientId
      }

      try {
        this.setState({ isGenerating: true })
        const r = await exportFile.fetchExport('invoice', data)
        setTimeout(() => {
          this.setState({ isGenerating: false, isShowDateRangePicker: false })
        }, 3000)
      } catch (e) {
        notify.error('Unable to export', 'Unable to get invoice export successfully. Please try again later.')
        this.setState({ isGenerating: false,isShowDateRangePicker: false })
      }
    }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClientInvoices))
