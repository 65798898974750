import React, { Component, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import BigNumber from 'bignumber.js'
import clsx from 'clsx'
import moment from 'moment-timezone'

import Permissions from '../../../../constants/permissions'
import { clientPlanPaceService, pmRateSetCategoryPaceService, pmRateSetPaceService, clientService } from '../../../../services'
import { auth, common, formatter, validator, periodValidator } from '../../../../util'

// UI
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'

import { Button, List, Page, Panel } from '../../../../components'
import notify from '../../../../components/Notification'

import './styles.css'

const { searchDropdown } = common
const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const { Item: FormItem } = Form
const { TextArea } = Input
const { Option } = Select

const dateFormat = 'DD/MM/YYYY'
const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 6 },
  wrapperCol: { sm: 18, md: 18, lg: 18 }
}
const formItemDateLayout = {
  labelCol: { sm: 6, md: 6, lg: 12 },
  wrapperCol: { sm: 18, md: 18, lg: 12 }
}

const ListItemField = Object.freeze({
  Id: 'id',
  FundingId: 'funding_id',
  PlanId: 'plan_id',
  StartDate: 'start_date',
  EndDate: 'end_date',
  CategoryId: 'category_id',
  CategoryNumber: 'category_number',
  ItemId: 'item_id',
  ItemNumber: 'item_number',
  AllocatedAmount: 'allocated_amount',
  AvailableAmount: 'available_amount',
  CarryOverAmount: 'carry_over_amount',
  SpentAmount: 'spent_amount',
  Amount: 'amount',
  IsPmFee: 'is_pm_fee',
  IsDiffAllocated: 'is_diff_allocated',
})
const ListItemValidateLabel = Object.freeze({
  AllocatedAmount: 'Allocated amount',
  AvailableAmount: 'Balance',
  CarryOverAmount: 'Carry over',
  SpentAmount: 'Spent',
  Amount: 'Remaining balance',
})

function getDefaultClientFundings (clientPlan) {
  const { client_funding_items: clientFundingItems } = clientPlan || {}
  const clientFundingLookup = new Map()
  const clientFundingItemsLookup = new Map()

  if (Array.isArray(clientFundingItems)) {
    for (const clientFundingItem of clientFundingItems) {
      const {
        id: cfId, client_id: clientId, plan_id: planId, id_number: idNumber, start_date: startDate, end_date: endDate,
        is_pm_fee: isPmFee, setup_cost_invoiced: setupCostInvoiced, monthly_fee_invoiced: monthlyFeeInvoiced,
        setup_cost_reason: setupCostReason, monthly_fee_reason: monthlyFeeReason, active
      } = clientFundingItem
      const clientFunding = clientFundingLookup.get(cfId) || {
        id: cfId, client_id: clientId, plan_id: planId, id_number: idNumber, start_date: startDate, end_date: endDate,
        is_pm_fee: isPmFee, setup_cost_invoiced: setupCostInvoiced, monthly_fee_invoiced: monthlyFeeInvoiced,
        setup_cost_reason: setupCostReason, monthly_fee_reason: monthlyFeeReason, active, items: []
      }
      const { items } = clientFunding
      items.push(clientFundingItem)
      clientFundingLookup.set(cfId, clientFunding)
      clientFundingItemsLookup.set(cfId, items)
    }
  }

  return clientFundingLookup.size > 0 ? Array.from(clientFundingLookup.values()) : []
}

function getDefaultClientFundingItems (clientPlanItems) {
  if (Array.isArray(clientPlanItems)) {
    return clientPlanItems.map((clientPlanItem) => {
      const {
        category_id: categoryId, category_number: categoryNumber, item_id: itemId, item_number: itemNumber,
        allocated_amount: allocatedAmount, available_amount: availableAmount, carry_over_amount: carryOverAmount,
        cfi_spent_amount: spentAmount, amount, is_pm_fee: isPmFee, is_diff_allocated: isDiffAllocated
      } = clientPlanItem
      return {
        cfi_category_id: categoryId, cfi_category_number: categoryNumber, cfi_item_id: itemId, cfi_item_number: itemNumber,
        cfi_allocated_amount: allocatedAmount, cfi_available_amount: availableAmount, cfi_carry_over_amount: carryOverAmount,
        cfi_spent_amount: spentAmount, cfi_amount: amount, cfi_is_pm_fee: isPmFee, cfi_is_diff_allocated: isDiffAllocated
      }
    })
  }

  return []
}

function getListItemFieldName (list, idx, field) {
  if (validator.isId(idx) && typeof field === 'string' && field.trim().length > 0) {
    return `${list}[${idx}].${field}`
  }

  return ''
}

function getFundingPeriodFieldName (idx, field) {
  return getListItemFieldName('client_fundings', idx, field)
}

function getFundingPeriodItemFieldName (cfIdx, idx, field) {
  return getListItemFieldName(`client_fundings[${cfIdx}].items`, idx, field)
}

function getPlanPeriodItemFieldName (idx, field) {
  return getListItemFieldName('client_plan_items', idx, field)
}

function handlePasteAmount (event) {
  const pastedText = common.getPastedText(event)

  if (pastedText) {
    const cleanedData = formatter.toBigNumber(formatter.parseAmount(pastedText))
    const amount = !cleanedData.isNaN() ? cleanedData.toFormat() : ''
    // No better alternative than document.execCommand at the moment
    document.execCommand('insertText', false, amount)
  }
}

function renderColumnSupportCategory ({
  categories, disabled, error, fieldName, getFieldDecorator, initialValue, loadingDropdown, showExtra, onChange
}) {
  return (
    <div className={clsx(error ? 'has-error' : '')}>
      {getFieldDecorator(fieldName, {
        initialValue,
        rules: [
          { required: true, message: 'Please select support category' },
        ]
      })(
        <Select
          disabled={loadingDropdown || disabled}
          dropdownMatchSelectWidth={false}
          filterOption={searchDropdown}
          loading={loadingDropdown}
          placeholder="Select Support Category"
          showSearch
          onChange={onChange}
          style={{ width: '330px', color: disabled ? 'black' : 'inherit' }}
        >
          {categories.map(({ category_id: id, category_number: categoryNumber, category_name: categoryName }) => (
            <Option key={id} value={categoryNumber}>
              ({categoryNumber.padStart(2, '0')}) {categoryName}
            </Option>
          ))}
        </Select>
      )}

      {error ? <div className="ant-form-explain">{error}</div> : null}

      {showExtra ? <div className="ant-form-extra">&nbsp;</div> : null}
    </div>
  )
}

function renderColumnSuppportItem () {

}

function renderColumnAvailableBalance ({ availableAmount, getFieldDecorator, error, fieldName, showExtra, onChange, onValidate }) {
  return (
    <div className={clsx(error ? 'has-error' : '')}>
      {getFieldDecorator(fieldName, {
        initialValue: !availableAmount.isNaN() ? availableAmount.toFormat(2) : undefined,
        rules: [
          { required: true, message: 'Please enter balance' },
          { validator: onValidate }
        ]
      })(
        <Input
          addonBefore='$'
          onPaste={handlePasteAmount}
        // onChange={handleChange(idx, 'availableBalance')}
        // onChange={onChange(idx, 'availableBalance')}
        />
      )}

      {error ? <div className="ant-form-explain">{error}</div> : null}

      {showExtra ? <div className="ant-form-extra">&nbsp;</div> : null}
    </div>
  )
}

function renderColumnAllocatedAmount ({
  allocatedAmount, error, fieldNameAA, fieldNameIDA, fvIsDiffAllocated, getFieldDecorator, isDiffAllocated, showExtra, onChange,
  onValidate
}) {
  if (fvIsDiffAllocated !== true) {
    getFieldDecorator(fieldNameAA, { initialValue: !allocatedAmount.isNaN() ? allocatedAmount.toFormat(2) : undefined })
  }

  return (
    <Row className='flex-box'>
      <Col lg={6}>
        {getFieldDecorator(fieldNameIDA, {
          initialValue: typeof isDiffAllocated === 'boolean' ? isDiffAllocated : false,
          valuePropName: 'checked',
        })(
          // <Switch checkedChildren="Diff" unCheckedChildren="Same" onChange={handleSwitchChange(idx)} />
          <Switch checkedChildren="Diff" unCheckedChildren="Same" />
        )}

        {showExtra ? <div className="ant-form-extra">&nbsp;</div> : null}
      </Col>

      {fvIsDiffAllocated ? (
        <Col className={clsx(error ? 'has-error' : '')} lg={18}>
          {getFieldDecorator(fieldNameAA, {
            initialValue: !allocatedAmount.isNaN() ? allocatedAmount.toFormat(2) : undefined,
            rules: [
              { required: true, message: 'Please enter allocated amount' },
              // { validator: validateAllocatedAmount(idx, formattedAmount) }
              // { validator: onValidate(allocatedAmount, isDiffAllocated, forecastSpent) }
            ]
          })(
            <Input
              addonBefore='$'
              onPaste={handlePasteAmount}
            // onChange={handleChange(idx, 'allocatedAmount')}
            />
          )}

          {error ? <div className="ant-form-explain">{error}</div> : null}

          {showExtra ? <div className="ant-form-extra">&nbsp;</div> : null}
        </Col>
      ) : null}
    </Row>
  )
}

function renderColumnCarryOverAmount ({ carryOverAmount, error, fieldName, getFieldDecorator, showExtra, onValidate }) {
  return (
    <div className={clsx(error ? 'has-error' : '')}>
      {getFieldDecorator(fieldName, {
        initialValue: !carryOverAmount.isNaN() ? carryOverAmount : undefined,
        rules: [
          { validator: onValidate }
        ]
      })(
        <Input
          addonBefore='$'
          onPaste={handlePasteAmount}
        />
      )}

      {error ? <div className="ant-form-explain">{error}</div> : null}

      {showExtra ? <div className="ant-form-extra">&nbsp;</div> : null}
    </div>
  )
}

function renderColumnSpent ({ forecastSpent, showExtra, spentAmount }) {
  return (
    <>
      <div>$ {spentAmount.toFormat(2)}</div>

      {showExtra ? (
        <div className="ant-form-extra">
          ({BigNumber(!forecastSpent.isNaN() ? forecastSpent : 0).toFormat(2)})
        </div>
      ) : null}
    </>
  )
}

function renderColumnAction ({ hasAccess, showExtra, onRemove }) {
  return (
    <>
      <div>
        {hasAccess(Permissions.PARTICIPANT.PP_PACE.DELETE) ? (
          <Tooltip mouseLeaveDelay={0} title="Remove Support Category">
            <Icon className='btn-icon' type="delete" onClick={typeof onRemove === 'function' ? onRemove : null} />
          </Tooltip>
        ) : null}
      </div>

      {showExtra ? <div className="ant-form-extra">&nbsp;</div> : null}
    </>
  )
}

function toBigNumber (value) {
  const bigNumber = formatter.toBigNumber(value)
  return !bigNumber.isNaN() ? bigNumber : BigNumber(0)
}

function sortListItem ({ aIsPmFee, aCategorySorting, aItemSorting }, { bIsPmFee, bCategorySorting, bItemSorting }) {
  if (aIsPmFee !== bIsPmFee) {
    return aIsPmFee ? -1 : 1
  }

  if (aCategorySorting !== bCategorySorting) {
    return aCategorySorting - bCategorySorting
  }

  return aItemSorting - bItemSorting
}

const validateAmount = (label, value) => {
  if (!validator.isNullOrUndefined(value) && !validator.isEmptyString(value, true)) {
    const amount = formatter.toBigNumber(value)

    if (
      !validator.isCurrencyAmount(value) || amount.isNaN() || !amount.isFinite() || amount.decimalPlaces() > 2
    ) {
      return new Error('Please enter a number with 2 decimal places')
    } else if (amount.isLessThan(0)) {
      return new Error(`${validator.isEmptyString(label, true) ? 'Amount' : label} must be greater than 0`)
    }
  }

  return
}




function getFundingPeriodItemColumns (
  categories, categoryItems, clientFundingIdx, form, hasAccess, isEdit, loadingDropdown, onAdd, onRemove
) {
  const cfIdx = clientFundingIdx
  const { getFieldDecorator, getFieldError, getFieldValue, setFieldsValue } = form || {}
  const categoryLookup = new Map(categories.map((category) => [category.category_number, category]))

  const changeCategory = (idx) => (value) => {
    const { category_id: categoryId } = categoryLookup.get(value) || {}
    setFieldsValue({
      [getFundingPeriodItemFieldName(cfIdx, idx, ListItemField.CategoryId)]: categoryId,
      [getFundingPeriodItemFieldName(cfIdx, idx, ListItemField.ItemId)]: undefined,
      [getFundingPeriodItemFieldName(cfIdx, idx, ListItemField.ItemNumber)]: undefined
    })
  }

  const validateAvailableBalance = (allocatedAmount, isDiffAllocated, forecastSpent) => (rule, value, callback) => {
    const error = validateAmount(ListItemValidateLabel.AvailableAmount, value)
    const hasValue = !validator.isNullOrUndefined(value) && !validator.isEmptyString(value, true)
    const _allocatedAmount = toBigNumber(allocatedAmount)
    const _forecastSpent = toBigNumber(forecastSpent)
    const availableBalance = toBigNumber(value)
    const spent = _allocatedAmount.minus(availableBalance)

    if (error) {
      callback(error)
    } else if (hasValue && isDiffAllocated === true && availableBalance.isGreaterThan(_allocatedAmount)) {
      callback(new Error('Balance must be less than or equal to allocated amount'))
    } else if (
      hasValue && ((isDiffAllocated === true && spent.isLessThan(_forecastSpent)) || availableBalance.isLessThan(_forecastSpent))
    ) {
      callback(new Error(
        `${ListItemValidateLabel.Amount} must be greater than or equal to forecasted spent (${_forecastSpent.toFormat(2)})`
      ))
    } else {
      callback()
    }
  }

  return Object.freeze([
    {
      title: (
        <div className='button-box'>
          <div className='label'>Support Category</div>

          <Tooltip mouseLeaveDelay={0} title="Add Support Category">
            <Icon className='btn-icon' type="plus-circle" onClick={typeof onAdd === 'function' ? onAdd : null} />
          </Tooltip>
        </div>
      ),
      width: 6,
      render: (record, idx) => {
        const { cfi_id: cfiId, cfi_category_number: categoryNumber } = record
        const disabled = isEdit && !validator.isNullOrUndefined(categoryNumber)
        const categoryLookup = new Map(categories.map((category) => [category.category_number, category]))
        const category = (idx === 0 ? categoryLookup.get('14') : undefined) || {}
        const fieldName = getFundingPeriodItemFieldName(cfIdx, idx, ListItemField.CategoryNumber)
        const error = getFieldError(fieldName)
        const initialValue = disabled ? categoryNumber : (idx === 0 ? '14' : undefined)
        getFieldDecorator(getFundingPeriodItemFieldName(
          cfIdx, idx, ListItemField.CategoryId), { initialValue: cfiId || category.category_id }
        )
        return renderColumnSupportCategory({
          categories, disabled, error, fieldName, getFieldDecorator, initialValue, loadingDropdown, onChange: changeCategory(idx)
        })
      }
    },
    {
      title: 'Support Item',
      width: 5,
      // render: (record, idx) => {
      //   const fieldName = `client_fundings[${cfIdx}].items[${idx}].item_number`
      //   const error = getFieldError(fieldName)
      //   const categoryNumber = getFieldValue(`client_fundings[${cfIdx}].items[${idx}].category_number`)
      //   const seenItemNumbers = new Set()
      //   const category = categoryItem.filter(category => Number(category.category_number) === 14)
      //   const disabled = isEdit && items[idx].cfi_item_number !== undefined

      //   const validateDuplicateValue = () => {
      //     return (rule, value, callback) => {
      //       const itemNumbersInSameCategory = getFieldValue('client_fundings')?.map(funding =>
      //         funding.items.filter(item => item.category_number === categoryNumber)
      //       ).flat().map(item => item.item_number)
      //       const duplicateCount = itemNumbersInSameCategory.filter(itemNumber => itemNumber === value && itemNumber !== undefined && itemNumber !== null).length
      //       const emptyCount = itemNumbersInSameCategory.filter(itemNumber => itemNumber === undefined || itemNumber === null).length
      //       if (emptyCount > 1) {
      //         callback(new Error('Either one needs to have value'))
      //       } else if (duplicateCount > 1) {
      //         callback(new Error('This support item number has already been selected'))
      //       } else {
      //         callback()
      //       }
      //     }
      //   }

      //   return (
      //     <div className={clsx(error ? 'has-error' : '')}>
      //       {getFieldDecorator(`client_fundings[${cfIdx}].items[${idx}].item_number`, {
      //         initialValue: items[idx].cfi_item_number || undefined,
      //         rules: [
      //           { validator: validateDuplicateValue() }
      //         ]
      //       })(
      //         <Select
      //           allowClear
      //           disabled={disabled}
      //           dropdownMatchSelectWidth={false}
      //           filterOption={searchDropdown}
      //           showSearch
      //           placeholder="Select Support Item"
      //           style={{ width: '250px', color: disabled ? 'black' : 'inherit' }}
      //         >
      //           {!isEdit && idx === 0 ?
      //             category
      //               .filter(({ category_number, item_number: ItemNumber }) => {
      //                 if (Number(category_number) === 14 && !seenItemNumbers.has(ItemNumber)) {
      //                   seenItemNumbers.add(ItemNumber)
      //                   return true
      //                 }
      //                 return false
      //               })
      //               .map(({ item_number: ItemNumber, item_name: ItemName }) => (
      //                 <Option key={ItemNumber} value={ItemNumber}>
      //                   {ItemName} ({ItemNumber})
      //                 </Option>
      //               ))
      //             :
      //             categoryItem
      //               .filter(({ category_number, item_number: ItemNumber }) => {
      //                 if (category_number === categoryNumber && !seenItemNumbers.has(ItemNumber)) {
      //                   seenItemNumbers.add(ItemNumber)
      //                   return true
      //                 }
      //                 return false
      //               })
      //               .map(({ item_number: ItemNumber, item_name: ItemName }) => (
      //                 <Option key={ItemNumber} value={ItemNumber}>
      //                   {ItemName} ({ItemNumber})
      //                 </Option>
      //               ))
      //           }
      //         </Select>
      //       )}
      //       {error ? <div className="ant-form-explain">{error}</div> : null}
      //     </div>
      //   )
      // }
    },
    {
      title: 'Available Balance',
      width: 3,
      render: (record, idx) => {
        const { cfi_available_amount: availableAmount, forecast_spent: forecastSpent } = record
        const _availableAmount = formatter.toBigNumber(availableAmount)
        const fieldName = getFundingPeriodItemFieldName(cfIdx, idx, ListItemField.AvailableAmount)
        const error = getFieldError(fieldName)
        const allocatedAmount = getFieldValue(getFundingPeriodItemFieldName(cfIdx, idx, ListItemField.AllocatedAmount))
        const isDiffAllocated = getFieldValue(getFundingPeriodItemFieldName(cfIdx, idx, ListItemField.IsDiffAllocated))
        return renderColumnAvailableBalance({
          availableAmount: _availableAmount, getFieldDecorator, error, fieldName, // onChange
          onValidate: validateAvailableBalance(allocatedAmount, isDiffAllocated, forecastSpent)
        })
      }
    },
    {
      title: 'Action',
      width: 10,
      render: (record, idx) => {
        const { id: fundingId, cfi_id: id, cfi_amount: amount, cfi_is_pm_fee: isPmFee } = record
        const categoryNumber = getFieldValue(getFundingPeriodItemFieldName(cfIdx, idx, ListItemField.isPmFee))
        getFieldDecorator(getFundingPeriodItemFieldName(cfIdx, idx, ListItemField.Id), { initialValue: id })
        getFieldDecorator(getFundingPeriodItemFieldName(cfIdx, idx, ListItemField.FundingId), { initialValue: fundingId })
        getFieldDecorator(getFundingPeriodItemFieldName(
          cfIdx, idx, ListItemField.Amount), { initialValue: toBigNumber(amount).toFixed(2) }
        )
        getFieldDecorator(getFundingPeriodItemFieldName(cfIdx, idx, ListItemField.IsPmFee), {
          initialValue: !validator.isNullOrUndefined(categoryNumber) ? categoryNumber === '14' : !!isPmFee
        })
        return renderColumnAction({ hasAccess, onRemove: onRemove(cfIdx, idx) })
      }
    }
  ])
}

function FundingPeriodFormHeader ({ onAdd, onRemove }) {
  const addClientFunding = useCallback(() => {
    if (typeof onAdd === 'function') {
      onAdd()
    }
  }, [onAdd])

  const removeClientFunding = useCallback(() => {
    if (typeof onRemove === 'function') {
      onRemove()
    }
  }, [onRemove])

  return (
    <Row gutter={16}>
      <Col className='row-header' lg={6}>
        <div className='label'>Funding Period</div>

        <Tooltip mouseLeaveDelay={0} title='Add Funding Period'>
          <Icon className='btn-icon' type='plus-circle' theme='filled' onClick={addClientFunding} />
        </Tooltip>

        <Tooltip mouseLeaveDelay={0} title='Remove Funding Period'>
          <Icon className='btn-icon' type='minus-circle' theme='filled' onClick={removeClientFunding} />
        </Tooltip>
      </Col>
    </Row>
  )
}

function FundingPeriodItemList ({
  categories, categoryItems, clientFundingIdx, form, hasAccess, isEdit, listItems, loadingDropdown, onAdd, onRemove
}) {
  const rows = Array.isArray(listItems)
    ? listItems.sort((a, b) => {
      const { cfi_category_sorting: aCategorySorting, cfi_item_sorting: aItemSorting, cfi_is_pm_fee: aIsPmFee } = a
      const { cfi_category_sorting: bCategorySorting, cfi_item_sorting: bItemSorting, cfi_is_pm_fee: bIsPmFee } = b
      return sortListItem({ aIsPmFee, aCategorySorting, aItemSorting }, { bIsPmFee, bCategorySorting, bItemSorting })
    })
    : []
  return (
    <Skeleton active loading={loadingDropdown}>
      <List
        cols={getFundingPeriodItemColumns(
          categories, categoryItems, clientFundingIdx, form, hasAccess, isEdit, loadingDropdown, onAdd, onRemove
        )}
        rows={rows}
      />
    </Skeleton>
  )
}

function FundingPeriodForm ({ categories, categoryItems, hasAccess, isEdit, isValidDates, item, props }) {
  const { form } = props || {}
  const { getFieldDecorator, getFieldsValue } = form || {}
  const { client_plan_items: fvClientPlanItems } = getFieldsValue()
  const [clientFundings, setClientFundings] = useState(getDefaultClientFundings(item))

  const addClientFunding = useCallback(() => {
    const items = getDefaultClientFundingItems(fvClientPlanItems)
    setClientFundings((clientFundings) => clientFundings.concat([{ items }]))
  }, [fvClientPlanItems])

  const removeClientFunding = useCallback((idx) => () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this funding period?',
      content: 'Press Ok to continue, Cancel to return',
      onOk: () => {
        setClientFundings(
          (clientFundings) => idx > -1 ? clientFundings.filter((_, _idx) => _idx !== idx) : clientFundings
        )
      }
    })
  }, [])

  const addClientFundingItem = useCallback((clientFunding) => () => {
    const { items } = clientFunding
    clientFunding.items = (Array.isArray(items) ? items : []).concat([{}])
    setClientFundings((clientFundings) => [].concat(clientFundings))
  }, [])

  const removeClientFundingItem = useCallback((clientFunding) => (cfIdx, idx) => () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this support category?',
      content: 'Press Ok to continue, Cancel to return',
      onOk: () => {
        const { items } = clientFunding
        setClientFundings((clientFundings) => {
          return Array.isArray(items) && cfIdx > -1 && idx > -1
            ? clientFundings.map((_cf, _cfIdx) => {
              if (_cfIdx === cfIdx) {
                _cf.items = _cf.items.filter((_, _idx) => _idx !== idx)
              }

              return _cf
            })
            : clientFundings
        })
      }
    }
    )
  }, [])

  return (
    <div className='cf-list'>
      {clientFundings.map((clientFunding, idx) => {
        const { id: cfId, plan_id: cfPlanId, start_date: cfStartDate, end_date: cfEndDate, items: cfItems } = clientFunding
        getFieldDecorator(getFundingPeriodFieldName(idx, ListItemField.Id), { initialValue: cfId })
        getFieldDecorator(getFundingPeriodFieldName(idx, ListItemField.PlanId), { initialValue: cfPlanId })

        return (
          <div key={idx}>
            <FundingPeriodFormHeader onAdd={addClientFunding} onRemove={removeClientFunding(idx)} />

            <Panel>
              <Row gutter={16}>
                <Col lg={6}>
                  <FormItem {...formItemDateLayout} label="Start Date">
                    {getFieldDecorator(getFundingPeriodFieldName(idx, ListItemField.StartDate), {
                      initialValue: validator.isDate(cfStartDate) ? moment(cfStartDate) : undefined,
                      rules: [
                        { required: true, message: 'Please select start date' },
                      ]
                    })(
                      <DatePicker format={dateFormat} placeholder="Select Start Date" />
                    )}
                  </FormItem>
                </Col>

                <Col lg={6}>
                  <FormItem {...formItemDateLayout} label="End Date">
                    {getFieldDecorator(getFundingPeriodFieldName(idx, ListItemField.EndDate), {
                      initialValue: validator.isDate(cfEndDate) ? moment(cfEndDate) : undefined,
                      rules: [
                        { required: true, message: 'Please select end date' },
                      ]
                    })(
                      <DatePicker format={dateFormat} placeholder="Select End Date" />
                    )}
                  </FormItem>
                </Col>
              </Row>

              <FundingPeriodItemList
                categories={categories}
                categoryItems={categoryItems}
                clientFundingIdx={idx}
                form={form}
                hasAccess={hasAccess}
                isEdit={isEdit}
                listItems={Array.isArray(cfItems) ? cfItems : []}
                onAdd={addClientFundingItem(clientFunding)}
                onRemove={removeClientFundingItem(clientFunding)}
              />
            </Panel>
          </div>
        )
      })}

      {hasAccess(Permissions.PARTICIPANT.PP_PACE.CREATE) && isValidDates ? (
        <FundingPeriodFormHeader onAdd={addClientFunding} onRemove={removeClientFunding(clientFundings.length - 1)} />
      ) : null}
    </div>
  )
}


function getPlanPeriodItemColumns (
  categories, categoryItems, form, hasAccess, isEdit, loadingDropdown, onAdd, onRemove
) {
  const { getFieldDecorator, getFieldError, getFieldValue, setFieldsValue } = form || {}
  const categoryLookup = new Map(categories.map((category) => [category.category_number, category]))

  const changeCategory = (idx) => (value) => {
    const { category_id: categoryId } = categoryLookup.get(value) || {}
    setFieldsValue({
      [getPlanPeriodItemFieldName(idx, ListItemField.CategoryId)]: categoryId,
      [getPlanPeriodItemFieldName(idx, ListItemField.ItemId)]: undefined,
      [getPlanPeriodItemFieldName(idx, ListItemField.ItemNumber)]: undefined
    })
  }

  const validateAvailableBalance = (allocatedAmount, isDiffAllocated, forecastSpent) => (rule, value, callback) => {
    const error = validateAmount(ListItemValidateLabel.AvailableAmount, value)
    const hasValue = !validator.isNullOrUndefined(value) && !validator.isEmptyString(value, true)
    const _allocatedAmount = toBigNumber(allocatedAmount)
    const _forecastSpent = toBigNumber(forecastSpent)
    const availableBalance = toBigNumber(value)
    const spent = _allocatedAmount.minus(availableBalance)

    if (error) {
      callback(error)
    } else if (hasValue && isDiffAllocated === true && availableBalance.isGreaterThan(_allocatedAmount)) {
      callback(new Error('Balance must be less than or equal to allocated amount'))
    } else if (
      hasValue && ((isDiffAllocated === true && spent.isLessThan(_forecastSpent)) || availableBalance.isLessThan(_forecastSpent))
    ) {
      callback(new Error(
        `${ListItemValidateLabel.Amount} must be greater than or equal to forecasted spent (${_forecastSpent.toFormat(2)})`
      ))
    } else {
      callback()
    }
  }

  const validateAllocatedAmount = (idx, totalSpent) => (rule, value, callback) => {
    // const amount = getFieldValue(`items[${idx}].available_amount`)
    // const formattedAmount = formatter.toBigNumber(amount)
    // if (formattedAmount.isLessThan(totalSpent)) {
    //   callback(new Error('Available amount must be greater than total spent amount'))
    // }
    // if (formatter.toBigNumber(value).minus(formattedAmount).isLessThan(0)) {
    //   callback(new Error('Allocated amount must be greater than or equals to balance'))
    // } else {
    //   validateAmount('Allocated amount')(rule, value, callback)
    // }

    validateAmount(ListItemValidateLabel.AllocatedAmount)(rule, value, callback)
  }

  const validateCarryOverAmount = () => (rule, value, callback) => {
    const error = validateAmount(ListItemValidateLabel.CarryOverAmount, value)

    if (error) {
      callback(error)
    } else {
      callback()
    }
  }

  // const handleChange = (idx, type) => (e) => {
  //   const value = e.target.value
  //   if (type === 'availableBalance') {
  //     setFieldsValue({ [`items[${idx}].available_amount`]: value })
  //   } else if (type === 'allocatedAmount') {
  //     setFieldsValue({ [`items[${idx}].allocated_amount`]: value })
  //   }
  //   validateFields([
  //     `items[${idx}].available_amount`,
  //     `items[${idx}].allocated_amount`
  //   ])
  // }

  // const handleCategoryChange = (cfIdx, idx) => (value) => {
  //   setFieldsValue({
  //     [`items[${idx}].item_number`]: undefined,
  //   })
  // }

  return Object.freeze([
    {
      title: (
        <div className='button-box'>
          <div className='label'>Support Category</div>

          <Tooltip mouseLeaveDelay={0} title="Add Support Category">
            <Icon className='btn-icon' type="plus-circle" onClick={typeof onAdd === 'function' ? onAdd : null} />
          </Tooltip>
        </div>
      ),
      width: 6,
      render: (record, idx) => {
        const { cpi_id: cpiId, cpi_category_number: categoryNumber } = record
        const disabled = isEdit && !validator.isNullOrUndefined(categoryNumber)
        const categoryLookup = new Map(categories.map((category) => [category.category_number, category]))
        const category = (idx === 0 ? categoryLookup.get('14') : undefined) || {}
        const fieldName = getPlanPeriodItemFieldName(idx, ListItemField.CategoryNumber)
        const error = getFieldError(fieldName)
        const initialValue = disabled ? categoryNumber : (idx === 0 ? '14' : undefined)
        getFieldDecorator(getPlanPeriodItemFieldName(idx, ListItemField.CategoryId), { initialValue: cpiId || category.category_id })
        return renderColumnSupportCategory({
          categories, disabled, error, fieldName, getFieldDecorator, initialValue, loadingDropdown, showExtra: isEdit,
          onChange: changeCategory(idx)
        })
      }
    },
    {
      title: 'Support Item',
      width: 5,
      render: ({ cpi_item_number: itemNumber }, idx) => {
        const fieldName = getPlanPeriodItemFieldName(idx, ListItemField.ItemNumber)
        const error = getFieldError(fieldName)
        // const categoryNumber = getFieldValue(getPlanPeriodItemFieldName(idx, ListItemField.CategoryNumber))
        // const seenItemNumbers = new Set()
        // const category = categoryItem.filter(category => Number(category.category_number) === 14)
        // const disabled = isEdit && items[idx].cpi_item_number !== undefined
        const disabled = isEdit && !validator.isNullOrUndefined(itemNumber)

        // const validateDuplicateValue = () => {
        //   return (rule, value, callback) => {
        //     const itemsInSameCategory = getFieldValue('items') || []
        //     const itemNumbersInSameCategory = itemsInSameCategory
        //       .filter(item => item.category_number === categoryNumber)
        //       .map(item => item.item_number)
        //     const duplicateCount = itemNumbersInSameCategory.filter(itemNumber => itemNumber === value && itemNumber !== undefined && itemNumber !== null).length
        //     const emptyCount = itemNumbersInSameCategory.filter(itemNumber => itemNumber === undefined || itemNumber === null).length
        //     if (emptyCount > 1) {
        //       callback(new Error('Either one needs to have value'))
        //     } else if (duplicateCount > 1) {
        //       callback(new Error('This support item number has already been selected'))
        //     } else {
        //       callback()
        //     }
        //   }
        // }

        // return (
        //   <div className={clsx(error ? 'has-error' : '')}>
        //     {getFieldDecorator(`items[${idx}].item_number`, {
        //       initialValue: isEdit ? itemNumber : undefined,
        //       rules: [
        //         // { validator: validateDuplicateValue() }
        //       ]
        //     })(
        //       <Select
        //         allowClear
        //         disabled={loadingDropdown || disabled}
        //         dropdownMatchSelectWidth={false}
        //         filterOption={searchDropdown}
        //         loading={loadingDropdown}
        //         placeholder="Select Support Item"
        //         showSearch
        //         style={{ width: '250px', color: disabled ? 'black' : 'inherit' }}
        //       >
        //         {/* {!isEdit && idx === 0 ?
        //           category
        //             .filter(({ category_number, item_number: ItemNumber }) => {
        //               if (Number(category_number) === 14 && !seenItemNumbers.has(ItemNumber)) {
        //                 seenItemNumbers.add(ItemNumber)
        //                 return true
        //               }
        //               return false
        //             })
        //             .map(({ item_number: ItemNumber, item_name: ItemName }) => (
        //               <Option key={ItemNumber} value={ItemNumber}>
        //                 {ItemName} ({ItemNumber})
        //               </Option>
        //             ))
        //           :
        //           categoryItem
        //             .filter(({ category_number, item_number: ItemNumber }) => {
        //               if (category_number === categoryNumber && !seenItemNumbers.has(ItemNumber)) {
        //                 seenItemNumbers.add(ItemNumber)
        //                 return true
        //               }
        //               return false
        //             })
        //             .map(({ item_number: ItemNumber, item_name: ItemName }) => (
        //               <Option key={ItemNumber} value={ItemNumber}>
        //                 {ItemName} ({ItemNumber})
        //               </Option>
        //             ))
        //         } */}
        //       </Select>
        //     )}

        //     {error ? <div className="ant-form-explain">{error}</div> : null}

        //     {isEdit ? <div className="ant-form-extra">&nbsp;</div> : null}
        //   </div>
        // )
      }
    },
    {
      title: 'Available Balance',
      width: 3,
      render: (record, idx) => {
        const { cpi_available_amount: availableAmount, forecast_spent: forecastSpent } = record
        const _availableAmount = formatter.toBigNumber(availableAmount)
        const fieldName = getPlanPeriodItemFieldName(idx, ListItemField.AvailableAmount)
        const error = getFieldError(fieldName)
        const allocatedAmount = getFieldValue(getPlanPeriodItemFieldName(idx, ListItemField.AllocatedAmount))
        const isDiffAllocated = getFieldValue(getPlanPeriodItemFieldName(idx, ListItemField.IsDiffAllocated))
        return renderColumnAvailableBalance({
          availableAmount: _availableAmount, getFieldDecorator, error, fieldName, showExtra: isEdit, // onChange: handleChange,
          onValidate: validateAvailableBalance(allocatedAmount, isDiffAllocated, forecastSpent)
        })
      }
    },
    {
      title: 'Allocated Amount',
      width: 4,
      render: (record, idx) => {
        const { cpi_allocated_amount: allocatedAmount, cpi_is_diff_allocated: isDiffAllocated } = record
        const _allocatedAmount = formatter.toBigNumber(allocatedAmount)
        const fieldNameAA = getPlanPeriodItemFieldName(idx, ListItemField.AllocatedAmount)
        const fieldNameIDA = getPlanPeriodItemFieldName(idx, ListItemField.IsDiffAllocated)
        const error = getFieldError(fieldNameAA)
        const fvIsDiffAllocated = getFieldValue(fieldNameIDA)

        if (fvIsDiffAllocated !== true) {
          getFieldDecorator(fieldNameAA, { initialValue: !_allocatedAmount.isNaN() ? _allocatedAmount.toFormat(2) : undefined })
        }

        return renderColumnAllocatedAmount({
          allocatedAmount: _allocatedAmount, error, fieldNameAA, fieldNameIDA, fvIsDiffAllocated, getFieldDecorator, isDiffAllocated,
          // showExtra: isEdit, onChange, onValidate
          showExtra: isEdit
        })
      }
    },
    {
      title: 'Carry Over Amount',
      width: 3,
      render: (record, idx) => {
        const { cpi_carry_over_amount: carryOverAmount } = record
        const _carryOverAmount = formatter.toBigNumber(carryOverAmount)
        const fieldName = getPlanPeriodItemFieldName(idx, ListItemField.CarryOverAmount)
        const error = getFieldError(fieldName)
        return renderColumnCarryOverAmount({
          carryOverAmount: _carryOverAmount, error, fieldName, getFieldDecorator, showExtra: isEdit,
          onValidate: validateCarryOverAmount()
        })
      }
    },
    {
      title: (
        <>
          <div>Spent</div>
          {isEdit ? <div>(Forecast)</div> : null}
        </>
      ),
      width: 2,
      render: (record, idx) => {
        const { forecast_spent: forecastSpent } = record
        const isDiffAllocated = getFieldValue(getPlanPeriodItemFieldName(idx, ListItemField.IsDiffAllocated))
        const _forecastSpent = toBigNumber(forecastSpent)
        const _allocatedAmount = toBigNumber(getFieldValue(getPlanPeriodItemFieldName(idx, ListItemField.AllocatedAmount)))
        const _availableAmount = toBigNumber(getFieldValue(getPlanPeriodItemFieldName(idx, ListItemField.AvailableAmount)))
        const _spentAmount = isDiffAllocated === true ? _allocatedAmount.minus(_availableAmount) : BigNumber(0)
        getFieldDecorator(getPlanPeriodItemFieldName(idx, ListItemField.SpentAmount), { initialValue: _spentAmount.toFixed(2) })
        return renderColumnSpent({ forecastSpent: _forecastSpent, showExtra: isEdit, spentAmount: _spentAmount })
      }
    },
    {
      title: 'Action',
      width: 1,
      render: (record, idx) => {
        const { id: planId, cpi_id: id, cpi_amount: amount, cpi_is_pm_fee: isPmFee } = record
        const categoryNumber = getFieldValue(getPlanPeriodItemFieldName(idx, ListItemField.isPmFee))
        getFieldDecorator(getPlanPeriodItemFieldName(idx, ListItemField.Id), { initialValue: id })
        getFieldDecorator(getPlanPeriodItemFieldName(idx, ListItemField.PlanId), { initialValue: planId })
        getFieldDecorator(getPlanPeriodItemFieldName(idx, ListItemField.Amount), { initialValue: toBigNumber(amount).toFixed(2) })
        getFieldDecorator(getPlanPeriodItemFieldName(idx, ListItemField.IsPmFee), {
          initialValue: !validator.isNullOrUndefined(categoryNumber) ? categoryNumber === '14' : !!isPmFee
        })
        return renderColumnAction({ hasAccess, showExtra: isEdit, onRemove: onRemove(idx) })
      }
    }
  ])
}

function PlanPeriodItemList ({ categories, categoryItems, form, hasAccess, isEdit, listItems, loadingDropdown, onAdd, onRemove }) {
  const rows = Array.isArray(listItems)
    ? listItems.sort((a, b) => {
      const { cpi_category_sorting: aCategorySorting, cpi_item_sorting: aItemSorting, cpi_is_pm_fee: aIsPmFee } = a
      const { cpi_category_sorting: bCategorySorting, cpi_item_sorting: bItemSorting, cpi_is_pm_fee: bIsPmFee } = b
      return sortListItem({ aIsPmFee, aCategorySorting, aItemSorting }, { bIsPmFee, bCategorySorting, bItemSorting })
    })
    : []
  return (
    <Skeleton active loading={loadingDropdown}>
      <List
        cols={getPlanPeriodItemColumns(
          categories, categoryItems, form, hasAccess, isEdit, loadingDropdown, onAdd, onRemove
        )}
        rows={rows}
      />
    </Skeleton>
  )
}

function PlanPeriodItemForm ({ categories, categoryItems, clientPlanItems, hasAccess, isEdit, loadingDropdown, props }) {
  const { form } = props || {}
  const defaultListItems = isEdit ? clientPlanItems : [{ items: [{ category_number: '14' }] }]
  const [listItems, setListItems] = useState(defaultListItems)

  const addListItem = useCallback(() => {
    setListItems((listItems) => listItems.concat([{}]))
  }, [])

  const removeListItem = useCallback((idx) => () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this support category?',
      content: 'Press Ok to continue, Cancel to return',
      onOk: () => {
        setListItems(
          (listItems) => idx > -1 ? listItems.filter((_, _idx) => _idx !== idx) : listItems
        )
      }
    })
  }, [])

  return (
    <div className='cf-list'>
      <PlanPeriodItemList
        categories={categories} categoryItems={categoryItems} form={form} hasAccess={hasAccess} isEdit={isEdit} listItems={listItems}
        loadingDropdown={loadingDropdown} props={props} onAdd={addListItem} onRemove={removeListItem}
      />
    </div>
  )
}

function PlanPeriodForm ({ categories, categoryItems, clientId, hasAccess, init, isEdit, item, loadingDropdown, props }) {
  const {
    start_date: iStartDate, end_date: iEndDate, role_start_date: iRoleStartDate, role_end_date: iRoleEndDate, iActive,
    client_plan_items: clientPlanItems
  } = item
  const { form, match } = props || {}
  const { params } = match || {}
  const { getFieldDecorator, getFieldError, getFieldValue, validateFields } = form || {}
  const { id, clientRefId } = params || {}
  const endDateErrors = getFieldError('end_date')
  const startDateErrors = getFieldError('start_date')
  const endDate = getFieldValue('end_date')
  const startDate = getFieldValue('start_date')
  const areDatesEntered = startDate && endDate
  const areDatesValid = !startDateErrors && !endDateErrors
  const [extraEndDate, setExtraEndDate] = useState()
  const [extraStartDate, setExtraStartDate] = useState()
  const [helpEndDate, setHelpEndDate] = useState()
  const [helpStartDate, setHelpStartDate] = useState()
  const [revalidateFieldName, setRevalidateFieldName] = useState()
  const [validateStatusEndDate, setValidateStatusEndDate] = useState()
  const [validateStatusStartDate, setValidateStatusStartDate] = useState()
  getFieldDecorator('client_id', { initialValue: clientId })

  const revalidateField = useCallback((callback, fieldName) => {
    validateFields([fieldName], { force: true }).catch(() => { }).finally(() => {
      callback()
    })
  }, [validateFields])

  const validateEndDate = useCallback(async (rule, value, callback) => {
    const fvStartDate = getFieldValue('start_date')

    if (validator.isDate(fvStartDate) && validator.isDate(value)) {
      const startDate = moment(fvStartDate).startOf('day')
      const endDate = moment(value).endOf('day')
      const rfn = revalidateFieldName

      if (validator.isNullOrUndefined(revalidateFieldName)) {
        setRevalidateFieldName('end_date')
      }

      try {
        setExtraEndDate()
        const { error, extra, help, validateStatus } = await periodValidator.validateEndDate({
          startDate,
          endDate,
          item,
          onCheckDate: () => clientPlanPaceService.checkDates(startDate.toISOString(), endDate.toISOString(), id, clientId),
          onGetLink: (id) => `/participants/${clientRefId}/plan-period-pace/${id}`
        })
        callback(error)
        setExtraEndDate(extra)
        setHelpEndDate(help)
        setValidateStatusEndDate(validateStatus)

        if (rfn !== 'start_date') {
          revalidateField(callback, 'start_date')
        } else if (!error && getFieldError('start_date')) {
          setRevalidateFieldName('end_date')
        }
      } catch (e) {
        console.log(e)
      }
    } else {
      setExtraEndDate()
      setHelpEndDate()
    }
  }, [getFieldError, getFieldValue, revalidateField, clientId, clientRefId, id, item, revalidateFieldName])

  const validateStartDate = useCallback(async (rule, value, callback) => {
    const fvEndDate = getFieldValue('end_date')

    if (validator.isDate(value) && validator.isDate(fvEndDate)) {
      const startDate = moment(value).startOf('day')
      const endDate = moment(fvEndDate).endOf('day')
      const rfn = revalidateFieldName

      if (validator.isNullOrUndefined(revalidateFieldName)) {
        setRevalidateFieldName('start_date')
      }

      try {
        setExtraStartDate()
        const { error, extra, help, validateStatus } = await periodValidator.validateStartDate({
          startDate,
          endDate,
          item,
          onCheckDate: () => clientPlanPaceService.checkDates(startDate.toISOString(), endDate.toISOString(), id, clientId),
          onGetLink: (id) => `/participants/${clientRefId}/plan-period-pace/${id}`
        })
        callback(error)
        setExtraStartDate(extra)
        setHelpStartDate(help)
        setValidateStatusStartDate(validateStatus)

        if (rfn !== 'end_date') {
          revalidateField(callback, 'end_date')
        } else if (!error && getFieldError('end_date')) {
          setRevalidateFieldName('start_date')
        }
      } catch (e) {
        console.log(e)
      }
    } else {
      setExtraStartDate()
      setHelpStartDate()
    }
  }, [getFieldError, getFieldValue, revalidateField, clientId, clientRefId, id, item, revalidateFieldName])

  return (
    <>
      <Panel>
        <Skeleton active loading={init}>
          <Row gutter={16}>
            <Col lg={6}>
              <FormItem
                {...formItemDateLayout} label='Start Date' extra={extraStartDate} help={helpStartDate}
                validateStatus={validateStatusStartDate}
              >
                {getFieldDecorator('start_date', {
                  initialValue: iStartDate ? moment(iStartDate) : null,
                  rules: [
                    { required: true, message: 'Please select start date' },
                    { validator: validateStartDate }
                  ]
                })(
                  <DatePicker format={dateFormat} placeholder='Select Start Date' />
                )}
              </FormItem>
            </Col>

            <Col lg={6}>
              <FormItem
                {...formItemDateLayout} label='End Date' extra={extraEndDate} help={helpEndDate} validateStatus={validateStatusEndDate}
              >
                {getFieldDecorator('end_date', {
                  initialValue: iEndDate ? moment(iEndDate) : null,
                  rules: [
                    { required: true, message: 'Please select end date' },
                    { validator: validateEndDate }
                  ]
                })(
                  <DatePicker disabled={false} format={dateFormat} placeholder='Select End Date' />
                )}
              </FormItem>
            </Col>

            <Col lg={6}>
              <FormItem {...formItemLayout} label='Active'>
                {getFieldDecorator('active', {
                  initialValue: typeof iActive === 'boolean' ? iActive : true,
                  valuePropName: 'checked'
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                    disabled={false}
                  />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col lg={6}>
              <FormItem {...formItemDateLayout} label='Role Start Date'>
                {getFieldDecorator('role_start_date', {
                  initialValue: iRoleStartDate ? moment(iRoleStartDate) : undefined,
                  rules: [
                    { required: true, message: 'Please select role start date' },
                  ]
                })(
                  <DatePicker disabled={false} format={dateFormat} placeholder='Select Role Start Date' />
                )}
              </FormItem>
            </Col>

            <Col lg={6}>
              <FormItem {...formItemDateLayout} label='Role End Date'>
                {getFieldDecorator('role_end_date', {
                  initialValue: iRoleEndDate ? moment(iRoleEndDate) : undefined,
                })(
                  <DatePicker disabled={false} format={dateFormat} placeholder='Select Role End Date' />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Row gutter={16}>
              <Col lg={6}>
                <FormItem {...formItemDateLayout} label="Setup Cost Invoice?">
                  {getFieldDecorator('setup_cost_invoiced', {
                    initialValue: isEdit ? (item?.setup_cost_invoiced !== undefined ? item?.setup_cost_invoiced : true) : true,
                    valuePropName: 'checked',
                  })(
                    <Switch checkedChildren="Yes, Create" unCheckedChildren="No, Don't Create" />
                  )}
                </FormItem>
              </Col>

              {getFieldValue('setup_cost_invoiced') !== true ? (
                <Col lg={6}>
                  <FormItem label="No Setup Cost Invoice Reason">
                    {getFieldDecorator('setup_cost_reason', {
                      initialValue: item?.setup_cost_reason || undefined,
                      rules: [
                        { required: true, message: 'Please enter no setup cost invoice reason' },
                        { whitespace: true, message: 'Please enter no setup cost invoice reason' },
                      ]
                    })(
                      <TextArea rows={3} />
                    )}
                  </FormItem>
                </Col>
              ) : null}

              <Col lg={6}>
                <FormItem {...formItemDateLayout} label="Monthly Fee Invoice?">
                  {getFieldDecorator('monthly_fee_invoiced', {
                    initialValue: isEdit ? (item?.monthly_fee_invoiced !== undefined ? item?.monthly_fee_invoiced : true) : true,
                    valuePropName: 'checked',
                  })(
                    <Switch checkedChildren="Yes, Create" unCheckedChildren="No, Don't Create" />
                  )}
                </FormItem>
              </Col>

              {getFieldValue('monthly_fee_invoiced') !== true ? (
                <Col lg={6}>
                  <FormItem label="No Monthly Fee Invoice Reason">
                    {getFieldDecorator(`monthly_fee_reason`, {
                      initialValue: item?.monthly_fee_reason || undefined,
                      rules: [
                        { required: true, message: 'Please enter no monthly fee invoice reason' },
                        { whitespace: true, message: 'Please enter no monthly fee invoice reason' },
                      ]
                    })(
                      <TextArea rows={3} />
                    )}
                  </FormItem>
                </Col>
              ) : null}
            </Row>

            <PlanPeriodItemForm
              categories={categories} categoryItems={categoryItems} clientPlanItems={clientPlanItems} hasAccess={hasAccess}
              isEdit={isEdit} loadingDropdown={loadingDropdown} props={props}
            />
          </Row>
        </Skeleton>
      </Panel>

      {areDatesEntered && areDatesValid ? (
        <FundingPeriodForm
          categories={categories} categoryItems={categoryItems} hasAccess={hasAccess} isEdit={isEdit} isValidDates={areDatesValid}
          item={item} props={props}
        />
      ) : null}
    </>
  )
}

function PlanPeriodPage ({ props }) {
  const { form, history, match } = props || {}
  const { validateFieldsAndScroll } = form
  const { params } = match || {}
  const { id } = params || {}
  const { clientRefId } = params
  const [categories, setCategories] = useState([])
  const [categoryItems, setCategoryItems] = useState([])
  const [clientId, setClientId] = useState()
  const [init, setInit] = useState(id !== 'add')
  const [item, setItem] = useState({})
  const [loading, setLoading] = useState(false)
  const [loadingDropdown, setLoadingDropdown] = useState(true)
  const [saving, setSaving] = useState(false)
  const [showEdit, setShowEdit] = useState(true)

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  const isEdit = useCallback(() => {
    return id !== 'add'
  }, [id])

  const handleSave = useCallback(() => {
    if (loading || saving) {
      return
    }

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        if (saving) {
          return
        }

        // if (values.client_fundings === undefined) {
        //   notify.error('Missing Funding Period', 'Funding Period is required. Please add a funding period details.')
        //   return
        // }

        // if (client && client.id) {
        //   data.client_id = client.id
        // }

        try {
          setSaving(true)
          let response

          if (isEdit()) {
            response = await clientPlanPaceService.save(id, values)
          } else {
            response = await clientPlanPaceService.add(values)
          }

          if (validator.isObject(response) && validator(response.id)) {
            notify.success('Saved successfully', 'Plan Period saved successfully.')

            if (!isEdit()) {
              history.replace(`/participants/${clientRefId}/plan-period-pace/${response.id}`)
            }
          } else {
            notify.error('Unable to save successfully', 'Unable to save plan period successfully. Please try again later.')
          }

          setSaving(false)
        } catch (e) {
          setSaving(false)
          notify.error('Unable to save successfully', 'Unable to save plan period successfully. Please try again later.')
        }
      }
    })
  }, [isEdit, validateFieldsAndScroll, clientRefId, history, id, loading, saving])

  const toggleEdit = useCallback(() => {
    setShowEdit(!showEdit)
  }, [showEdit])

  useEffect(() => {
    let mounted = true

    if (!hasAccess(Permissions.PARTICIPANT.PP_PACE.LIST)) {
      setInit(false)
      setLoadingDropdown(false)
      return
    }

    setLoading(true)
    Promise.all([
      isEdit() && validator.isId(id) ? clientPlanPaceService.get(id) : undefined,
      pmRateSetCategoryPaceService.getAllUniques(),
      // pmRateSetPaceService.getAllConfigs(),
      undefined,
      isEdit() ? undefined : clientService.getRef(clientRefId)
    ])
      .then(([response, categoryResponse, categoryItemResponse, clientResponse]) => {
        if (mounted) {
          if (validator.isObject(response) && validator.isId(response.id)) {
            setItem(response)
            setClientId(response.client_id)
          }

          if (Array.isArray(categoryResponse)) {
            setCategories(categoryResponse)
          }

          if (validator.isArray(categoryItemResponse)) {
            setCategoryItems(categoryItemResponse)
          }

          if (validator.isObject(clientResponse) && validator.isId(clientResponse.id)) {
            setClientId(clientResponse.id)
          }
        }
      })
      .finally(() => {
        if (mounted) {
          setInit(false)
          setLoading(false)
          setLoadingDropdown(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [hasAccess, isEdit, clientRefId, id])

  return (
    <Page.Body key={id}>
      <Page.Content nomenu>
        <Page.Header title={`(PACE) ${isEdit() ? 'Edit' : 'Add'} Plan Period`}>
          {hasAccess(Permissions.PARTICIPANT.PP_PACE.UPDATE) && isEdit() && showEdit && !loading
            ? <Button onClick={toggleEdit}>Edit</Button>
            : null}

          {hasAccess(Permissions.PARTICIPANT.PP_PACE.DELETE) && isEdit() && !showEdit
            ? <Button feedback={saving} onClick={() => { }}>Delete</Button>
            : null
          }

          {(
            (hasAccess(Permissions.PARTICIPANT.PP_PACE.CREATE) && !isEdit()) ||
            (hasAccess(Permissions.PARTICIPANT.PP_PACE.UPDATE) && isEdit() && !showEdit)
          )
            ? <Button feedback={saving} onClick={handleSave}>Save</Button>
            : null
          }

          <Button feedback={saving} onClick={history.goBack}>Back</Button>
        </Page.Header>

        <div className='ppp-form'>
          <PlanPeriodForm
            categories={categories} categoryItems={categoryItems} clientId={clientId} hasAccess={hasAccess} init={init}
            isEdit={isEdit()} item={item} loadingDropdown={loadingDropdown} props={props}
          />
        </div>
      </Page.Content>
    </Page.Body>
  )
}

export class PlanPeriodPagePace extends Component {
  render () {
    return <PlanPeriodPage props={this.props} />
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { ...state.PlanPeriodPace }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(PlanPeriodPagePace))
