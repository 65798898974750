import { request, queryString } from '../util'

// const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/pm-rate-set-category-items-pace'

export default {
  getAll (filter) {
    return request(`${url}${queryString.stringify({ filter: JSON.stringify(filter) })}`)
  }
}
