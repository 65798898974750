import { REFRESH_RATES, REFRESH_RATE_SETS, REFRESH_RATE_SET_CATEGORY_TEMPLATES } from '../actions/rateSetPace'

const INITIAL_STATE = {
  rate: {
    refresh: false
  },
  rateSet: {
    refresh: false
  },
  rateSetCategoryTemplate: {
    refresh: false
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REFRESH_RATES:
      return { ...handleRefreshRates(state, action) }
    case REFRESH_RATE_SETS:
      return { ...handleRefreshRateSets(state, action) }
    case REFRESH_RATE_SET_CATEGORY_TEMPLATES:
      return { ...handleRefreshRateSetCategoryTemplates(state, action) }
    default:
      return state
  }
}

function handleRefreshRates (state, action) {
  const { rate } = state
  const { refresh } = action
  return { ...state, rate: { ...rate, refresh } }
}

function handleRefreshRateSets (state, action) {
  const { rateSet } = state
  const { refresh } = action
  return { ...state, rateSet: { ...rateSet, refresh } }
}

function handleRefreshRateSetCategoryTemplates (state, action) {
  const { rateSetCategoryTemplate } = state
  const { refresh } = action
  return { ...state, rateSetCategoryTemplate: { ...rateSetCategoryTemplate, refresh } }
}
