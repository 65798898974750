import React, { Component, useCallback, useEffect, useState } from 'react'

import { Permissions, ReportRequestType } from '../../../constants'
import ReportMenu from '../../../constants/menu/report'
import { reportSchedulerPaceService } from '../../../services'
import { auth, formatter, validator } from '../../../util'

// UI
import Col from 'antd/lib/col'
import Icon from 'antd/lib/icon'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'

import { ControlLabel, List, Page, Pager } from '../../../components'

import './styles.css'

const ReportType = Object.freeze([
  ReportRequestType.SB_COMPARE,
  ReportRequestType.INV_RCV_IMPORT
])
const defaultPageSize = 20

function getReportGenerateRequestColumns (hasAccess) {
  const handleDownload = (fileUrl) => () => {
    if (window) {
      const newTab = window.open(fileUrl, '_blank', 'noopener,noreferrer')

      if (newTab) {
        newTab.opener = null
      }
    }
  }

  return Object.freeze([
    {
      title: 'Requested At',
      width: 4,
      render: ({ created_at: createdAt }) => formatter.toStandardLongDate(createdAt)
    },
    {
      title: 'Type',
      width: 5,
      render: ({ type }) => {
        const rt = ReportType.find(({ code }) => code === type)
        return rt ? rt.name : type
      }
    },
    {
      title: 'Status',
      width: 1,
      render: ({ status }) => formatter.capitalize(status)
    },
    {
      title: 'Completed At',
      width: 4,
      render: ({ completed_at: completedAt }) => formatter.toStandardLongDate(completedAt)
    },
    {
      title: 'Logs',
      width: 6,
      render: ({ log_message: logMessage }) => <div style={{ fontSize: '9px' }}>{logMessage}</div>
    },
    {
      title: 'Requested By',
      width: 3,
      render: ({ created_by_name: createdByName }) => createdByName
    },
    {
      title: '',
      width: 1,
      render: ({ output_file_url: outputFileUrl }) => {
        return (
          <div className='action-buttons'>
            {(
              hasAccess(Permissions.REPORT.SCHEDULER_PACE.READ) && !validator.isNullOrUndefined(outputFileUrl) &&
              !validator.isEmptyString(outputFileUrl)
            ) ? (
              <Icon type='download' onClick={handleDownload(outputFileUrl)} />
            ) : null}
          </div>
        )
      }
    }
  ])
}

function ReportGenerateRequest (props) {
  const [filter, setFilter] = useState({})
  const [init, setInit] = useState(true)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)

  const listByPage = useCallback(({ page, filter: newFilter }) => {
    const _filter = { ...filter, ...newFilter }
    const _page = typeof page === 'number' && page > 0 ? page : 1
    setFilter(_filter)
    setLoading(true)
    setPage(_page)
    reportSchedulerPaceService.listSchedulerReportByPage(_page, defaultPageSize, _filter)
      .then((response) => {
        if (validator.isObject(response)) {
          const { list, total } = response

          if (Array.isArray(list)) {
            setList(list)
          }

          if (typeof total === 'number') {
            setTotal(total)
          }
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [filter])

  const changePage = useCallback((page) => {
    listByPage({ page })
  }, [listByPage])

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  useEffect(() => {
    if (!hasAccess(Permissions.REPORT.SCHEDULER_PACE.LIST)) {
      return
    }

    let mounted = true
    setLoading(true)
    reportSchedulerPaceService
      .listSchedulerReportByPage(1, defaultPageSize)
      .then((response) => {
        if (mounted && validator.isObject(response)) {
          const { list, total } = response

          if (Array.isArray(list)) {
            setList(list)
          }

          if (typeof total === 'number') {
            setTotal(total)
          }
        }
      })
      .finally(() => {
        if (mounted) {
          setInit(false)
          setLoading(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [hasAccess])

  return (
    <Page.Body>
      <Page.Left>
        <Page.Menu title='Home' menu={ReportMenu} />
      </Page.Left>

      <Page.Content full>
        <Skeleton active loading={init}>
          <div className='rsp-rate-set1'>
            <Page.Header title='(PACE) Report Generate Request'>
              <Row>
                <Col lg={6}>
                  <ControlLabel>&nbsp;</ControlLabel>

                  <div className='btn-box'>

                  </div>
                </Col>
              </Row>
            </Page.Header>

            <Spin spinning={loading}>
              <List cols={getReportGenerateRequestColumns(hasAccess)} rows={list} />

              <Pager
                size={defaultPageSize}
                total={total}
                totalText={`Total ${total} requests`}
                current={page}
                onChange={changePage}
                style={{ marginTop: '15px' }}
              />
            </Spin>
          </div>
        </Skeleton>
      </Page.Content>
    </Page.Body >
  )
}

export class ReportGenerateRequestPace extends Component {
  render () {
    return <ReportGenerateRequest {...this.props} />
  }
}

export default ReportGenerateRequestPace
