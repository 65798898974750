import React, { Component, useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import clsx from 'clsx'
import debounce from 'lodash.debounce'
import moment from 'moment-timezone'

import { Permissions } from '../../../../constants'
import { setFileAttachmentCount } from '../../../../states/actions'
import { filePaceService, settingFileService } from '../../../../services'
import { auth, formatter, validator } from '../../../../util'

// UI
import Col from 'antd/lib/col'
import Icon from 'antd/lib/icon'
import Popconfirm from 'antd/lib/popconfirm'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'

import { Button, ControlLabel, List, Pager, SearchInput } from '../../../../components'
import notify from '../../../../components/Notification'
import FileAddModal from './AddModal'

import './styles.css'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const { Button: RadioButton, Group: RadioGroup } = Radio
const { Option } = Select

const defaultPageSize = 20

function getInvoiceFileColumns (hasAccess, editFile, removeFile) {
  const handleDelete = (item) => () => {
    if (typeof removeFile === 'function') {
      removeFile(item)
    }
  }

  const handleDownload = (url) => () => {
    if (!validator.isNullOrUndefined(url) && !validator.isEmptyString(url) && window) {
      const newTab = window.open(url, '_blank', 'noopener,noreferrer')

      if (newTab) {
        newTab.opener = null
      }
    }
  }

  const handleEdit = (item) => () => {
    if (typeof editFile === 'function') {
      editFile(item)
    }
  }

  return Object.freeze([
    {
      title: 'File Category',
      width: 7,
      render: ({ main_cat_name: catName, sub_cat_name: subCatName }) => (
        <div>
          <div className='title'>{subCatName}</div>
          <div className='sub-title'>{catName}</div>
        </div>
      )
    },
    {
      title: 'File Label',
      width: 7,
      render: ({ label, name }) => {
        return (
          <div>
            <div className='title'>{label}</div>
            <div className='sub-title'>{name ? `[${formatter.toStandardFileName(name)}]` : ''}</div>
          </div>
        )
      }
    },
    {
      title: 'Issuance Date',
      width: 3,
      render: ({ issuance_date: issuanceDate }) => formatter.toShortDate(issuanceDate)
    },
    {
      title: 'Is Mail Attachment?',
      width: 3,
      render: ({ sub_cat_is_attach_mail_comm: isAttachMailComm }) => (
        <div className={clsx('icon', isAttachMailComm ? 'active' : '')}>
          <Icon type='check-circle' theme='filled' />
        </div>
      )
    },
    {
      title: 'Mandatory',
      width: 1,
      render: ({ sub_cat_is_mandatory: isMandatory }) => (
        <div className={clsx('icon', isMandatory ? 'active' : '')}>
          <Icon type='check-circle' theme='filled' />
        </div>
      )
    },
    {
      title: 'Enabled',
      width: 1,
      render: ({ active }) => (
        <div className={clsx('icon', active ? 'active' : '')}>
          <Icon type='check-circle' theme='filled' />
        </div>
      )
    },
    {
      title: 'Action',
      width: 2,
      render: (item) => (
        <div className='button-box'>
          {hasAccess(Permissions.INVOICE.FILES_PACE.UPDATE)
            ? (
              <Tooltip mouseLeaveDelay={0} title='Edit'>
                <Icon type='form' onClick={handleEdit(item)} />
              </Tooltip>
            )
            : null}

          {hasAccess(Permissions.INVOICE.FILES_PACE.READ)
            ? (
              <Tooltip mouseLeaveDelay={0} title={`Download ${formatter.toStandardFileName(item.name)}`}>
                <Icon type='file-text' onClick={handleDownload(item.url)} />
              </Tooltip>
            )
            : null}

          {hasAccess(Permissions.INVOICE.FILES_PACE.DELETE)
            ? (
              <Tooltip mouseLeaveDelay={0} title='Delete'>
                <Popconfirm
                  title={`Are you sure you want to delete ${item.label}?`}
                  onConfirm={handleDelete(item)}
                  okText='Yes'
                  cancelText='No'
                >
                  <Icon type='delete' />
                </Popconfirm>
              </Tooltip>
            )
            : null}
        </div>
      )
    }
  ])
}

function InvoiceFile (props) {
  const { selectedInvoice, setFileAttachmentCount } = props || {}
  const invoice = validator.isObject(selectedInvoice) && validator.isId(selectedInvoice.id) ? selectedInvoice : {}
  const [fileCategories, setFileCategories] = useState([])
  const [filter, setFilter] = useState({})
  const [init, setInit] = useState(true)
  const [isEdit, setIsEdit] = useState(false)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [saving, setSaving] = useState(false)
  const [searchText, setSearchText] = useState()
  const [searching, setSearching] = useState(false)
  const [selectedFile, setSelectedFile] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [subCategories, setSubCategories] = useState([])
  const [total, setTotal] = useState(0)

  const listByPage = useCallback(({ page, filter: newFilter }) => {
    const _filter = { ...filter, ...newFilter }
    const _page = typeof page === 'number' && page > 0 ? page : 1
    setLoading(true)
    setFilter(_filter)
    setPage(_page)
    filePaceService.listInvoiceFiles(_page, defaultPageSize, _filter)
      .then((response) => {
        if (validator.isObject(response)) {
          const { list, total } = response

          if (Array.isArray(list)) {
            setList(list)
          }

          if (typeof total === 'number') {
            setTotal(total)
          }
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [filter])

  const updateFileAttachmentCount = useCallback(async () => {
    if (validator.isId(invoice.id)) {
      try {
        const fileResponse = await filePaceService
          .listInvoiceFiles(1, 1, { genre_id: invoice.id, sub_cat_is_attach_mail_comm: true })

        if (validator.isObject(fileResponse)) {
          // Set initial file email attachment count
          const { total } = fileResponse
          setFileAttachmentCount(total)
        }
      } catch {

      }
    }
  }, [setFileAttachmentCount, invoice])

  const changeEnabled = useCallback(({ target }) => {
    const { value } = target
    const active = validator.isEmptyString(value) ? undefined : value === 'true'
    listByPage({ page: 1, filter: { active } })
  }, [listByPage])

  // const changeMandatory = useCallback(({ target }) => {
  //   const { value } = target
  //   const isMandatory = validator.isEmptyString(value) ? undefined : value === 'true'
  //   listByPage({ page: 1, filter: { sub_cat_is_mandatory: isMandatory } })
  // }, [listByPage])

  const changePage = useCallback((page) => {
    listByPage({ page })
  }, [listByPage])

  const changeSubCategory = useCallback((subCatId) => {
    listByPage({ page: 1, filter: { sub_cat_id: subCatId } })
  }, [listByPage])

  const closeModal = useCallback(() => {
    setSelectedFile({})
    setShowModal(false)
  }, [])

  const filterSubCategory = useCallback((input, option) => {
    const { props } = option || {}
    const { children } = props || []
    const str = Array.isArray(children) ? children.join(' ') : `${children}`
    return str.toLowerCase().indexOf(input.toLowerCase()) > -1
  }, [])

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  const openModal = useCallback(() => {
    const { invoice_date: invoiceDate, invoice_number: invoiceNumber } = selectedInvoice || {}
    setIsEdit(false)
    setSelectedFile({ issuance_date: invoiceDate, label: invoiceNumber })
    setShowModal(true)
  }, [selectedInvoice])

  const addFile = useCallback((values, onSuccess) => {
    const { label } = values
    setSaving(true)
    filePaceService.add(values)
      .then(async (response) => {
        if (validator.isObject(response) && validator.isId(response.id)) {
          await updateFileAttachmentCount()
          notify.success('Added successfully', `File "${label}" added successfully.`)
          listByPage({ page: 1 })

          if (typeof onSuccess === 'function') {
            onSuccess()
          }
        } else {
          notify.error('Unable to add successfully', `Unable to add file "${label}" successfully. Please try again later.`)
        }
      })
      .catch(() => {
        notify.error('Unable to add successfully', `Unable to add file "${label}" successfully. Please try again later.`)
      })
      .finally(() => {
        setSaving(false)
      })
  }, [listByPage, updateFileAttachmentCount])

  const editFile = useCallback((file) => {
    setIsEdit(true)
    setSelectedFile(file)
    setShowModal(true)
  }, [])

  const updateFile = useCallback((id, values, onSuccess) => {
    const { label } = values
    setSaving(true)
    filePaceService.save(id, values)
      .then((response) => {
        if (validator.isObject(response) && validator.isId(response.id)) {
          notify.success('Updated successfully', `File "${label}" updated successfully.`)
          listByPage({ page: 1 })

          if (typeof onSuccess === 'function') {
            onSuccess()
          }
        } else {
          notify.error('Unable to update successfully', `Unable to update file "${label}" successfully. Please try again later.`)
        }
      })
      .catch(() => {
        notify.error('Unable to update successfully', `Unable to update file "${label}" successfully. Please try again later.`)
      })
      .finally(() => {
        setSaving(false)
      })
  }, [listByPage])

  const removeFile = useCallback((file) => {
    const { id, label } = file
    setSaving(true)
    filePaceService.remove(id)
      .then(async (response) => {
        if (validator.isObject(response) && validator.isId(response.id)) {
          await updateFileAttachmentCount()
          notify.success('Deleted successfully', `File "${label}" deleted successfully.`)
          listByPage({ page: 1 })
        } else {
          notify.error('Unable to delete successfully', `Unable to delete file "${label}" successfully. Please try again later.`)
        }
      })
      .catch(() => {
        notify.error('Unable to delete successfully', `Unable to delete file "${label}" successfully. Please try again later.`)
      })
      .finally(() => {
        setSaving(false)
      })
  }, [updateFileAttachmentCount, listByPage])

  const searchFile = useCallback(() => {
    const onSearchFile = async (value) => {
      setLoading(true)
      setPage(1)
      setSearchText(value)
      setSearching(true)

      try {
        const response = await filePaceService.listInvoiceFiles(1, defaultPageSize, filter, {}, value)

        if (validator.isObject(response)) {
          const { list, total } = response
          setList(list)
          setTotal(total)
        }
      } finally {
        setLoading(false)
        setSearching(false)
      }
    }
    return debounce(onSearchFile, 500)
  }, [filter])

  useEffect(() => {
    let mounted = true

    if (!hasAccess(Permissions.INVOICE.INFO_PACE.READ)) {
      setInit(false)
      return
    }

    setLoading(true)

    if (!validator.isId(invoice.id)) {
      return
    }

    const filter = { genre_id: invoice.id }
    setFilter(filter)
    Promise
      .all([
        filePaceService.listInvoiceFiles(1, defaultPageSize, filter),
        settingFileService.getAllFileCatDropdowns({ module_type: 'invoice' }),
        settingFileService.getAllSubCatDropdowns({ module_type: 'invoice' })
      ])
      .then(([response, fileCatResponse, subCatResponse]) => {
        if (mounted) {
          if (validator.isObject(response)) {
            const { list, total } = response

            if (Array.isArray(list)) {
              setList(list)
            }

            if (typeof total === 'number') {
              setTotal(total)
            }
          }

          if (Array.isArray(fileCatResponse)) {
            setFileCategories(fileCatResponse)
          }

          if (Array.isArray(subCatResponse)) {
            setSubCategories(subCatResponse)
          }
        }
      })
      .finally(() => {
        if (mounted) {
          setInit(false)
          setLoading(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [hasAccess, invoice])

  return (
    <Skeleton active loading={init}>
      <div className='pf-file-list'>
        <Row className='ip-margin-bottom' gutter={16}>
          <Col lg={6}>
            <ControlLabel>File Label, File Name</ControlLabel>

            <SearchInput
              isSearching={searching}
              placeholder='Search'
              value={searchText}
              onChange={searchFile()}
            />
          </Col>

          <Col lg={6}>
            <ControlLabel>Sub Category</ControlLabel>

            <Select
              allowClear
              disabled={loading || saving}
              dropdownMatchSelectWidth={false}
              filterOption={filterSubCategory}
              optionFilterProp='children'
              placeholder='Select Sub Category'
              showSearch
              onChange={changeSubCategory}
              style={{ width: '100%' }}
            >
              {subCategories.map(({ id, name, active }) => (
                <Option key={id} disabled={!active} value={id}>{name}</Option>
              ))}
            </Select>
          </Col>

          <Col lg={9}>
            <div className='radio-box'>
              {/* <div className='radio-group'>
                <ControlLabel>Mandatory</ControlLabel>

                <RadioGroup defaultValue='' onChange={changeMandatory}>
                  <RadioButton value=''>All</RadioButton>
                  <RadioButton value='true'>Mandatory</RadioButton>
                  <RadioButton value='false'>Non-Mandatory</RadioButton>
                </RadioGroup>
              </div> */}

              <div className='radio-group'>
                <ControlLabel>Enabled</ControlLabel>

                <RadioGroup defaultValue='' onChange={changeEnabled}>
                  <RadioButton value=''>All</RadioButton>
                  <RadioButton value='true'>Active</RadioButton>
                  <RadioButton value='false'>Inactive</RadioButton>
                </RadioGroup>
              </div>
            </div>
          </Col>

          <Col lg={3}>
            <ControlLabel>&nbsp;</ControlLabel>

            <div className='ip-text-right'>
              <Button onClick={openModal}>Add File</Button>
            </div>
          </Col>
        </Row>

        <Spin spinning={loading || saving}>
          <List cols={getInvoiceFileColumns(hasAccess, editFile, removeFile)} rows={list} />

          <Pager
            size={defaultPageSize}
            total={total}
            current={page}
            onChange={changePage}
            totalText={`Total ${total} logs`}
            style={{ marginTop: '15px' }}
          />
        </Spin>

        <FileAddModal
          file={selectedFile}
          fileCategories={fileCategories}
          isEdit={isEdit}
          saving={saving}
          subCategories={subCategories}
          visible={showModal}
          onAddFile={addFile}
          onClose={closeModal}
          onUpdateFile={updateFile}
        />
      </div>
    </Skeleton>
  )
}

export class PaceInvoiceFile extends Component {
  render () {
    return <InvoiceFile {...this.props} />
  }
}

const mapDispatchToProps = {
  setFileAttachmentCount
}

const mapStateToProps = (state) => {
  return { ...state.InvoicePace }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaceInvoiceFile)
