import React, { Component, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import moment from 'moment-timezone'

import { Permissions } from '../../../../constants'
import { pmRatePaceService, pmRateSetPaceService } from '../../../../services'
import { refreshRates } from '../../../../states/actions'
import { auth, formatter, validator } from '../../../../util'

// UI
import Col from 'antd/lib/col'
import Icon from 'antd/lib/icon'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'

import { Button, ControlLabel, List, Page, Pager } from '../../../../components'

import './styles.css'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const { Option } = Select

const defaultPageSize = 20

function getRateSetColumns (hasAccess) {
  return Object.freeze([
    {
      key: 'rate_name',
      title: 'Rate Name',
      width: 6,
      render: ({ rate_name: rateName, active, is_current: isCurrent }) => (
        <span className={clsx(isCurrent ? 'current' : '', active ? '' : 'inactive')}>{rateName}</span>
      )
    },
    {
      key: 'name',
      title: 'Name',
      width: 10,
      render: ({ name, active, is_current: isCurrent }) => (
        <span className={clsx(isCurrent ? 'current' : '', active ? '' : 'inactive')}>{name}</span>
      )
    },
    {
      key: 'start_date',
      title: 'Period',
      width: 5,
      render: ({ start_date: startDate, end_date: endDate, active, is_current: isCurrent }) => (
        <span className={clsx(isCurrent ? 'current' : '', active ? '' : 'inactive')}>
          {formatter.toShortDate(startDate)} - {formatter.toShortDate(endDate)}
        </span>
      )
    },
    {
      key: 'active',
      title: 'Enable',
      width: 2,
      render: ({ active }) => (
        <Icon className={clsx('rsp-icon', active ? 'active' : 'inactive')} type='check-circle' theme='filled' />
      )
    },
    {
      key: 'action',
      title: 'Action',
      width: 1,
      render: ({ id, active }) => {
        return (
          <div className='action-buttons'>
            {hasAccess(Permissions.SETTING.RATESETS_PACE.READ)
              ? (
                <Tooltip mouseLeaveDelay={0} title='Edit'>
                  <Link to={`/settings/rate-set-pace/rate-sets/${id}`}>
                    <div className={clsx('rsp-btn-icon', active ? 'active' : 'inactive')}>
                      <Icon type='form' />
                    </div>
                  </Link>
                </Tooltip>
              )
              : null}
          </div>
        )
      }
    }
  ])
}

function RateSet (props) {
  const { rate, refreshRates } = props || {}
  const { refresh } = rate
  const [filter, setFilter] = useState({})
  const [init, setInit] = useState(true)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingDropdown, setLoadingDropdown] = useState(false)
  const [page, setPage] = useState(1)
  const [rates, setRates] = useState([])
  const [total, setTotal] = useState(0)

  const listByPage = useCallback(({ page, filter: newFilter }) => {
    const _filter = { ...filter, ...newFilter }
    const _page = typeof page === 'number' && page > 0 ? page : 1
    setFilter(_filter)
    setLoading(true)
    setPage(_page)
    pmRateSetPaceService.listByPage(_page, defaultPageSize, _filter)
      .then((response) => {
        if (validator.isObject(response)) {
          const { list, total } = response

          if (Array.isArray(list)) {
            setList(list)
          }

          if (typeof total === 'number') {
            setTotal(total)
          }
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [filter])

  const changePage = useCallback((page) => {
    listByPage({ page })
  }, [listByPage])

  const changeRate = useCallback((rateId) => {
    listByPage({ page: 1, filter: { rate_id: rateId } })
  }, [listByPage])

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  useEffect(() => {
    if (!hasAccess(Permissions.SETTING.RATESETS_PACE.LIST)) {
      setInit(false)
      return
    }

    let mounted = true
    setLoading(true)
    setLoadingDropdown(true)
    Promise.all([
      pmRateSetPaceService.listByPage(1, defaultPageSize),
      pmRatePaceService.getAll()
    ])
      .then(([response, rateResponse]) => {
        if (mounted) {
          if (validator.isObject(response)) {
            const { list, total } = response

            if (Array.isArray(list)) {
              setList(list)
            }

            if (typeof total === 'number') {
              setTotal(total)
            }
          }

          if (Array.isArray(rateResponse)) {
            setRates(rateResponse)
          }
        }
      })
      .finally(() => {
        if (mounted) {
          setInit(false)
          setLoading(false)
          setLoadingDropdown(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [hasAccess])

  useEffect(() => {
    if (!hasAccess(Permissions.SETTING.RATESETS_PACE.LIST)) {
      return
    }

    let mounted = true

    if (refresh === true) {
      setLoadingDropdown(true)
      pmRatePaceService.getAll()
        .then((rateResponse) => {
          if (mounted && Array.isArray(rateResponse)) {
            setRates(rateResponse)
          }
        })
        .finally(() => {
          if (mounted) {
            refreshRates(false)
            setLoadingDropdown(false)
          }
        })
    }

    return () => {
      mounted = false
    }
  }, [hasAccess, refreshRates, refresh])

  return (
    <Skeleton active loading={init}>
      <div className='rsp-rate-set'>
        <Page.Header>
          <Row>
            <Col lg={6}>
              <ControlLabel>Rate</ControlLabel>

              <Select
                allowClear
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                loading={loadingDropdown}
                placeholder='Select Rate'
                showSearch
                onChange={changeRate}
                style={{ width: '100%' }}
              >
                {rates.map(({ id, name, active }) => (
                  <Option key={id} className={active ? '' : 'rsp-text-grey'} value={id}>{name}</Option>
                ))}
              </Select>
            </Col>

            <Col lg={18}>
              <ControlLabel>&nbsp;</ControlLabel>

              <div className='btn-box'>
                {hasAccess(Permissions.SETTING.RATESETS_PACE.CREATE)
                  ? <Link to='/settings/rate-set-pace/rate-sets/add'><Button>Add Rate Set</Button></Link>
                  : null}
              </div>
            </Col>
          </Row>
        </Page.Header>

        <Spin spinning={loading}>
          <List cols={getRateSetColumns(hasAccess)} rows={list} />

          <Pager
            size={defaultPageSize}
            total={total}
            totalText={`Total ${total} rate sets`}
            current={page}
            onChange={changePage}
            style={{ marginTop: '15px' }}
          />
        </Spin>
      </div>
    </Skeleton>
  )
}

export class PaceRateSet extends Component {
  render () {
    return <RateSet {...this.props} />
  }
}

const mapDispatchToProps = {
  refreshRates
}

const mapStateToProps = (state) => {
  return { ...state.RateSetPace }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaceRateSet)
