import { request, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/participants/fundings-pace'

export default {
  getAll (filter) {
    return request(`${url}/list${queryString.stringify({ filter: JSON.stringify(filter) })}`)
  },
  getAllDropdowns (filter, text) {
    return request(`${url}/dropdown${queryString.stringify({ filter: JSON.stringify(filter || {}), text })}`)
  }
}
