import React, { Component, useState, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { apiHostname } from '../../../config'
import { fetchStatements } from '../../../states/actions/statement'
import { auth, common, formatter, queryString, validator } from '../../../util'
import { authService, statementPaceService } from '../../../services'
import { InvoiceListType, Permissions, StatementMenu } from '../../../constants'

// UI
import { ControlLabel, List, Page, Pager, SearchInput } from '../../../components'
import notify from '../../../components/Notification'

import './styles.css'

import Col from 'antd/lib/col'
import Icon from 'antd/lib/icon'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'

const pageSize = 10
const Option = Select.Option

const getTitle = (type) => {
  let title

  if (type === InvoiceListType.INV_LIST_PM) {
    title = '(PACE) PM SB Statements'
  } else if (type === InvoiceListType.INV_LIST_STD) {
    title = '(PACE) Std SB Statements'
  } else {
    title = '(PACE) Statement Periods'
  }

  return title
}

const StatementColumns = (filterType) => {
  const columns = [
    {
      title: 'Period',
      width: 3,
      render: ({ period_fullname }) => <div>{period_fullname}</div>
    },
    {
      title: 'Total Participants',
      width: 3,
      render: ({ client_count }) => <div>{client_count}</div>
    },
    {
      title: 'Total Invoiced Amount',
      width: 3,
      render: ({ subtotal, pm_subtotal, std_subtotal }) => {
        return (
          filterType === InvoiceListType.INV_LIST_PM
            ? <div>{formatter.toPrice(pm_subtotal)}</div>
            : filterType === InvoiceListType.INV_LIST_STD
              ? <div>{formatter.toPrice(std_subtotal)}</div>
              : <div>{formatter.toPrice(subtotal)}</div>
        )
      }
    },
    {
      title: 'Total Paid Amount',
      width: 3,
      render: ({ cum_rcv_total, cum_rcv_pm_total, cum_rcv_std_total }) => {
        return (
          filterType === InvoiceListType.INV_LIST_PM
            ? <div>{formatter.toPrice(cum_rcv_pm_total)}</div>
            : filterType === InvoiceListType.INV_LIST_STD
              ? <div>{formatter.toPrice(cum_rcv_std_total)}</div>
              : <div>{formatter.toPrice(cum_rcv_total)}</div>
        )
      }
    }
  ]

  if (filterType === InvoiceListType.INV_LIST_PM || filterType === InvoiceListType.INV_LIST_STD) {
    if (filterType === InvoiceListType.INV_LIST_PM) {
      columns.push({
        title: 'Total Credit Balance',
        width: 3,
        render: ({ cum_credit_remaining_total }) => <div>{formatter.toPrice(cum_credit_remaining_total)}</div>
      })
    }

    columns.push({
      title: '',
      width: 1,
      render: ({ id, period_month }) => filterType === InvoiceListType.INV_LIST_PM || filterType === InvoiceListType.INV_LIST_STD
        ? <div className='action-buttons'>
          <Link to={`/statement-pace/period/${filterType}/${period_month}`} rel='noopener noreferrer' target='_blank' ><div><Icon type='eye' /></div></Link>
        </div>
        : null
    })
  }

  return columns
}

function StatementList (props) {
  const [loading, setLoading] = useState(false)
  const [loadingList, setLoadingList] = useState(false)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [init, setInit] = useState(true)
  const [list, setList] = useState([])
  const { type = InvoiceListType.INV_LIST_PM } = common.getPath(props.match)
  const title = getTitle(type)

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  const listByPage = useCallback(({ page }) => {
    const _page = typeof page === 'number' && page > 0 ? page : 1
    setLoading(true)
    setPage(_page)
    statementPaceService.listStatementPeriodByPage(_page, pageSize, {}, type)
      .then((response) => {
        if (validator.isObject(response)) {
          const { list, total } = response
          setList(list)
          setTotal(total)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [type])

  const changePage = useCallback((page) => {
    listByPage({ page })
  }, [listByPage])

  useEffect(() => {
    let mounted = true

    if (!hasAccess(Permissions.CREDIT.INFO_PACE.READ)) {
      setInit(false)
      return
    }

    setLoading(true)
    statementPaceService.listStatementPeriodByPage(1, pageSize, {}, type)
      .then((response) => {
        if (mounted && validator.isObject(response)) {
          const { list, total } = response

          if (Array.isArray(list)) {
            setList(list)
          }

          setTotal(total)
        }
      })
      .finally(() => {
        if (mounted) {
          setInit(false)
          setLoading(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [hasAccess, type])

  return (
    <Page.Body>
      <Page.Left>
        <Page.Menu title='Home' menu={StatementMenu} />
      </Page.Left>
      <Page.Content full>
        <Page.Header title={title} />

        <Skeleton loading={loading} active>
          <Spin spinning={loadingList}>
            <List cols={StatementColumns(type)} rows={list} />
          </Spin>
        </Skeleton>

        <Pager
          size={pageSize}
          total={total}
          totalText={`Total ${total} periods`}
          current={page}
          onChange={changePage}
          style={{ marginTop: '15px' }}
        />
      </Page.Content>
    </Page.Body>
  )
}

export class StatementListPeriod extends Component {
  render () {
    return <StatementList {...this.props} />
  }
}

export default StatementListPeriod