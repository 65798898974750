import React, { Component, useState, useEffect, useCallback } from 'react'
import Moment from 'moment-timezone'
import { Permissions } from '../../../constants'
import { logPaceService } from '../../../services'
import { auth, validator } from '../../../util'

// UI
import { List, Pager, Panel } from '../../../components'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'

import './styles.css'

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const defaultPageSize = 20

function getLogHistoryColumns () {
  return Object.freeze([
    {
      title: 'Created At',
      width: 5,
      render: ({ created_at }) => Moment(created_at).format('DD/MM/YYYY hh:mm A')
    },
    {
      title: 'Updated By',
      width: 4,
      render: ({ member }) => <div className='updated-name' style={{ textTransform: 'capitalize' }}>{member}</div>
    },
    {
      title: 'Action',
      width: 3,
      render: ({ action }) => <div className='updated-name' style={{ textTransform: 'uppercase' }}>{action}</div>
    },
    {
      title: 'Changes',
      width: 12,
      render: ({ changes }) => <div dangerouslySetInnerHTML={{ __html: changes && changes.replace(/\n/g, '<br />') }}></div>
    }
  ])
}

function ActivityLog (props) {
  const { creditId, saving } = props || {}
  const [init, setInit] = useState(true)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)

  const listByPage = useCallback(({ page }) => {
    const _page = typeof page === 'number' && page > 0 ? page : 1
    setLoading(true)
    setPage(_page)
    logPaceService.listCreditLogs(_page, defaultPageSize, { genre_id: creditId })
      .then((response) => {
        if (validator.isObject(response)) {
          const { list, total } = response
          setList(list)
          setTotal(total)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [creditId])

  const changePage = useCallback((page) => {
    listByPage({ page })
  }, [listByPage])

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  useEffect(() => {
    let mounted = true

    if (!hasAccess(Permissions.CREDIT.INFO_PACE.READ)) {
      setInit(false)
      return
    }

    setLoading(true)
    logPaceService
      .listCreditLogs(1, defaultPageSize, { genre_id: creditId })
      .then((response) => {
        if (mounted && validator.isObject(response)) {
          const { list, total } = response

          if (Array.isArray(list)) {
            setList(list)
          }

          setTotal(total)
        }
      })
      .finally(() => {
        if (mounted) {
          setInit(false)
          setLoading(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [hasAccess, creditId, saving])

  return (
    <Panel>
      <Skeleton active loading={init}>
        <Spin spinning={loading}>
          <List cols={getLogHistoryColumns()} rows={list} />

          <Pager
            size={defaultPageSize}
            total={total}
            current={page}
            onChange={changePage}
            totalText={`Total ${total} logs`}
            style={{ marginTop: '15px' }}
          />
        </Spin>
      </Skeleton>
    </Panel>
  )
}

export class LogActivity extends Component {
  render () {
    return <ActivityLog {...this.props} />
  }
}

export default LogActivity
