import React, { Component, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import clsx from 'clsx'
import moment from 'moment-timezone'

import { CommTypeName, Permissions } from '../../../../constants'
import { commPaceService } from '../../../../services'
import { auth, formatter, validator } from '../../../../util'

// UI
import Icon from 'antd/lib/icon'
import Popconfirm from 'antd/lib/popconfirm'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'

import { List, Pager } from '../../../../components'
import notify from '../../../../components/Notification'
import CommAddModal from './AddModal'

import './styles.css'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const dateFormat = 'DD/MM/YYYY hh:mm:ss A'
const defaultPageSize = 20

function getInvoiceCommColumns (hasAccess, { onCloseComm, onOpenAddModal, onRescheduleComm, onScheduleComm }) {
  const canUpdateInvoice = hasAccess(Permissions.INVOICE.INFO_PACE.UPDATE)
  const closeComm = (id, comm) => () => {
    if (typeof onCloseComm === 'function') {
      onCloseComm(id, comm)
    }
  }

  const openAddModal = (comm) => () => {
    if (typeof onOpenAddModal === 'function') {
      onOpenAddModal(comm)
    }
  }

  const rescheduleComm = (id, comm) => () => {
    if (typeof onRescheduleComm === 'function') {
      onRescheduleComm(id, comm)
    }
  }

  const scheduleComm = (id, comm) => () => {
    if (typeof onScheduleComm === 'function') {
      onScheduleComm(id, comm)
    }
  }

  // Having both "Stop" and "Cancel" comm functions would confuse user
  // const stopComm = (id, comm) => () => {
  //   if (typeof onStopComm === 'function') {
  //     onStopComm(id, comm)
  //   }
  // }

  return Object.freeze([
    {
      title: 'Created At',
      width: 4,
      render: ({ disabled, created_at: createdAt }) => (
        <div className={clsx(disabled ? 'text-disabled' : '')}>{formatter.toDate(createdAt, dateFormat)}</div>
      )
    },
    {
      title: 'Event',
      width: 3,
      render: ({ comm_type: commType, disabled }) => {
        const ctn = CommTypeName.find(({ type }) => type === commType) || {}
        return <div className={clsx(disabled ? 'text-disabled' : '')}>{ctn.name || 'Comm'}</div>
      }
    },
    {
      title: 'Subject',
      width: 4,
      render: ({ title, disabled }) => <div className={clsx(disabled ? 'text-disabled' : '')}>{title}</div>
    },
    {
      title: 'Content',
      width: 8,
      render: ({ content, disabled }) => (
        <div className={clsx(disabled ? 'text-disabled' : '')} dangerouslySetInnerHTML={{ __html: content }}></div>
      )
    },
    {
      title: 'Scheduled?',
      width: 1,
      render: ({ id, disabled, cr_ids: crIds, is_scheduleds: isScheduleds, scheduled_ats: scheduledAts }) => {
        return Array.isArray(isScheduleds) ? isScheduleds.map((isScheduled, idx) => (
          <div key={idx}>
            {isScheduled ? (
              <Tooltip mouseLeaveDelay={0} title={`Scheduled to send at ${formatter.toDate(scheduledAts[idx], dateFormat)}`}>
                <Icon className={clsx('pc-btn-icon', idx === 0 ? 'success' : '')} type='check-circle' theme='filled' />
              </Tooltip>
            ) : (
              !canUpdateInvoice || disabled || idx > 0
                ? (
                  <Tooltip mouseLeaveDelay={0} title='Not Scheduled'>
                    <Icon className='pc-btn-icon' type='minus-circle' theme='filled' />
                  </Tooltip>
                ) : (
                  <Tooltip mouseLeaveDelay={0} title='Schedule'>
                    <Popconfirm
                      cancelText='No'
                      okText='Yes'
                      title='Proceed to schedule this communication for sending?'
                      onConfirm={scheduleComm(id, { cr_id: crIds[idx] })}
                    >
                      <Icon className='pc-btn-icon action warning' type='clock-circle' />
                    </Popconfirm>
                  </Tooltip>
                )
            )}
          </div>
        )) : null
      }
    },
    {
      title: 'Sent?',
      width: 1,
      render: ({
        disabled, is_scheduleds: isScheduleds, scheduled_ats: scheduledAts, is_processed_or_sents: isProcessedOrSents,
        processed_or_sent_ats: processedOrSentAts, faileds, failed_ats: failedAts, failed_reasons: failedReasons,
        is_manually_closeds: isManuallyCloseds
      }) => Array.isArray(isProcessedOrSents) ? isProcessedOrSents.map((isProcessedOrSent, idx) => (
        <div key={idx}>
          {isScheduleds[idx] !== true ? (
            <Tooltip mouseLeaveDelay={0} title='Not Sent'>
              <Icon className='pc-btn-icon' type='close-circle' theme='filled' />
            </Tooltip>
          ) : isProcessedOrSent ? (
            faileds[idx] ? (
              <Tooltip
                mouseLeaveDelay={0}
                title={`Failed to send at ${formatter.toDate(failedAts[idx], dateFormat)}, Reason: ${failedReasons[idx] || ''}`}
              >
                <Icon className={clsx('pc-btn-icon', idx === 0 ? 'error' : '')} type='close-circle' theme='filled' />
              </Tooltip>
            ) : (
              <Tooltip
                mouseLeaveDelay={0}
                title={`Sent at ${formatter.toDate(processedOrSentAts[idx], dateFormat)}${isManuallyCloseds[idx] ? ` (Cancelled)` : ''}`}
              >
                <Icon
                  className={clsx('pc-btn-icon', idx === 0 && !isManuallyCloseds[idx] ? 'success' : '')}
                  type={isManuallyCloseds[idx] ? 'close-circle' : 'check-circle'}
                  theme='filled'
                />
              </Tooltip>
            )
          ) : (
            <Tooltip mouseLeaveDelay={0} title={`Scheduled to send at ${formatter.toDate(scheduledAts[idx], dateFormat)}`}>
              <Icon className={clsx('pc-btn-icon', idx === 0 && !disabled ? 'warning' : '')} type='clock-circle' theme='filled' />
            </Tooltip>
          )}
        </div>
      )) : null
    },
    {
      title: 'Action',
      width: 1.5,
      render: ({
        id, title, content, closed, disabled, updated_at: updatedAt, cr_ids: crIds, is_scheduleds: isScheduleds,
        is_processed_or_sents: isProcessedOrSents, is_manually_closeds: isManuallyCloseds
      }, idx) => {
        return disabled ? (
          <Tooltip
            mouseLeaveDelay={0} placement='left' title={`Communication is disabled at ${formatter.toDate(updatedAt, dateFormat)}`}
          >
            <Icon className='pc-btn-icon' type='minus-circle' />
          </Tooltip>
        ) : (
          <div className='button-box'>
            {closed ? (
              <>
                <Tooltip
                  mouseLeaveDelay={0}
                  placement='left'
                  title={
                    `Communication is ${isManuallyCloseds[idx] ? 'cancelled and ' : ''}closed at ${formatter.toDate(updatedAt, dateFormat)}`
                  }
                >
                  <Icon className='pc-btn-icon' type='close-circle' />
                </Tooltip>

                {isProcessedOrSents[0] && isManuallyCloseds[0] ? (
                  <Tooltip mouseLeaveDelay={0} title='Reschedule'>
                    <Icon className='pc-btn-icon action' type='history' onClick={rescheduleComm(id, { cr_id: crIds[idx] })} />
                  </Tooltip>
                ) : null}
              </>
            ) : canUpdateInvoice ? (
              isScheduleds[idx] && isProcessedOrSents[idx] !== true && isManuallyCloseds[idx] !== true ? (
                <>
                  <Tooltip mouseLeaveDelay={0} title='Edit'>
                    <Icon className='pc-btn-icon action' type='form' onClick={openAddModal({ cr_id: crIds[idx], id, title, content })} />
                  </Tooltip>

                  {/* Having both "Stop" and "Cancel" comm functions would confuse user
                  <Tooltip mouseLeaveDelay={0} title='Pause'>
                    <Icon className='pc-btn-icon action warning' type='pause-circle' onClick={stopComm(id, { cr_id: crIds[idx] })} />
                  </Tooltip> */}

                  <Tooltip mouseLeaveDelay={0} title='Cancel & Close'>
                    <Popconfirm
                      cancelText='No'
                      okText='Yes'
                      title='Cancel and close this communication?'
                      onConfirm={closeComm(id, { cr_id: crIds[idx] })}
                    >
                      <Icon className='pc-btn-icon action error' type='stop' />
                    </Popconfirm>
                  </Tooltip>
                </>
              ) : null
            ) : null}
          </div>
        )
      }
    }
  ])
}

function InvoiceComm (props) {
  const { selectedInvoice } = props || {}
  const invoice = validator.isObject(selectedInvoice) && validator.isId(selectedInvoice.id) ? selectedInvoice : {}
  const [init, setInit] = useState(true)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [saving] = useState(false)
  const [selectedComm, setSelectedComm] = useState()
  const [showAddModal, setShowAddModal] = useState(false)
  const [total, setTotal] = useState(0)

  const listByPage = useCallback(({ page }) => {
    const _page = typeof page === 'number' && page > 0 ? page : 1
    setLoading(true)
    setPage(_page)
    commPaceService.listInvoiceComms(_page, defaultPageSize, { genre_id: invoice.id })
      .then((response) => {
        if (validator.isObject(response)) {
          const { list, total } = response

          if (Array.isArray(list)) {
            setList(list)
          }

          if (typeof total === 'number') {
            setTotal(total)
          }
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [invoice])

  const closeAddModal = useCallback(() => {
    setSelectedComm()
    setShowAddModal(false)
  }, [])

  const closeComm = useCallback((id, comm) => {
    if (loading) {
      return
    }

    setLoading(true)
    commPaceService.closeInvoiceComm(id, comm)
      .then((response) => {
        if (validator.isObject(response) && validator.isId(response.id)) {
          notify.success('Cancelled and closed successfully', 'Communication cancelled and closed successfully.')
          listByPage({ page: 1 })
        } else {
          notify.error('Unable to cancel and close successfully', 'Unable to cancel and close communication successfully. Please try again later.')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [listByPage, loading])

  const changePage = useCallback((page) => {
    listByPage({ page })
  }, [listByPage])

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  const openAddModal = useCallback((comm) => {
    setSelectedComm(comm)
    setShowAddModal(true)
  }, [])

  const rescheduleComm = useCallback((id, comm) => {
    if (loading) {
      return
    }

    setLoading(true)
    commPaceService.rescheduleInvoiceComm(id, comm)
      .then((response) => {
        if (validator.isObject(response) && validator.isId(response.id)) {
          notify.success('Rescheduled successfully', 'Communication resecheduled successfully.')
          listByPage({ page: 1 })
        } else {
          notify.error('Unable to reschedule successfully', 'Unable to reschedule communication successfully. Please try again later.')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [listByPage, loading])

  const saveComm = useCallback((id, comm, onSuccess) => {
    if (loading) {
      return
    }

    setLoading(true)
    commPaceService.saveInvoiceComm(id, comm)
      .then((response) => {
        if (validator.isObject(response) && validator.isId(response.id)) {
          notify.success('Saved successfully', 'Communication saved successfully.')
          listByPage({ page: 1 })

          if (typeof onSuccess === 'function') {
            onSuccess()
          }
        } else {
          notify.error('Unable to save successfully', 'Unable to save successfully. Please try again later.')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [listByPage, loading])

  const scheduleComm = useCallback((id, comm) => {
    if (loading) {
      return
    }

    setLoading(true)
    commPaceService.scheduleInvoiceComm(id, comm)
      .then((response) => {
        if (validator.isObject(response) && validator.isId(response.id)) {
          notify.success('Scheduled successfully', 'Communication secheduled successfully.')
          listByPage({ page: 1 })
        } else {
          notify.error('Unable to schedule successfully', 'Unable to schedule communication successfully. Please try again later.')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [listByPage, loading])

  // Having both "Stop" and "Cancel" comm functions would confuse user
  // const stopComm = useCallback((id, comm) => {
  //   if (loading) {
  //     return
  //   }

  //   setLoading(true)
  //   commPaceService.stopInvoiceComm(id, comm)
  //     .then((response) => {
  //       if (validator.isObject(response) && validator.isId(response.id)) {
  //         notify.success('Stopped successfully', 'Communication stopped successfully.')
  //         listByPage({ page: 1 })
  //       } else {
  //         notify.error('Unable to stop successfully', 'Unable to stop communication successfully. Please try again later.')
  //       }
  //     })
  //     .finally(() => {
  //       setLoading(false)
  //     })
  // }, [listByPage, loading])

  useEffect(() => {
    let mounted = true

    if (!hasAccess(Permissions.INVOICE.INFO_PACE.READ)) {
      setInit(false)
      return
    }

    setLoading(true)

    if (!validator.isId(invoice.id)) {
      return
    }

    commPaceService
      .listInvoiceComms(1, defaultPageSize, { genre_id: invoice.id })
      .then((response) => {
        if (mounted && validator.isObject(response)) {
          const { list, total } = response

          if (Array.isArray(list)) {
            setList(list)
          }

          if (typeof total === 'number') {
            setTotal(total)
          }
        }
      })
      .finally(() => {
        if (mounted) {
          setInit(false)
          setLoading(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [hasAccess, invoice])

  return (
    <Skeleton active loading={init}>
      <Spin spinning={loading || saving}>
        <List
          className='pc-comm-list'
          cols={getInvoiceCommColumns(hasAccess, {
            onCloseComm: closeComm, onOpenAddModal: openAddModal, onRescheduleComm: rescheduleComm, onScheduleComm: scheduleComm
          })}
          rows={list}
        />

        <Pager
          size={defaultPageSize}
          total={total}
          current={page}
          onChange={changePage}
          totalText={`Total ${total} logs`}
          style={{ marginTop: '15px' }}
        />
      </Spin>

      <CommAddModal
        comm={selectedComm}
        saving={saving}
        visible={showAddModal}
        onClose={closeAddModal}
        onUpdateComm={saveComm}
      />
    </Skeleton>
  )
}

export class PaceInvoiceComm extends Component {
  render () {
    return <InvoiceComm {...this.props} />
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { ...state.InvoicePace }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaceInvoiceComm)
