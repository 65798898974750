import React, { Component, useCallback } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { validator } from '../../util'

// UI
import Icon from 'antd/lib/icon'
import Spin from 'antd/lib/spin'
import Upload from 'antd/lib/upload'

import Button from '../Button'

import './styles.css'

function FileUpload (props) {
  const { className, errorMessage, fileUrl, loading, upload } = props || {}
  const hasErrorMessage = !validator.isNullOrUndefined(errorMessage) && !validator.isEmptyString(errorMessage)
  const hasFileUrl = !validator.isNullOrUndefined(fileUrl) && !validator.isEmptyString(fileUrl)
  const hasUpload = !validator.isNullOrUndefined(upload) && validator.isObject(upload)
  const disabled = hasUpload && typeof upload.disabled === 'boolean' ? upload.disabled : false
  const spinning = typeof loading === 'boolean' ? loading : false

  if (hasUpload) {
    upload.className = clsx(['wd-upload', upload.className])
    upload.disabled = spinning || disabled
  }

  const downloadFile = useCallback(() => {
    if (hasFileUrl && window) {
      const newTab = window.open(fileUrl, '_blank', 'noopener,noreferrer')

      if (newTab) {
        newTab.opener = null
      }
    }
  }, [hasFileUrl, fileUrl])

  return (
    <div className={clsx('wd-file-upload', className)}>
      <div className={clsx(['uploader', disabled ? 'disabled' : '', hasErrorMessage ? 'error' : ''])}>
        <Upload {...upload}>
          <Spin spinning={spinning}>
            <div className='trigger-box'>
              <div className='icon-box'>
                <div>
                  {hasFileUrl ? (
                    <Icon className='trigger-icon' type='file-done' />
                  ) : (
                    <Icon className='trigger-icon' type='file-add' />
                  )}
                </div>
              </div>

              <div className='button-box'>
                <Button ghost>Click to {hasFileUrl ? 'Change' : 'Upload'}</Button>

                {hasFileUrl ? <Button ghost onClick={downloadFile}>Click to download</Button> : null}
              </div>
            </div>
          </Spin>
        </Upload>
      </div>

      {hasErrorMessage ? <div className='error-message'>{errorMessage}</div> : null}
    </div >
  )
}

export class FileUpload2 extends Component {
  render () {
    return <FileUpload {...this.props} />
  }
}
FileUpload2.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  fileUrl: PropTypes.string,
  loading: PropTypes.bool,
  upload: PropTypes.object.isRequired
}

export default FileUpload2