import React, { Component, useCallback } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Permissions from '../../../constants/permissions'
import { auth } from '../../../util'

// UI
import { Button, Panel } from '../../../components'

import PlanPeriodList from './List'

import './styles.css'

function PlanPeriod ({ props }) {
  const { clientRefId } = props

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  return (
    <Panel
      title='Plan Period'
      subtitle={(
        hasAccess(Permissions.PARTICIPANT.PP_PACE.CREATE)
          ? <Link to={`/participants/${clientRefId}/plan-period-pace/add`}><Button type='alert'>Add</Button></Link>
          : null
      )}
    >
      <PlanPeriodList props={props} />
    </Panel>
  )
}

export class PlanPeriodPace extends Component {
  render () {
    return <PlanPeriod props={this.props} />
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { ...state.PlanPeriodPace }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanPeriodPace)
