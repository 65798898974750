import React, { Component } from 'react'
import { BrowserRouter as Router, Link, Route } from 'react-router-dom'
// UI
import InvAuthModule from './InvAuth'

import './styles.css'

class Process extends Component {
  render () {
    return (
      <Router>
        <Route component={InvAuthModule} path='/process/inv-auth' exact />
      </Router>
    )
  }
}

export default Process
