export default [
  // { name: 'Driver License', value: 'driver_license' },
  // { name: 'DWES', value: 'dwes' },
  // { name: 'First Aid', value: 'first_aid' },
  // { name: 'Flu Vaccination', value: 'flu_vaccination' },
  { name: 'Other', value: 'other' },
  // { name: 'Police Check', value: 'police_check' },
  // { name: 'Vehicle Registration', value: 'vehicle_registration' },
  // { name: 'Vehicle Insurance', value: 'vehicle_insurance' },
  // { name: 'WWCC', value: 'wwcc' }

]

export const FileTypes = {
  // POLICE_CHECK: 'police_check',
  // FIRST_AID: 'first_aid',
  // DWES: 'dwes',
  // WWCC: 'wwcc',
  // DRIVER_LICENSE: 'driver_license',
  // FLU_VACCINATION: 'flu_vaccination',
  // VEHICLE_REGISTRATION: 'vehicle_registration',
  // VEHICLE_INSURANCE: 'vehicle_insurance',
  OTHER: 'other'
}

export const ClientFileTypeList = [
  // { name: 'Care Plan', value: 'care_plan' },
  { name: 'Other', value: 'other' }
]

export const ClientFileTypes = {
  // CARE_PLAN: 'care_plan',
  OTHER: 'other'
}

export const FileUploadModule = Object.freeze({
  Invoice: 'invoice'
})