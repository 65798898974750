import { request, requestFile, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/participants'

export default {
  add (values) {
    return request(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  getAllDropdowns (filter, text) {
    return request(`${url}/dropdown${queryString.stringify({ filter: JSON.stringify(filter || {}), text })}`)
  },
  getLimit (id) {
    return request(`${url}/limit`)
  },
  get (id) {
    return request(`${url}/${id}`)
  },
  getRef (id) {
    return request(`${url}/ref/${id}`)
  },
  remove (id) {
    return request(`${url}/${id}`, {
      method: 'DELETE',
      headers
    })
  },
  save (id, values) {
    return request(`${url}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  listByPage (page, size, filter, sorting, text) {
    return request(`${url}/list/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listClientBudget (id) {
    return request(`${url}/list/budget/${id}`)
  },
  listAllClients (filter) {
    return request(`${url}/list/all${filter ? queryString.stringify({ filter: JSON.stringify(filter) }) : ''}`)
  },
  exportClient (values) {
    return requestFile(`${url}/export/all`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  }
}
