import React, { Component, useState, useCallback, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { debounce } from 'lodash'
import moment from 'moment-timezone'

import { InvoiceTypePace as InvoiceType, Permissions } from '../../../constants'
import {
  clientService, creditPaceService, invoicePaceService, pmRateSetCategoryPaceService, providerService, creditApplyHistoryPaceService
} from '../../../services'
import { auth, common, formatter, validator } from '../../../util'

// UI
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import Spin from 'antd/lib/spin'
import Skeleton from 'antd/lib/skeleton'
import Tabs from 'antd/lib/tabs'

import './styles.css'

import { Button, Page, Pager, Panel, List } from '../../../components'
import notify from '../../../components/Notification'
import ActivityLog from '../ActivityLog'

const { Item: FormItem } = Form
const { TextArea } = Input
const { confirm, warning } = Modal
const { Option } = Select
const { TabPane } = Tabs

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const defaultPageSize = 20
const formItemShortLayout = {
  labelCol: { sm: 6, md: 6, lg: 4 },
  wrapperCol: { sm: 10, md: 10, lg: 12 }
}

function ApplyHistoryPanel ({ props }) {
  const { match } = props || {}
  const { params } = match || {}
  const { id } = params || {}
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)

  const listByPage = useCallback(({ page }) => {
    const _page = typeof page === 'number' && page > 0 ? page : 1
    setLoading(true)
    setPage(_page)
    creditApplyHistoryPaceService.listByPage(_page, defaultPageSize, { credit_ref_id: id })
      .then((response) => {
        if (validator.isObject(response)) {
          const { list, total } = response
          setList(list)
          setTotal(total)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [id])

  const changePage = useCallback((page) => {
    listByPage({ page })
  }, [listByPage])

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  useEffect(() => {
    let mounted = true

    if (!hasAccess(Permissions.CREDIT.INFO_PACE.READ)) {
      return
    }

    setLoading(true)
    creditApplyHistoryPaceService.listByPage(1, defaultPageSize, { credit_ref_id: id })
      .then((response) => {
        if (mounted && validator.isObject(response)) {
          const { list, total } = response

          if (Array.isArray(list)) {
            setList(list)
          }

          setTotal(total)
        }
      })
      .finally(() => {
        if (mounted) {
          setLoading(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [hasAccess, id])

  return (
    <Panel title='Application History'>
      <Spin spinning={loading}>
        <List cols={getApplyHistoryColumns(hasAccess)} rows={list} />
        <Pager
          size={defaultPageSize}
          total={total}
          totalText={`Total ${total} Credit History`}
          current={page}
          onChange={changePage}
          style={{ marginTop: '15px' }}
        />
      </Spin>
    </Panel>
  )
}

function getApplyHistoryColumns (hasAccess) {
  return Object.freeze([
    {
      title: 'Applied At',
      width: 5,
      render: ({ apply_date: applyDate }) => formatter.toShortDate(applyDate)
    },
    {
      title: 'Applied Amount',
      width: 5,
      render: ({ amount }) => formatter.toPrice(amount)
    },
    {
      title: 'Credit Notes on Statement',
      width: 4,
      render: ({ comment }) => comment
    },
    {
      title: 'Invoice',
      width: 5,
      render: ({ invoice_ref_id: invoiceRefId, invoice_jid_number: jidNumber, invoice_number: invoiceNumber }) => (
        hasAccess(Permissions.INVOICE.INFO_PACE.READ)
          ? (
            <Link to={`/invoices/${invoiceRefId}/info`} rel='noopener noreferrer' target='_blank'>
              {jidNumber.toUpperCase()} ({invoiceNumber})
            </Link>
          )
          : <>{jidNumber.toUpperCase()} ({invoiceNumber})</>
      )
    },
    {
      title: 'Updated At',
      width: 5,
      render: ({ updated_at: updatedAt }) => formatter.toStandardDate(updatedAt)
    }
  ])
}

function CreditForm ({ init, item, props }) {
  const { form, location } = props || {}
  const { getFieldDecorator, getFieldValue, setFieldsValue, validateFields } = form || {}
  const { match } = props || {}
  const { params } = match || {}
  const { id } = params || {}
  const { search } = location || {}
  const [categories, setCategories] = useState([])
  const [categoriesCache, setCategoriesCache] = useState([])
  const [clients, setClients] = useState([])
  const [invoices, setInvoices] = useState([])
  const [loading, setLoading] = useState(false)
  const [providers, setProviders] = useState([])
  const [clientRef, setClientRef] = useState([])
  const [clientAppliedTotal, setClientAppliedTotal] = useState(0)
  const [searchingClient] = useState(false)
  const [searchingInvoice, setSearchingInvoice] = useState(false)
  const [searchingProvider] = useState(false)
  const isCategorySelected = !!item.category_number || !!getFieldValue('category_number')
  const isClientSelected = !!item.client_id || !!getFieldValue('client_id')
  const selectedClient = item.client_id || getFieldValue('client_id')
  const selectedProvider = item.provider_id || getFieldValue('provider_id')
  // const selectedInvoice = item.invoice_id || getFieldValue('invoice_id')
  const qs = new URLSearchParams(search)
  const clientRefId = qs.get('cid')

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  const isEdit = useCallback(() => {
    return id !== 'add'
  }, [id])

  const changeInvoice = useCallback((invoiceId) => {
    setCategories(categoriesCache)
    setFieldsValue({ category_number: undefined })

    if (invoiceId) {
      const invoice = invoices.find(({ id }) => BigNumber(id).isEqualTo(invoiceId))

      if (validator.isObject(invoice)) {
        setCategories(invoice.categories)
      }
    }
  }, [categoriesCache, invoices, setFieldsValue])

  const changeProvider = useCallback((providerId) => {
    setSearchingInvoice(true)
    setFieldsValue({ invoice_id: undefined })
    invoicePaceService
      .getAllDropdowns({ client_id: selectedClient, provider_id: providerId })
      .then((invoiceResponse) => {
        if (Array.isArray(invoiceResponse)) {
          setInvoices(invoiceResponse)
        }
      }).finally(() => {
        setSearchingInvoice(false)
      })
    changeInvoice()
  }, [changeInvoice, selectedClient, setFieldsValue])

  const changeClient = useCallback((clientId) => {
    setFieldsValue({ invoice_id: undefined, provider_id: undefined })
    changeProvider()
  }, [changeProvider, setFieldsValue])

  const pasteAmount = useCallback((field) => (event) => {
    const pastedText = common.getPastedText(event)

    if (pastedText) {
      const cleanedData = formatter.parseAmount(pastedText)
      setFieldsValue({ [field]: cleanedData })
      validateFields([field])
    }
  }, [setFieldsValue, validateFields])

  const filterClient = useCallback((input, option) => {
    const { props } = option || {}
    const { children } = props || []
    const str = Array.isArray(children) ? children.join(' ') : `${children}`
    return str.toLowerCase().indexOf(input.toLowerCase()) > -1
  }, [])

  const filterProvider = useCallback((input, option) => {
    const { props } = option || {}
    const { children } = props || []
    const str = Array.isArray(children) ? children.join(' ') : `${children}`
    return str.toLowerCase().indexOf(input.toLowerCase()) > -1
  }, [])

  const filterOptionCategory = useCallback((input, option) => {
    const { props } = option || {}
    const { children } = props || []
    const str = Array.isArray(children) ? children.join(' ') : `${children}`
    return str.toLowerCase().indexOf(input.toLowerCase()) > -1
  }, [])

  const searchInvoice = useCallback(() => {
    const onSearchInvoice = async (value) => {
      setSearchingInvoice(true)

      try {
        const response = await invoicePaceService.getAllDropdowns({
          client_id: selectedClient, provider_id: selectedProvider
        }, value)

        if (Array.isArray(response)) {
          setInvoices(response)
        }
      } finally {
        setSearchingInvoice(false)
      }
    }
    return debounce(onSearchInvoice, 500)
  }, [selectedClient, selectedProvider])

  const validateAmount = useCallback((rule, value, callback) => {
    if (!validator.isNullOrUndefined(value) && !validator.isEmptyString(value, true)) {
      const expectedAmount = formatter.toBigNumber(value)

      if (
        !validator.isCurrencyAmount(value) || expectedAmount.isNaN() || !expectedAmount.isFinite() || expectedAmount.decimalPlaces() > 2
      ) {
        callback(new Error('Please enter a number with 2 decimal places'))
      } else {
        callback()
      }
    } else {
      callback()
    }
  }, [])

  useEffect(() => {
    let mounted = true

    if (!hasAccess(Permissions.CREDIT.INFO_PACE.CREATE) && !hasAccess(Permissions.CREDIT.INFO_PACE.UPDATE)) {
      return
    }

    setLoading(true)
    const { invoice_id: invoiceId } = item
    Promise
      .all([
        pmRateSetCategoryPaceService.getAllUniques(),
        clientService.getAllDropdowns(),
        providerService.getAllDropdowns(),
        isEdit() && validator.isId(invoiceId) ? invoicePaceService.getAllDropdowns({ id: invoiceId }) : undefined,
        clientRefId ? clientService.getRef(clientRefId) : undefined,
        isEdit() ? creditApplyHistoryPaceService.listByPage(1, defaultPageSize, { credit_ref_id: id }) : undefined
      ])
      .then(([categoryResponse, clientResponse, providerResponse, invoiceResponse, clientRefResponse, creditResponse]) => {
        if (mounted) {
          if (Array.isArray(categoryResponse)) {
            setCategories(categoryResponse)
            setCategoriesCache(categoryResponse)
          }

          if (Array.isArray(clientResponse)) {
            setClients(clientResponse)
          }

          if (Array.isArray(providerResponse)) {
            setProviders(providerResponse)
          }

          if (Array.isArray(invoiceResponse)) {
            setInvoices(invoiceResponse)
          }

          if (validator.isObject(clientRefResponse)) {
            setClientRef(clientRefResponse)
          }

          if (validator.isObject(creditResponse)) {
            const { total } = creditResponse
            setClientAppliedTotal(total)
          }

        }
      }).finally(() => {
        if (mounted) {
          setLoading(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [hasAccess, isEdit, clientRefId, id, item])

  return (
    <Panel>
      <Skeleton active loading={init}>
        <Spin spinning={loading}>
          <Form>

            <FormItem {...formItemShortLayout} label='Participant'>
              {getFieldDecorator('client_id', {
                initialValue: item.client_id || clientRef.id || undefined,
                rules: [
                  { required: true, message: 'Please select participant' }
                ]
              })(
                <Select
                  allowClear={!loading && !searchingClient}
                  disabled={isEdit()}
                  dropdownMatchSelectWidth={false}
                  filterOption={filterClient}
                  loading={loading || searchingClient}
                  notFoundContent='No participant available'
                  placeholder='Select Participant'
                  showSearch
                  onChange={changeClient}
                >
                  {clients.map(({ id, first_name: firstName, last_name: lastName, ndis_number: ndisNumber, active }) => (
                    <Option key={id} disabled={!active} value={id}>{firstName} {lastName} ({ndisNumber})</Option>
                  ))}
                </Select>
              )}
            </FormItem>

            {isClientSelected ? (
              <FormItem {...formItemShortLayout} label="Provider">
                {getFieldDecorator('provider_id', {
                  initialValue: item.provider_id
                })(
                  <Select
                    allowClear={!loading && !searchingProvider}
                    disabled={isEdit()}
                    dropdownMatchSelectWidth={false}
                    filterOption={filterProvider}
                    loading={loading || searchingProvider}
                    notFoundContent='No provider available'
                    placeholder='Select Provider'
                    showSearch
                    onChange={changeProvider}
                  >
                    {providers.map(({ id, fullname, abn, active }) => (
                      <Option key={id} disabled={!active} value={id}>{fullname} {abn ? `(${abn})` : ''}</Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            ) : null}

            {isClientSelected ? (
              <FormItem {...formItemShortLayout} label="Invoice">
                {getFieldDecorator('invoice_id', {
                  initialValue: item.invoice_id
                })(
                  <Select
                    allowClear={!loading && !searchingInvoice}
                    disabled={isEdit()}
                    dropdownMatchSelectWidth={false}
                    filterOption={false}
                    loading={loading || searchingInvoice}
                    notFoundContent='No invoice available'
                    placeholder='Select Invoice'
                    showSearch
                    onChange={changeInvoice}
                    onSearch={searchInvoice()}
                  >
                    {invoices.map(({
                      id, provider_name: providerName, jid_number: jidNumber, invoice_type: invoiceType,
                      invoice_number: invoiceNumber, invoice_date: invoiceDate, invoice_provider_name: invProviderName,
                      amount, status_name: statusName, status_color: statusColor
                    }) => {
                      const statusStyle = validator.isNullOrUndefined(statusColor) || validator.isEmptyString(statusColor)
                        ? {} : { backgroundColor: statusColor }
                      return (
                        <Option key={id} value={id}>
                          {jidNumber.toUpperCase()}{' - '}
                          {invoiceNumber}{' - '}
                          {formatter.toShortDate(invoiceDate)}{' - '}
                          {providerName || invProviderName}{' - '}
                          {invoiceType === InvoiceType.INV_TYPE_PM.value
                            ? InvoiceType.INV_TYPE_PM.name : invoiceType === InvoiceType.INV_TYPE_RMB.value
                              ? InvoiceType.INV_TYPE_RMB.name : InvoiceType.INV_TYPE_STD.name}{' - '}
                          {formatter.toPrice(amount)}{' - '}
                          <span className='cp-invoice-status' style={statusStyle}>
                            {statusName ? statusName : formatter.capitalize(statusName)}
                          </span>
                        </Option>
                      )
                    })}
                  </Select>
                )}
              </FormItem>
            ) : null}

            {isClientSelected ? (
              <FormItem {...formItemShortLayout} label="Support Category">
                {getFieldDecorator('category_number', {
                  initialValue: item.category_number
                })(
                  <Select
                    allowClear
                    disabled={isEdit()}
                    dropdownMatchSelectWidth={false}
                    filterOption={filterOptionCategory}
                    notFoundContent='No support category available'
                    placeholder='Select Support Category'
                    showSearch
                  >
                    {categories.map(({
                      category_number: categoryNumber, category_name: categoryName
                    }) => <Option key={categoryNumber} value={categoryNumber}>{categoryName} ({categoryNumber})</Option>)}
                  </Select>
                )}
              </FormItem>
            ) : null}

            {isCategorySelected ? (
              <FormItem {...formItemShortLayout} label="Credit Amount">
                {getFieldDecorator('amount', {
                  initialValue: item.amount ? formatter.toPrice(item.amount, '') : undefined,
                  rules: [
                    { required: true, message: 'Please enter credit amount' },
                    { validator: validateAmount }
                  ],
                })(
                  <Input addonBefore="$" disabled={isEdit() && clientAppliedTotal !== 0} onPaste={pasteAmount('amount')} />
                )}
              </FormItem>
            ) : null}

            {isCategorySelected ? (
              <FormItem {...formItemShortLayout} label="Credit Notes on Statement">
                {getFieldDecorator('comment', {
                  initialValue: item.comment || null,
                })(
                  <TextArea />
                )}
              </FormItem>
            ) : null}

            {isCategorySelected ? (
              <FormItem {...formItemShortLayout} label="Private Notes">
                {getFieldDecorator('private_comment', {
                  initialValue: item.private_comment || null,
                })(
                  <TextArea />
                )}
              </FormItem>
            ) : null}
          </Form>
        </Spin>
      </Skeleton>
    </Panel>
  )
}

function getDefaultCurrentTab (props, tabs) {
  const { match } = props || {}
  const { params } = match || {}
  const { type } = params || {}
  const tab = tabs.find(({ path }) => path === `/${type}`)
  return isValidTab(tab) ? tab.key : tabs[0].key
}

function getTabs () {
  return Object.freeze([
    { key: '1', title: 'Credit Details', path: '/info' },
    { key: '2', title: 'Activity Log', path: '/logs' },
  ])
}

function isValidTab (tab) {
  return !!tab && !validator.isEmptyString(tab.key)
}

function TabList (props) {
  const { form, history, match } = props || {}
  const { validateFieldsAndScroll } = form || {}
  const { params } = match || {}
  const { id, type } = params || {}
  const tabs = useMemo(() => getTabs(props), [props])
  const [currentTab, setCurrentTab] = useState(getDefaultCurrentTab(props, tabs))
  const [init, setInit] = useState(true)
  const [item, setItem] = useState({})
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [showEdit, setShowEdit] = useState(true)
  const [showSave, setShowSave] = useState(false)
  const creditId = item.id
  const isFirstTab = currentTab === '1'

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  const isEdit = useCallback(() => {
    const { match } = props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }, [props])

  const toggleEdit = useCallback(() => {
    setShowEdit(!showEdit)
    setShowSave(true)
  }, [showEdit])

  const changeTab = useCallback((key) => {
    const tab = tabs.find(({ key: k }) => k === key)
    setCurrentTab(key)

    if (isValidTab(tab)) {
      if (id !== 'add' && !isNaN(Number(id))) {
        history.replace(`/credits-pace/${id}${tab.path}`);
      }
    }
  }, [history, tabs, id])

  const handleChangeTab = useCallback((key) => {
    changeTab(key)
  }, [changeTab])

  useEffect(() => {
    let mounted = true

    setTimeout(() => {
      if (mounted) {
        if (type === null || type === undefined || validator.isEmptyString(type)) {
          changeTab(tabs[0].key)
        }
      }
    }, 10)

    return () => {
      mounted = false
    }
  }, [changeTab, tabs, type])

  const handleSave = useCallback(() => {
    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        if (saving) {
          return
        }

        try {
          setSaving(true)
          let response
          values.amount = formatter.toBigNumber(values.amount).toFixed(2)
          if (values.comment === '') {
            values.comment = null
          }
          if (values.private_comment === '') {
            values.private_comment = null
          }

          if (isEdit()) {
            response = await creditPaceService.save(item.id, values)
          } else {
            response = await creditPaceService.add(values)
          }

          if (response.id) {
            notify.success('Saved successfully', `Credit saved successfully.`)

            if (!isEdit()) {
              history.replace(`/credits-pace/${response.ref_id}/info`)
            }
          } else {
            notify.error('Unable to save', `Unable to save credit. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save', `Unable to save credit. Please try again later.`)
        } finally {
          setSaving(false)
        }
      }
    })
  }, [item, history, isEdit, saving, validateFieldsAndScroll])

  const handleDelete = useCallback(async (id) => {
    if (loading || saving) {
      return
    }
    try {
      setSaving(true)
      const r = await creditPaceService.remove(id)

      if (r && r.id) {
        notify.success('Deleted successfully', 'Credit deleted successfully.')
        history.replace(`/credits-pace`)
      } else {
        notify.error('Unable to delete successfully', 'Unable to delete credit successfully. Please try again later.')
      }
    } catch (e) {
      notify.error('Unable to delete successfully', 'Unable to delete credit successfully. Please try again later.')
    } finally {
      setSaving(false)
    }
  }, [history, loading, saving])

  const handleDeleteCheck = useCallback(async () => {
    if (item.applied || item.closed) {
      warning({
        title: 'Unable to delete this credit',
        content: 'Credit have been applied on invoice(s)',
      })
    } else {
      confirm({
        title: 'Are you sure you want to delete this credit?',
        content: 'Press Ok to continue, Cancel to return',
        async onOk () {
          typeof handleDelete === 'function' && handleDelete(item.id)
        }
      })
    }
  }, [item, handleDelete])

  useEffect(() => {
    let mounted = true

    if (!hasAccess(Permissions.CREDIT.INFO_PACE.READ)) {
      setInit(false)
      return
    }

    setLoading(true)
    Promise
      .all([
        isEdit() ? creditPaceService.getByRefId(id) : undefined
      ])
      .then(([response]) => {
        if (mounted && validator.isObject(response)) {
          setItem(response)
        }
      }).finally(() => {
        if (mounted) {
          setInit(false)
          setLoading(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [hasAccess, isEdit, id])

  return (
    <Page.Body>
      <Page.Content nomenu>
        <Page.Header title={!isEdit() ? '(PACE) New Credit' : item.id ? `${item.category_name}` : null}>
          {hasAccess(Permissions.CREDIT.INFO_PACE.UPDATE) && isEdit() && isFirstTab && !loading && showEdit ? (
            <Button feedback={saving} onClick={toggleEdit}>Edit</Button>
          ) : null}

          {hasAccess(Permissions.CREDIT.INFO_PACE.DELETE) && isEdit() && isFirstTab && !loading && showSave ? (
            <Button ghost feedback={saving} onClick={handleDeleteCheck}>
              Delete
            </Button>
          ) : null}

          {(
            (hasAccess(Permissions.CREDIT.INFO_PACE.CREATE) && !isEdit()) ||
            (hasAccess(Permissions.CREDIT.INFO_PACE.UPDATE) && isEdit() && showSave)
          ) && isFirstTab ? (
            <Button feedback={saving} onClick={handleSave}>Save</Button>
          ) : null}

          <Button feedback={saving} onClick={history.goBack}>Back</Button>
        </Page.Header>

        <div className='cp-credit-panel'>
          <Tabs
            activeKey={currentTab}
            onChange={handleChangeTab}
          >
            <TabPane tab='Credit Details' key='1'>
              <CreditForm init={init} item={item} props={props} />

              {isEdit() && !init ? (
                <ApplyHistoryPanel props={props} />
              ) : null}
            </TabPane>

            {isEdit() && validator.isId(creditId) ? (
              <TabPane tab='Activity Log' key='2'>
                <ActivityLog creditId={creditId} props={props} saving={saving} />
              </TabPane>
            ) : null}
          </Tabs>
        </div>
      </Page.Content>
    </Page.Body>
  )
}

export class CreditPagePace extends Component {
  render () {
    return <TabList {...this.props} />
  }
}

export default Form.create()(CreditPagePace)
