import Permissions from '../permissions'

export default [
  {
    name: 'Contacts',
    icon: 'contacts',
    link: '/settings/contacts',
    desc: 'Configure Contacts Export',
    enable: true,
    permission: Permissions.SETTING.CONTACTS_EXPORT.LIST
  },
  {
    name: 'Custom Identifiers',
    icon: 'control',
    link: '/settings/custom-identifier',
    desc: 'Configure Custom Identifier',
    enable: true,
    permission: Permissions.SETTING.CUSTOM_IDF.LIST
  },
  {
    name: 'File',
    icon: 'file-protect',
    link: '/settings/files',
    desc: 'File Category Settings',
    enable: true,
    permission: Permissions.SETTING.FILES.LIST
  },
  {
    name: 'GST',
    icon: 'percentage',
    link: '/settings/gst',
    desc: 'GST Settings',
    enable: true,
    permission: Permissions.SETTING.GST.LIST
  },
  {
    name: 'Profile',
    icon: 'idcard',
    link: '/settings/profile',
    permission: [
      Permissions.SETTING.PACCREF.LIST,
      Permissions.SETTING.PLANGUAGE.LIST,
      Permissions.SETTING.PRELATIONSHIP.LIST
    ],
    enable: true,
    desc: 'Configure profile settings'
  },
  {
    name: 'Rate Set & Categories',
    icon: 'apartment',
    link: '/settings/rate-set',
    desc: 'Configure Rate Sets and Categories',
    enable: true,
    permission: Permissions.SETTING.RATESETS.LIST
  },
  {
    name: '(PACE) Rate Set & Categories',
    icon: 'apartment',
    link: '/settings/rate-set-pace',
    desc: 'Configure Rate Sets and Categories',
    enable: true,
    permission: Permissions.SETTING.RATESETS_PACE.LIST
  },
  {
    name: 'Reasons',
    link: '/settings/reasons',
    icon: 'exception',
    desc: 'Configure reasons of cancellation',
    enable: true,
    permission: Permissions.SETTING.REASON.LIST
  },
  {
    name: 'Others',
    icon: 'setting',
    link: '/settings/others',
    desc: 'Miscellaneous configuration',
    enable: true,
    permission: Permissions.SETTING.OTHERS.LIST
  },
  {
    name: 'Invoice Import',
    icon: 'import',
    link: '/settings/invoice-import',
    desc: 'Import all past invoices',
    enable: true,
    permission: Permissions.SETTING.INV_IMPORT.CREATE
  }
]
