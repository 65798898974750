import React, { Component, useState, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { apiHostname } from '../../../config'
import { auth, common, exportFile, formatter, queryString, validator } from '../../../util'
import { authService, statementPaceService } from '../../../services'
import { ExportType, InvoiceListType, StatementMenu, Permissions } from '../../../constants'

// UI
import { Button, ControlLabel, Checkbox, List, Page, Pager, SearchInput } from '../../../components'
import notify from '../../../components/Notification'

import './styles.css'

import Col from 'antd/lib/col'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'

const { confirm } = Modal

const pageSize = 10
const Option = Select.Option

const getTitle = (type) => {
  let title

  if (type === InvoiceListType.INV_LIST_PM) {
    title = '(PACE) PM SB Statements'
  } else if (type === InvoiceListType.INV_LIST_STD) {
    title = '(PACE) Std SB Statements'
  } else {
    title = '(PACE) Statement Periods'
  }

  return title
}

const StatementColumns = () => {
  const isBeyondMonth = (d1, d2) =>{
    const d1s = formatter.toMoment(d1)
    const d2s = formatter.toMoment(d2)

    if (d2s.isAfter(d1s)) {
      return d2s.isSameOrAfter(d1s, 'month')
    } else {
      return false
    }
  }

  const getRowTextStyle = (isClientActive, isBeyond) => {
    let color = undefined
    if (isClientActive) {
      if (isBeyond) {
        color = '#FF3300'
      } else {
        color = undefined
      }
    } else {
      if (isBeyond) {
        color = '#FF330044'
      } else {
        color = '#ccc'
      }
    }

    return color
  }

  const columns = [
    {
      title: 'Participant',
      width: 3,
      render: ({ client_fullname, client_id, client_ref_id, client_active, period_end_date, period_date_to }) => {
      const isBeyond = isBeyondMonth(period_end_date, period_date_to)
      return <div>
        <a style={{color: getRowTextStyle(client_active, isBeyond)}} href={`/participants/${client_ref_id}/info`} rel='noopener noreferrer' target='_blank'>
          {client_fullname}
        </a>
      </div>
      }
    },
    {
      title: 'Booking Number',
      width: 3,
      render: ({ booking_number, client_active, period_end_date, period_date_to }) => {
        const isBeyond = isBeyondMonth(period_end_date, period_date_to)

        return <div style={{color: getRowTextStyle(client_active, isBeyond)}}>{booking_number}</div>
      }
    },
    {
      title: 'SB Period',
      width: 4,
      render: ({ client_active, period_start_date, period_end_date, period_date_to }) => {
        const isBeyond = isBeyondMonth(period_end_date, period_date_to)

        return <div style={{color: getRowTextStyle(client_active, isBeyond)}}>{formatter.toShortDate(period_start_date)} - {formatter.toShortDate(period_end_date)}</div>
      }
    },
    {
      title: 'Opening Bal',
      width: 3,
      render: ({ client_active, total_last_month_value, cum_rcv_total, period_end_date, period_date_to }) => {
        const isBeyond = isBeyondMonth(period_end_date, period_date_to)

        return <div style={{color: getRowTextStyle(client_active, isBeyond)}}>{formatter.toPrice(parseFloat(total_last_month_value) + parseFloat(cum_rcv_total))}</div>
      }
    },
    {
      title: 'Total Paid Amount',
      width: 3,
      render:({ client_active, cum_rcv_total, period_end_date, period_date_to }) => {
        const isBeyond = isBeyondMonth(period_end_date, period_date_to)

        return <div style={{color: getRowTextStyle(client_active, isBeyond)}}>{formatter.toPrice(cum_rcv_total)}</div>
      }
    },
    {
      title: 'Closing Bal',
      width: 3,
      render: ({ client_active, total_last_month_value, period_end_date, period_date_to }) => {
        const isBeyond = isBeyondMonth(period_end_date, period_date_to)

        return <div style={{color: getRowTextStyle(client_active, isBeyond)}}>{formatter.toPrice(total_last_month_value)}</div>
      }
    },
    {
      title: 'Total Credit Balance',
      width: 3,
      render: ({ client_active, cum_credit_remaining_amount, period_end_date, period_date_to }) => {
        const isBeyond = isBeyondMonth(period_end_date, period_date_to)

        return <div style={{color: getRowTextStyle(client_active, isBeyond)}}>{formatter.toPrice(cum_credit_remaining_amount)}</div>
      }
    },
    {
      title: '',
      width: 2,
      render: (item) => {
        const isChecked =  false
        // const isEmail = item.client_authorize_rep_email || item.client_email
        const isEmail = !!item.client_statement_email

        return (<div className='action-buttons' key={`select${item.budget_id}`}>
          <div style={{paddingRight: '15px'}}>
            <Checkbox
              checked={isChecked}
              // onClick={(e) => this.updateItemSelect(e, {
              //   id: item.id,
              //   budget_id: item.budget_id,
              //   is_email: isEmail,
              //   booking_number: item.booking_number,
              //   budget_client_id: item.budget_client_id
              // })}
            />
          </div>
          <Tooltip mouseLeaveDelay={0} title='Download'>
            <div style={{cursor: 'pointer'}} >
              <Icon type='download' />
            </div>
          </Tooltip>
          <Tooltip mouseLeaveDelay={0} title={isEmail ? 'Send Email' : 'No Email Recipient for Statement'}>
            { isEmail
              ? <div style={{cursor: 'pointer'}} >
                <Icon type='mail' />
              </div>
              : <div style={{cursor: 'default'}}>
                <Icon type='mail' style={{color: '#ccc'}} />
              </div> }
          </Tooltip>
        </div>)
      }
    },
  ]

  return columns
}

function StatementClient (props) {
  const { form, history, match } = props || {}
  const [loading, setLoading] = useState(false)
  const [loadingList, setLoadingList] = useState(false)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [init, setInit] = useState(true)
  const [searching, setSearching] = useState(false)
  const [list, setList] = useState([])
  const [detail, setDetail] = useState([])
  const { type = InvoiceListType.INV_LIST_PM } = common.getPath(props.match)
  const title = getTitle(type)
  const { date } = common.getPath(props.match)

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  const listByPage = useCallback(({ page }) => {
    const _page = typeof page === 'number' && page > 0 ? page : 1
    setLoading(true)
    setPage(_page)
    statementPaceService.listStatementClientByPage(detail.id, _page, pageSize)
      .then((response) => {
        if (validator.isObject(response)) {
          const { list, total } = response
          setList(list)
          setTotal(total)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [type])

  const changePage = useCallback((page) => {
    listByPage({ page })
  }, [listByPage])

  useEffect(() => {
    let mounted = true
    const fetchData = async () => {
      try {
        if (!hasAccess(Permissions.CREDIT.INFO_PACE.READ)) {
          setInit(false)
          return
        }
        setLoading(true)
        const detail = await statementPaceService.getDate(1)

        if (mounted) {
          if (detail && detail.id) {
            setDetail(detail)
          }
        }
      } finally {
        if (mounted) {
          setLoading(false)
        }
      }
    }

    fetchData()

    return () => {
      mounted = false
    }
  }, [hasAccess, date])

  useEffect(() => {
    if (!hasAccess(Permissions.CREDIT.INFO_PACE.READ)) {
      setInit(false)
      return
    }
    const fetchPeriod = async () => {
      try {
        setLoading(true)
        const detail = await statementPaceService.getDate(date)
        if (detail && detail.id) {
          setDetail(detail)
        }
      }finally {
        setLoading(false)
      }
    }

    fetchPeriod()

  }, [hasAccess, date])

   useEffect(() => {
      let mounted = true

      if (!hasAccess(Permissions.CREDIT.INFO_PACE.READ)) {
        setInit(false)
        return
      }

      setLoading(true)
      statementPaceService.listStatementClientByPage(detail.id, page, pageSize)
        .then((response) => {
          if (mounted && validator.isObject(response)) {
            const { list, total } = response

            if (Array.isArray(list)) {
              setList(list)
            }

            setTotal(total)
          }
        })
        .finally(() => {
          if (mounted) {
            setInit(false)
            setLoading(false)
          }
        })

      return () => {
        mounted = false
      }
    }, [hasAccess, detail])
console.log(39030, list)
  return (
    <Page.Body>
      <Page.Left>
        <Page.Menu title='Home' menu={StatementMenu} />
      </Page.Left>
      <Page.Content full>
        <Page.Header title={title} total={detail && detail.id ? detail.name : ''} >
          <div className='btn' onClick={history.goBack}>Back</div>
        </Page.Header>

        {/* <div className='action-buttons-sc'>
            { isSelect && filterType === InvoiceListType.INV_LIST_STD ? <Button style={{backgroundColor: '#34ebb4'}} key='submitaba' feedback={loadingList} onClick={() => this.preStatement(ExportType.TYPE_SB_PRODA_V2)}>Download Pymt Req</Button> : null }
            { isSelect ? <Button style={{backgroundColor: '#3d34eb'}} key='submit1' feedback={loadingList} onClick={() => this.preStatement(ExportType.TYPE_STMT, false, true)}>Download Selected</Button> : null }
            { isSelect ? <Button style={{backgroundColor: '#ebc034'}} key='submit2' feedback={loadingList} onClick={() => this.preStatement(ExportType.TYPE_STMT, true, false)}>Email Selected</Button> : null }
            { isSelect ? <Button key='submit3' feedback={loadingList} onClick={() => this.preStatement(ExportType.TYPE_STMT, true, true)}>Download & Email Selected</Button> : null }
          </div> */}

        <Page.Filter>
          <Row gutter={8}>
            <Col lg={9}>
              <ControlLabel>Participant Name, Booking Number</ControlLabel>
              <SearchInput placeholder='Search statement' isSearching={searching} />
            </Col>
            <Col lg={10} />
            <Col lg={5}>
              {/* { list && list.list && validator.isNotEmptyArray(list.list) && !loading
                  ? <div className='inv-sec-row-left' style={{marginTop: '12px'}}>
                    <div style={{marginRight: '10px', fontSize: '11px', fontWeight: '400', color: '#333'}}>Select / Deselect All ({selectList.length} item{selectList.length === 1 ? '' : 's'} selected)</div>
                    <Checkbox
                      checked={selectListAll}
                      onClick={f => this.updateItemSelectAll(f)}
                    />
                  </div>
                  : null } */}

            </Col>
          </Row>
        </Page.Filter>

        <Skeleton loading={loading} active>
          <Spin spinning={loadingList}>
            <List cols={StatementColumns()} rows={list} />
          </Spin>
        </Skeleton>

        <Pager
          size={pageSize}
          total={total}
          totalText={`Total ${total} statements`}
          current={page}
          onChange={changePage}
          style={{ marginTop: '15px' }}
        />
      </Page.Content>
    </Page.Body>
  )
}

export class StatementListClient extends Component {
  render () {
    return <StatementClient {...this.props} />
  }
}

export default StatementListClient