import { request, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/pm-rate-sets-pace'

export default {
  add (values) {
    return request(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  checkDates (startDate, endDate, id, rateId) {
    return request(`${url}/check/dates${queryString.stringify({ id, start_date: startDate, end_date: endDate, rate_id: rateId })}`)
  },
  get (id) {
    return request(`${url}/${id}`)
  },
  getAll (filter, size) {
    return request(`${url}${queryString.stringify({ filter: JSON.stringify(filter), size })}`)
  },
  getAllConfigs (filter) {
    return request(`${url}/configs${queryString.stringify({ filter: JSON.stringify(filter) })}`)
  },
  getAllValues (filter, text) {
    return request(`${url}/values${queryString.stringify({ filter: JSON.stringify(filter), text })}`)
  },
  listByPage (page, size, filter, sorting, text) {
    return request(`${url}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  importFile (id, values) {
    return request(`${url}/file-start/${id}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  remove (id) {
    return request(`${url}/${id}`, {
      method: 'DELETE',
      headers
    })
  },
  save (id, values) {
    return request(`${url}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  }
}
