import React, { Component, useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'

import { Permissions } from '../../../../constants'
import { logPaceService } from '../../../../services'
import { auth, validator } from '../../../../util'

// UI
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'

import { List, Pager } from '../../../../components'

import './styles.css'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const defaultPageSize = 20

function getActivityLogColumns () {
  return Object.freeze([
    {
      title: 'Created At',
      width: 5,
      render: ({ created_at }) => moment(created_at).format('DD/MM/YYYY hh:mm A')
    },
    {
      title: 'Updated By',
      width: 4,
      render: ({ member }) => <div className='updated-name' style={{ textTransform: 'capitalize' }}>{member}</div>
    },
    {
      title: 'Action',
      width: 3,
      render: ({ action }) => <div className='updated-name' style={{ textTransform: 'uppercase' }}>{action}</div>
    },
    {
      title: 'Changes',
      width: 12,
      render: ({ changes }) => <div dangerouslySetInnerHTML={{ __html: changes && changes.replace(/\n/g, '<br />') }}></div>
    }
  ])
}

function ActivityLog (props) {
  const { selectedInvoice } = props || {}
  const invoice = validator.isObject(selectedInvoice) && validator.isId(selectedInvoice.id) ? selectedInvoice : {}
  const [init, setInit] = useState(true)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)

  const listByPage = useCallback(({ page }) => {
    const _page = typeof page === 'number' && page > 0 ? page : 1
    setLoading(true)
    setPage(_page)
    logPaceService.listInvoiceLogs(_page, defaultPageSize, { genre_id: invoice.id })
      .then((response) => {
        if (validator.isObject(response)) {
          const { list, total } = response

          if (Array.isArray(list)) {
            setList(list)
          }

          if (typeof total === 'number') {
            setTotal(total)
          }
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [invoice])

  const changePage = useCallback((page) => {
    listByPage({ page })
  }, [listByPage])

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  useEffect(() => {
    let mounted = true

    if (!hasAccess(Permissions.INVOICE.INFO_PACE.READ)) {
      setInit(false)
      return
    }

    setLoading(true)

    if (!validator.isId(invoice.id)) {
      return
    }

    logPaceService
      .listInvoiceLogs(1, defaultPageSize, { genre_id: invoice.id })
      .then((response) => {
        if (mounted && validator.isObject(response)) {
          const { list, total } = response

          if (Array.isArray(list)) {
            setList(list)
          }

          if (typeof total === 'number') {
            setTotal(total)
          }
        }
      })
      .finally(() => {
        if (mounted) {
          setInit(false)
          setLoading(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [hasAccess, invoice])

  return (
    <Skeleton active loading={init}>
      <Spin spinning={loading}>
        <List cols={getActivityLogColumns()} rows={list} />

        <Pager
          size={defaultPageSize}
          total={total}
          current={page}
          onChange={changePage}
          totalText={`Total ${total} logs`}
          style={{ marginTop: '15px' }}
        />
      </Spin>
    </Skeleton>

  )
}

export class PaceActivityLog extends Component {
  render () {
    return <ActivityLog {...this.props} />
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { ...state.InvoicePace }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaceActivityLog)
