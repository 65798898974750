import React, { Component, useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'

import { InvoiceStatus, InvoiceTypePace as InvoiceType, InvoiceUpdateType, Permissions } from '../../../constants'
import { filePaceService, invoiceBulkPaceService, invoicePaceService } from '../../../services'
import { setFileAttachmentCount, setSelectedInvoice, setSelectedInvoiceType } from '../../../states/actions'
import { auth, formatter, validator } from '../../../util'

// UI
import Badge from 'antd/lib/badge'
import Tabs from 'antd/lib/tabs'

import { Button, Page, Panel } from '../../../components'
import notify from '../../../components/Notification'
import ActivityLog from './ActivityLog'
import Communication from './Communication'
import File from './File'
import InvoiceDetail from './InvoiceDetail'

import './styles.css'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const { TabPane } = Tabs

function getDefaultCurrentTab (props, tabs) {
  const { match } = props || {}
  const { params } = match || {}
  const { type } = params || {}
  const tab = tabs.find(({ path }) => path === `/${type}`)
  return isValidTab(tab) ? tab.key : tabs[0].key
}

function getTabs (props) {
  const { match } = props || {}
  const { params } = match || {}
  const { id } = params || {}
  const isEdit = id !== 'add'
  const visible = auth.hasAccess([Permissions.INVOICE.INFO_PACE.CREATE, Permissions.INVOICE.INFO_PACE.READ, Permissions.INVOICE.INFO_PACE.UPDATE])
  const visible1 = visible
  const visible2 = isEdit && visible
  const visible3 = isEdit && visible
  const visible4 = isEdit && auth.hasAccess(Permissions.INVOICE.FILES_PACE.LIST)
  const visible5 = isEdit && visible
  return Object.freeze([
    { key: '1', title: 'Invoice Details', path: '/details', visible: visible1, children: <InvoiceDetail {...props} /> },
    { key: '2', title: 'Custom Identifier', path: '/identifiers', visible: visible2 },
    { key: '3', title: <>Communication <Badge count={0} /></>, path: '/comms', visible: visible3, children: <Communication {...props} /> },
    { key: '4', title: 'Files', path: '/files', visible: visible4, children: <File {...props} /> },
    { key: '5', title: 'Activity Log', path: '/logs', visible: visible5, children: <ActivityLog {...props} /> }
  ])
}

function isValidTab (tab) {
  return tab && !validator.isEmptyString(tab.key)
}

function InvoicePage (props) {
  const {
    fileAttachmentCount, formAttachmentCount, history, match, selectedInvoice, selectedInvoiceType, setFileAttachmentCount,
    setSelectedInvoice, setSelectedInvoiceType, onSave, onSaveAndProcess, onSaveAsDraft
  } = props || {}
  const { params } = match || {}
  const { id } = params || {}
  const { id: invoiceId, is_sdb_invoice: isSdbInvoice, status } = selectedInvoice || {}
  const tabs = useMemo(() => getTabs(props), [props])
  const [currentTab, setCurrentTab] = useState(getDefaultCurrentTab(props, tabs))
  const [init, setInit] = useState(true)
  const [showEdit, setShowEdit] = useState(true)
  const [saving, setSaving] = useState(false)
  const canSaveAndProcess = (id === 'add' && formAttachmentCount > 0) ||
    (!showEdit && [InvoiceStatus.Drafted.value, InvoiceStatus.Rejected.value].indexOf(status) > -1 && fileAttachmentCount > 0)
  const canSaveAsDraft = id === 'add' || (!showEdit && status === InvoiceStatus.Drafted.value)
  const canGetPaymentRequest = [
    InvoiceStatus.ToReceive.value, isSdbInvoice ? 'X' : InvoiceStatus.ToPay.value, InvoiceStatus.Closed.value
  ].indexOf(status) > -1
  const canGetAba = !isSdbInvoice && status === InvoiceStatus.Closed.value
  const canGetRemittance = !isSdbInvoice && status === InvoiceStatus.Closed.value
  const canSendToAuthEmail = !isSdbInvoice && status === InvoiceStatus.Processing.value
  const canCancel = !showEdit && status === InvoiceStatus.Rejected.value
  const canDelete = !showEdit && [InvoiceStatus.Drafted.value, InvoiceStatus.Cancelled.value].indexOf(status) > -1
  const canReject = !showEdit && [
    isSdbInvoice ? 'X' : InvoiceStatus.Processing.value, isSdbInvoice ? 'X' : InvoiceStatus.Drafted.value,
    isSdbInvoice ? 'X' : InvoiceStatus.ToAuthorise.value, InvoiceStatus.ToClaim.value, InvoiceStatus.ToReceive.value,
    isSdbInvoice ? 'X' : InvoiceStatus.ToPay.value
  ].indexOf(status) > -1
  const canRevertToDraft = !showEdit && status === InvoiceStatus.Rejected.value
  const canSave = !showEdit && [
    isSdbInvoice ? 'X' : InvoiceStatus.Processing.value, isSdbInvoice ? 'X' : InvoiceStatus.ToAuthorise.value,
    InvoiceStatus.ToClaim.value, InvoiceStatus.ToReceive.value, isSdbInvoice ? 'X' : InvoiceStatus.ToPay.value
  ].indexOf(status) > -1

  // TODO - TODO
  // Provider Invoice and Reimbursement
  // Drafted - Edit > Delete, Reject, Save & Process, Save as Draft
  // To Auth - Edit > Delete, Reject, Save
  // To Claim - Edit > Reject, Save
  // To Receive - Get Payment Request, Edit > Reject, Save
  // To Pay - Get Payment Request, Edit > Reject, Save
  // Rejected - Edit > Cancel, Save & Process, Revert to Draft
  // Cancelled - Edit > Delete
  // Closed - Get Payment Request, Get ABA, Get Remittance, Edit > Save

  // PM Fee Invoice
  // To Claim - Edit > Reject, Save
  // To Receive - Get Payment Request, Edit > Reject, Save
  // Rejected - Edit > Cancel, Save & Process, Revert to Draft
  // Cancelled - No action
  // Closed - Get Payment Request, Edit > Save

  const handleBulk = useCallback((action, successLabel, successFunc) => {
    if (saving || !validator.isId(invoiceId)) {
      return
    }

    setSaving(true)
    invoiceBulkPaceService
      .bulkUpdate(action, { invoices: [{ id: invoiceId }] })
      .then(async (response) => {
        if (validator.isObject(response)) {
          const { success } = response

          if (success === true) {
            notify.success(`${formatter.capitalize(successLabel)} Successfully`, `Invoice ${successLabel} successfully.`)

            if (typeof successFunc === 'function') {
              try {
                await successFunc()
              } finally {
                setSaving(false)
              }
            }
          } else {
            notify.error(`Unable to ${action}`, `Unable to ${action} successfully. Please try again later.`)
          }
        }
      })
      .catch((e) => {
        notify.error(`Unable to ${action}`, `Unable to ${action} successfully. Please try again later.`)
        console.log(e)
        setSaving(false)
      })
  }, [id, invoiceId, saving])

  const isEdit = useCallback(() => {
    return id !== 'add'
  }, [id])

  const changeTab = useCallback((key) => {
    const tab = tabs.find(({ key: k }) => k === key)
    setCurrentTab(key)

    if (isValidTab(tab)) {
      history.replace(`/invoices-pace/${id}${tab.path}`)
    }
  }, [history, id, tabs])

  const handleDelete = useCallback(() => {
    handleBulk('delete', 'deleted', () => {
      history.replace(`/invoices-list-pace/${selectedInvoiceType.value}`)
    })
  }, [handleBulk, history, selectedInvoiceType])

  const handleReject = useCallback(() => {
    handleBulk('reject', 'rejected', () => {
      return invoicePaceService.getByRefId(id).then(async (response) => {
        if (validator.isObject(response) && validator.isId(response.id)) {
          setSelectedInvoice(response)
        }
      })
    })
  }, [handleBulk])

  const handleSave = useCallback(() => {
    if (typeof onSave === 'function') {
      if (saving) {
        return
      }

      setSaving(true)
      onSave().finally(() => {
        setSaving(false)
      })
    } else {
      notify.error('Unable to save successfully', 'Unable to save successfully. Please try again later.')
    }
  }, [onSave, saving])

  const handleSaveAndProcess = useCallback(() => {
    if (typeof onSaveAndProcess === 'function') {
      if (saving) {
        return
      }

      setSaving(true)
      onSaveAndProcess().finally(() => {
        setSaving(false)
      })
    } else {
      notify.error('Unable to save and process successfully', 'Unable to save and process successfully. Please try again later.')
    }
  }, [onSaveAndProcess, saving])

  const handleSaveAsDraft = useCallback(() => {
    if (typeof onSaveAsDraft === 'function') {
      if (saving) {
        return
      }

      setSaving(true)
      onSaveAsDraft().finally(() => {
        setSaving(false)
      })
    } else {
      notify.error('Unable to save as draft successfully', 'Unable to save as draft successfully. Please try again later.')
    }
  }, [onSaveAsDraft, saving])

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  const getTitle = useCallback((init, invoice, invoiceType) => {
    const { invoice_number: invoiceNumber } = invoice || {}
    return !init ? `(PACE) ${!isEdit() ? 'New ' : ''}${invoiceType.name}${invoiceNumber ? ` - ${invoiceNumber}` : ''}` : ''
  }, [isEdit])

  const toggleShowEdit = useCallback(() => {
    setShowEdit(!showEdit)
  }, [showEdit])

  useEffect(() => {
    if (!hasAccess([Permissions.INVOICE.INFO_PACE.CREATE, Permissions.INVOICE.INFO_PACE.READ, Permissions.INVOICE.INFO_PACE.UPDATE])) {
      setInit(false)
      return
    }

    let mounted = true

    if (validator.isId(id)) {
      invoicePaceService.getByRefId(id).then(async (response) => {
        if (mounted && validator.isObject(response) && validator.isId(response.id)) {
          const { id: invoiceId, invoice_type: rInvoiceType } = response
          const invoiceType = InvoiceType.INV_TYPE_STD.value === rInvoiceType
            ? InvoiceType.INV_TYPE_STD : InvoiceType.INV_TYPE_RMB.value === rInvoiceType
              ? InvoiceType.INV_TYPE_RMB : InvoiceType.INV_TYPE_PM
          const fileResponse = await filePaceService
            .listInvoiceFiles(1, 1, { genre_id: invoiceId, sub_cat_is_attach_mail_comm: true })

          if (validator.isObject(fileResponse)) {
            // Set initial file email attachment count
            const { total } = fileResponse
            setFileAttachmentCount(total)
          }

          setSelectedInvoice(response)
          setSelectedInvoiceType(invoiceType)
        }
      }).finally(() => {
        if (mounted) {
          setInit(false)
        }
      })
    } else {
      setInit(false)
      setSelectedInvoice()
    }

    return () => {
      mounted = false
    }
  }, [hasAccess, setFileAttachmentCount, setSelectedInvoice, setSelectedInvoiceType, id])

  return (
    <Page.Body key={id}>
      <Page.Content nomenu>
        <Page.Header title={getTitle(init, selectedInvoice, selectedInvoiceType)}>
          {hasAccess(Permissions.INVOICE.MGMT_PACE.LIST) && isEdit() ? (
            <>
              {canGetPaymentRequest ? (
                <Button ghost feedback={saving} onClick={() => { }}>
                  Get Payment Request (WIP)
                </Button>
              ) : null}

              {canGetAba ? (
                <Button ghost feedback={saving} onClick={() => { }}>
                  Get ABA (WIP)
                </Button>
              ) : null}

              {canGetRemittance ? (
                <Button ghost feedback={saving} onClick={() => { }}>
                  Get Remittance (WIP)
                </Button>
              ) : null}
            </>
          ) : null}

          {hasAccess(Permissions.INVOICE.INFO_PACE.UPDATE) && isEdit() && canSendToAuthEmail ? (
            <Button ghost feedback={saving} onClick={() => { }}>
              Send Inv Authorisation Email (WIP)
            </Button>
          ) : null}

          {hasAccess(Permissions.INVOICE.INFO_PACE.DELETE) && isEdit() && canDelete ? (
            <Button ghost feedback={saving} onClick={handleDelete}>
              Delete
            </Button>
          ) : null}

          {hasAccess(Permissions.INVOICE.INFO_PACE.UPDATE) && isEdit() ? (
            <>
              {!init && showEdit ? (
                <Button feedback={saving} onClick={toggleShowEdit}>
                  Edit
                </Button>
              ) : null}

              {canCancel ? (
                <Button ghost feedback={saving} onClick={() => { }}>
                  Cancel (WIP)
                </Button>
              ) : null}

              {canReject ? (
                <Button feedback={saving} type='alert' onClick={handleReject}>
                  Reject
                </Button>
              ) : null}

              {canSaveAndProcess ? (
                <Button feedback={saving} onClick={handleSaveAndProcess}>
                  Save & Process (WIP)
                </Button>
              ) : null}

              {canSaveAsDraft ? (
                <Button feedback={saving} type='alert' onClick={handleSaveAsDraft}>
                  Save as Draft (WIP)
                </Button>
              ) : null}

              {canSave ? (
                <Button feedback={saving} onClick={handleSave}>
                  Save
                </Button>
              ) : null}

              {canRevertToDraft ? (
                <Button feedback={saving} type='alert' onClick={() => { }}>
                  Revert to Draft (WIP)
                </Button>
              ) : null}
            </>
          ) : null}

          {hasAccess(Permissions.INVOICE.INFO_PACE.CREATE) && !isEdit() ? (
            <>
              {/* This creates a new invoice in "Processing" status */}
              {canSaveAndProcess ? (
                <Button feedback={saving} onClick={handleSaveAndProcess}>
                  Save & Process
                </Button>
              ) : null}

              {/* This creates a new invoice in "Drafted" status */}
              <Button feedback={saving} type='alert' onClick={handleSaveAsDraft}>
                Save as Draft
              </Button>
            </>
          ) : null}

          <Button feedback={saving} onClick={history.goBack}>Back</Button>
        </Page.Header>

        <Panel className='ipp-panel'>
          <Tabs activeKey={currentTab} onChange={changeTab}>
            {tabs.map(({ key, title, visible, children }) => visible
              ? <TabPane key={key} tab={title}>{children}</TabPane>
              : null)}
          </Tabs>
        </Panel>
      </Page.Content>
    </Page.Body>
  )
}

export class InvoicePagePace extends Component {
  render () {
    return <InvoicePage {...this.props} />
  }
}

const mapDispatchToProps = {
  setFileAttachmentCount,
  setSelectedInvoice,
  setSelectedInvoiceType
}

const mapStateToProps = (state) => {
  return { ...state.InvoicePace }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoicePagePace)
