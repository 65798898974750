import { request, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/logs-pace'

export default {
  listCreditLogs (page, size, filter, sorting, text) {
    return request(`${url}/credit/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listInvoiceLogs (page, size, filter, sorting, text) {
    return request(`${url}/invoice/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  }
}
