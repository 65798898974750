import { request, requestFile, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/report-pace/scheduler'

export default {
  listSchedulerReportByPage (page, size, filter, sorting, text) {
    return request(`${url}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  addScheduleReport (values) {
    return request(`${url}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  }
}
