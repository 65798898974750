import { request, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/pm-rate-settings-pace'

export default {
  getAllFundingTypes (filter) {
    return request(`${url}/funding-types${queryString.stringify({ filter: JSON.stringify(filter) })}`)
  }
}
