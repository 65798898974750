import React, { Component, useCallback, useEffect, useMemo, useState } from 'react'

import { Permissions } from '../../../constants'
import SettingMenu from '../../../constants/menu/setting'
import { auth, validator } from '../../../util'

// UI
import Tabs from 'antd/lib/tabs'

import { Page } from '../../../components'
import Category from './Category'
import Grouping from './Grouping'
import Rate from './Rate'
import RateSet from './RateSet'

import './styles.css'

const { TabPane } = Tabs

function getDefaultCurrentTab(props, tabs) {
  const { match } = props || {}
  const { params } = match || {}
  const { type } = params || {}
  const tab = tabs.find(({ path }) => path === `/${type}`)
  return isValidTab(tab) ? tab.key : tabs[0].key
}

function getTabs(props) {
  return Object.freeze([
    { key: '1', title: 'Rate Sets', path: '/rate-sets', permission: Permissions.SETTING.RATESETS_PACE.LIST, children: <RateSet {...props} /> },
    { key: '2', title: 'Categories', path: '/categories', permission: Permissions.SETTING.RATESETS_PACE.LIST, children: <Category {...props} /> },
    { key: '3', title: 'Groupings', path: '/groupings', permission: Permissions.SETTING.RATESET_GROUPINGS_PACE.LIST, children: <Grouping {...props} /> },
    { key: '4', title: 'Rates', path: '/rates', permission: Permissions.SETTING.RATESETS_PACE.LIST, children: <Rate {...props} /> }
  ])
}

function isValidTab(tab) {
  return tab && !validator.isEmptyString(tab.key)
}

function SettingRateSetTabs(props) {
  const { history, match } = props || {}
  const { params } = match || {}
  const { type } = params || {}
  const tabs = useMemo(() => getTabs(props), [props])
  const [currentTab, setCurrentTab] = useState(getDefaultCurrentTab(props, tabs))

  const changeTab = useCallback((key) => {
    const tab = tabs.find(({ key: k }) => k === key)
    setCurrentTab(key)

    if (isValidTab(tab)) {
      history.replace(`/settings/rate-set-pace${tab.path}`)
    }
  }, [history, tabs])

  const hasAccess = useCallback((accessLevel) => {
    return auth.hasAccess(accessLevel)
  }, [])

  useEffect(() => {
    let mounted = true

    const timer = setTimeout(() => {
      clearTimeout(timer)

      if (mounted) {
        if (type === null || type === undefined || validator.isEmptyString(type)) {
          changeTab(tabs[0].key)
        }
      }
    }, 10)

    return () => {
      mounted = false
    }
  }, [changeTab, tabs, type])

  return (
    <Tabs activeKey={currentTab} onChange={changeTab}>
      {tabs.map(({ key, title, permission, children }) => hasAccess(permission)
        ? <TabPane key={key} tab={title}>{children}</TabPane>
        : null)}
    </Tabs>
  )
}

function SettingRateSet(props) {
  return (
    <Page.Body>
      <Page.Left>
        <Page.Menu title='Home' menu={SettingMenu} />
      </Page.Left>

      <Page.Content full>
        <Page.Header title='(PACE) Rate Set & Categories' />

        <SettingRateSetTabs {...props} />
      </Page.Content>
    </Page.Body>
  )
}

export class SettingRateSetPace extends Component {
  render() {
    return <SettingRateSet {...this.props} />
  }
}

export default SettingRateSetPace
