import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'

import formatter from './formatter'
import validator from './validator'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

function getExtraMessage (list, label, onGetLink) {
  const hasItem = Array.isArray(list) && list.length > 0

  if (hasItem) {
    return (
      <ol className='rsp-ol-form-extra ant-form-explain'>
        {list.map(({ id, id_number, name, start_date: startDate, end_date: endDate }, idx) => {
          const linkLabel = !validator.isNullOrUndefined(label) && !validator.isEmptyString(label, true) ? label : name || id_number

          return (
            <li key={idx}>
              <div>{formatter.toShortDate(startDate)} - {formatter.toShortDate(endDate)}</div>

              <div className='ant-form-extra'>
                {typeof onGetLink === 'function' ? (
                  <Link to={onGetLink(id)} rel='noopener noreferrer' target='_blank'>
                    {linkLabel}
                  </Link>
                ) : linkLabel}
              </div>
            </li>
          )
        })}
      </ol>
    )
  }
}

const periodValidator = Object.freeze({
  async validateEndDate (params) {
    let error
    let extra
    let help
    let validateStatus

    if (validator.isObject(params)) {
      const { startDate, endDate, item, linkLabel, onCheckDate, onGetLink } = params

      if (moment(endDate).isBefore(startDate)) {
        error = new Error('End date must be after start date')
      } else if (!moment(endDate).isSame(validator.isObject(item) ? item.end_date : undefined)) {
        // New end date is not the same as old end date
        if (typeof onCheckDate === 'function') {
          const response = await onCheckDate()

          if (validator.isObject(response)) {
            const { errors, invalid } = response

            if (invalid === true && Array.isArray(errors) && errors.length > 0) {
              const { hasGap, hasOverlapped, neighbours, overlapped } = errors[0]
              let isEndDateOverlapped = false

              if (hasOverlapped) {
                if (Array.isArray(overlapped) && overlapped.length > 0) {
                  const _overlapped = overlapped.filter(
                    ({ start_date: sd, end_date: ed }) => (
                      moment(sd).isAfter(startDate) && (moment(sd).isSameOrBefore(endDate) || moment(ed).isSameOrBefore(endDate))
                    )
                  )

                  if (Array.isArray(_overlapped) && _overlapped.length > 0) {
                    const message = `existing rate set${_overlapped.length === 1 ? '' : 's'} as follow:`
                    error = new Error(`Selected period overlapped with ${message}`)
                    extra = getExtraMessage(_overlapped, linkLabel, onGetLink)
                    isEndDateOverlapped = true
                  }
                }
              }

              if (!isEndDateOverlapped && hasGap) {
                const _neighbours = neighbours.filter(
                  ({ start_date: sd, end_date: ed }) => moment(sd).isAfter(endDate) || moment(ed).isAfter(endDate)
                )

                if (Array.isArray(_neighbours) && _neighbours.length > 0) {
                  const message = `existing rate set${_neighbours.length === 1 ? '' : 's'} as follow:`
                  extra = getExtraMessage(_neighbours, linkLabel, onGetLink)
                  help = `Selected period has a gap with with ${message}`
                  validateStatus = 'warning'
                }
              }
            }
          }
        }
      }
    } else {
      error = new Error('Invalid validation params')
    }

    return { error, extra, help, validateStatus }
  },
  async validateStartDate (params) {
    let error
    let extra
    let help
    let validateStatus

    if (validator.isObject(params)) {
      const { startDate, endDate, item, linkLabel, onCheckDate, onGetLink } = params

      if (moment(startDate).isAfter(endDate)) {
        error = new Error('Start date must be before end date')
      } else if (!moment(startDate).isSame(validator.isObject(item) ? item.start_date : undefined)) {
        // New start date is not the same as old start date
        if (typeof onCheckDate === 'function') {
          const response = await onCheckDate()

          if (validator.isObject(response)) {
            const { errors, invalid } = response

            if (invalid === true && Array.isArray(errors) && errors.length > 0) {
              const { hasGap, hasOverlapped, neighbours, overlapped } = errors[0]
              let isStartDateOverlapped = false

              if (hasOverlapped) {
                if (Array.isArray(overlapped) && overlapped.length > 0) {
                  const _overlapped = overlapped.filter(
                    ({ start_date: sd, end_date: ed }) => moment(sd).isSameOrBefore(startDate) && moment(ed).isSameOrAfter(startDate)
                  )

                  if (Array.isArray(_overlapped) && _overlapped.length > 0) {
                    const message = `existing rate set${_overlapped.length === 1 ? '' : 's'} as follow:`
                    error = new Error(`Selected period overlapped with ${message}`)
                    extra = getExtraMessage(_overlapped, linkLabel, onGetLink)
                    isStartDateOverlapped = true
                  }
                }
              }

              if (!isStartDateOverlapped && hasGap) {
                const _neighbours = neighbours.filter(
                  ({ start_date: sd, end_date: ed }) => moment(sd).isBefore(startDate) || moment(ed).isBefore(startDate)
                )

                if (Array.isArray(_neighbours) && _neighbours.length > 0) {
                  const message = `existing rate set${_neighbours.length === 1 ? '' : 's'} as follow:`
                  extra = getExtraMessage(_neighbours, linkLabel, onGetLink)
                  help = `Selected period has a gap with with ${message}`
                  validateStatus = 'warning'
                }
              }
            }
          }
        }
      }
    } else {
      error = new Error('Invalid validation params')
    }

    return { error, extra, help, validateStatus }
  }
})
export default periodValidator