import React, { Component, useCallback } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'

import { validator } from '../../../../../util'

// UI
import Drawer from 'antd/lib/drawer'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'

import { Button } from '../../../../../components'

import './styles.css'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const { Item: FormItem } = Form
const { TextArea } = Input

function CommAddModal (props) {
  const { comm, form, saving, visible, onClose, onUpdateComm } = props
  const { getFieldDecorator, resetFields, validateFieldsAndScroll } = form || {}
  const isEdit = !validator.isNullOrUndefined(comm) && validator.isId(comm.id)

  const closeModal = useCallback(() => {
    if (saving) {
      return
    }

    resetFields()

    if (typeof onClose === 'function') {
      onClose()
    }
  }, [onClose, resetFields, saving])

  const handleSave = useCallback(() => {
    if (saving) {
      return
    }

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        if (isEdit && typeof onUpdateComm === 'function') {
          const { title, content } = values
          values.title = !validator.isNullOrUndefined(title) && !validator.isEmptyString(title)
            ? title.replace(/\n/g, ' ') : undefined
          values.content = !validator.isNullOrUndefined(content) && !validator.isEmptyString(content)
            ? content.replace(/\n/g, '<br />') : undefined
          await onUpdateComm(comm.id, values, closeModal)
        }
      }
    })
  }, [closeModal, onUpdateComm, validateFieldsAndScroll, comm, isEdit, saving])

  return (
    <Drawer
      className='pc-comm-modal'
      maskClosable={false}
      title={`${isEdit ? 'Edit' : 'Add'} Communication`}
      visible={visible}
      width='360'
      onClose={closeModal}
    >
      <Form layout='vertical'>
        <FormItem label='Subject'>
          {getFieldDecorator('title', {
            initialValue: isEdit ? comm.title : undefined,
            rules: [
              { required: true, message: 'Please enter subject' },
              { whitespace: true, message: 'Please enter subject' }
            ],
          })(<TextArea disabled={saving} rows={3} />)}
        </FormItem>

        <FormItem label='Body'>
          {getFieldDecorator('content', {
            initialValue: isEdit ? comm.content.replace(/<br\s*\/>/g, '\n') : undefined,
            rules: [
              { required: true, message: 'Please enter body' },
              { whitespace: true, message: 'Please enter body' }
            ],
          })(<TextArea disabled={saving} rows={9} />)}
        </FormItem>

        {getFieldDecorator('cr_id', {
          initialValue: isEdit ? comm.cr_id : undefined
        })(<Input type='hidden' />)}
      </Form>

      <div className='modal-button-box'>
        <Button ghost feedback={saving} onClick={closeModal}>Cancel</Button>
        <Button feedback={saving} onClick={handleSave}>{isEdit ? 'Update' : 'Add'}</Button>
      </div>
    </Drawer>
  )
}

export class PaceCommAddModal extends Component {
  render () {
    return <CommAddModal {...this.props} />
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { ...state.InvoicePace }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(PaceCommAddModal))
