import React, { Component } from 'react'
import PropTypes from 'prop-types'
import formatter from '../../util/formatter'

import './styles.css'

const PanelTypes = Object.freeze(['warn'])

export default class Panel extends Component {
  render () {
    const { children, className, subtitle, title, type } = this.props

    return (
      <div className={formatter.toClassName(['wd-panel', className, PanelTypes.indexOf(type) > -1 ? type : ''])}>
        {title || subtitle ? (
          <div className='wd-panel-title'>
            <div>{title}</div>

            <div>{subtitle}</div>
          </div>
        ) : null}

        <div className='wd-panel-body'>
          {children}
        </div>
      </div>
    )
  }
}

Panel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  subtitle: PropTypes.node,
  title: PropTypes.node,
  type: PropTypes.string
}
