export default {
  PARTICIPANT: {
    INFO: {
      LIST: 'listParticipant',
      READ: 'readParticipant',
      CREATE: 'createParticipant',
      UPDATE: 'updateParticipant',
      DELETE: 'deleteParticipant'
    },
    SB: {
      LIST: 'listParticipantServiceBooking',
      READ: 'readParticipantServiceBooking',
      CREATE: 'createParticipantServiceBooking',
      UPDATE: 'updateParticipantServiceBooking',
      DELETE: 'deleteParticipantServiceBooking'
    },
    PP_PACE: {
      LIST: 'listParticipantServiceBooking',
      READ: 'readParticipantServiceBooking',
      CREATE: 'createParticipantServiceBooking',
      UPDATE: 'updateParticipantServiceBooking',
      DELETE: 'deleteParticipantServiceBooking'
      // LIST: 'listParticipantPlanPeriodPace',
      // READ: 'readParticipantPlanPeriodPace',
      // CREATE: 'createParticipantPlanPeriodPace',
      // UPDATE: 'updateParticipantPlanPeriodPace',
      // DELETE: 'deleteParticipantPlanPeriodPace'
    },
    INVOICE: {
      LIST: 'listParticipantInvoices',
      READ: 'readParticipantInvoices',
      // CREATE: '',
      // UPDATE: '',
      // DELETE: ''
    },
    CREDIT: {
      LIST: 'listParticipantCredits',
      READ: 'readParticipantCredits',
      CREATE: 'createParticipantCredits',
      UPDATE: 'updateParticipantCredits',
      // DELETE: ''
    },
    STMTS: {
      LIST: 'listParticipantStatements',
      READ: 'readParticipantStatements',
      CREATE: 'createParticipantStatements',
      UPDATE: 'updateParticipantStatements',
      DELETE: 'deleteParticipantStatements'
    },
    FILES: {
      LIST: 'listParticipantFiles',
      READ: 'readParticipantFiles',
      CREATE: 'createParticipantFiles',
      UPDATE: 'updateParticipantFiles',
      DELETE: 'deleteParticipantFiles'
    }
  },
  PROVIDER: {
    INFO: {
      LIST: 'listProvider',
      READ: 'readProvider',
      CREATE: 'createProvider',
      UPDATE: 'updateProvider',
      DELETE: 'deleteProvider'
    },
    INVOICE: {
      LIST: 'listProviderInvoice',
      READ: 'readProviderInvoice',
      // CREATE: '',
      // UPDATE: '',
      // DELETE: ''
    }
  },
  STATEMENT: {
    INFO: {
      LIST: 'listStatements',
      READ: 'readStatements',
      EMAIL: 'emailStatements',
      DOWNLOAD: 'downloadStatements',
    },
  },
  INVOICE: {
    INFO: {
      LIST: 'listInvoice',
      READ: 'readInvoice',
      CREATE: 'createInvoice',
      UPDATE: 'updateInvoice',
      DELETE: 'deleteInvoice'
    },
    INFO_PACE: {
      LIST: 'listInvoice',
      READ: 'readInvoice',
      CREATE: 'createInvoice',
      UPDATE: 'updateInvoice',
      DELETE: 'deleteInvoice'
      // LIST: 'listInvoicePace',
      // READ: 'readInvoicePace',
      // CREATE: 'createInvoicePace',
      // UPDATE: 'updateInvoicePace',
      // DELETE: 'deleteInvoicePace'
    },
    FILES: {
      LIST: 'listInvoiceFiles',
      READ: 'readInvoiceFiles',
      CREATE: 'createInvoiceFiles',
      UPDATE: 'updateInvoiceFiles',
      DELETE: 'deleteInvoiceFiles'
    },
    FILES_PACE: {
      LIST: 'listInvoiceFiles',
      READ: 'readInvoiceFiles',
      CREATE: 'createInvoiceFiles',
      UPDATE: 'updateInvoiceFiles',
      DELETE: 'deleteInvoiceFiles'
      // LIST: 'listInvoiceFilesPace',
      // READ: 'readInvoiceFilesPace',
      // CREATE: 'createInvoiceFilesPace',
      // UPDATE: 'updateInvoiceFilesPace',
      // DELETE: 'deleteInvoiceFilesPace'
    },
    CREDIT_APPLY: {
      LIST: 'listInvoiceApplyCredit',
      READ: 'readInvoiceApplyCredit',
      CREATE: 'createInvoiceApplyCredit',
      UPDATE: 'updateInvoiceApplyCredit',
      // DELETE: ''
    },
    CREDIT_APPLY_PACE: {
      LIST: 'listInvoiceApplyCredit',
      READ: 'readInvoiceApplyCredit',
      CREATE: 'createInvoiceApplyCredit',
      UPDATE: 'updateInvoiceApplyCredit'
      // LIST: 'listInvoiceApplyCreditPace',
      // READ: 'readInvoiceApplyCreditPace',
      // CREATE: 'createInvoiceApplyCreditPace',
      // UPDATE: 'updateInvoiceApplyCreditPace'
    },
    MGMT: {
      LIST: 'listInvoiceManagement',
      READ: 'readInvoiceManagement',
      CREATE: 'updateInvoiceManagement',
      // UPDATE: '',
      // DELETE: ''
    },
    MGMT_PACE: {
      LIST: 'listInvoiceManagement',
      READ: 'readInvoiceManagement',
      CREATE: 'updateInvoiceManagement'
      // LIST: 'listInvoiceManagementPace',
      // READ: 'readInvoiceManagementPace',
      // CREATE: 'updateInvoiceManagementPace'
    }
  },
  CREDIT: {
    INFO: {
      LIST: 'listCredit',
      READ: 'readCredit',
      CREATE: 'createCredit',
      UPDATE: 'updateCredit',
      DELETE: 'deleteCredit'
    },
    INFO_PACE: {
      LIST: 'listCredit',
      READ: 'readCredit',
      CREATE: 'createCredit',
      UPDATE: 'updateCredit',
      DELETE: 'deleteCredit'
      // LIST: 'listCreditPace',
      // READ: 'readCreditPace',
      // CREATE: 'createCreditPace',
      // UPDATE: 'updateCreditPace',
      // DELETE: 'deleteCreditPace'
    }
  },
  REPORT: {
    SCHEDULER: {
      LIST: 'listReportGenerateRequest',
      READ: 'readReportGenerateRequest',
      CREATE: 'createReportGenerateRequest',
    },
    SCHEDULER_PACE: {
      LIST: 'listReportGenerateRequest',
      READ: 'readReportGenerateRequest',
      CREATE: 'createReportGenerateRequest',
      // LIST: 'listReportGenerateRequestPace',
      // READ: 'readReportGenerateRequestPace',
      // CREATE: 'createReportGenerateRequestPace'
    },
    REPORT_SB: {
      LIST: 'listReportServiceBooking',
    },
    RECIPIENT_SETTING: {
      LIST: 'listReportRecipientSetting',
      UPDATE: 'updateReportRecipientSetting',
    },
    REPORT_PENDING_INVOICES: {
      LIST: 'listToReceiveInvoices',
    },
  },
  SETTING: {
    CONTACTS_EXPORT: {
      LIST: 'listSettingContacts',
      // READ: 'readSettingContacts',
      // CREATE: 'createSettingContacts',
      // UPDATE: 'updateSettingContacts',
      // DELETE: 'deleteSettingContacts'
    },
    CUSTOM_IDF: {
      LIST: 'listSettingCustomIdentifier',
      READ: 'readSettingCustomIdentifier',
      CREATE: 'createSettingCustomIdentifier',
      UPDATE: 'updateSettingCustomIdentifier',
      DELETE: 'deleteSettingCustomIdentifier'
    },
    FILES: {
      LIST: 'listSettingFiles',
      READ: 'readSettingFiles',
      CREATE: 'createSettingFiles',
      UPDATE: 'updateSettingFiles',
      DELETE: 'deleteSettingFiles'
    },
    GST: {
      LIST: 'listSettingGst',
      READ: 'readSettingGst',
      CREATE: 'createSettingGst',
      UPDATE: 'updateSettingGst',
      DELETE: 'deleteSettingGst'
    },
    PROFILE: {
      LIST: 'listSettingProfile',
      READ: 'readSettingProfile',
      CREATE: 'createSettingProfile',
      UPDATE: 'updateSettingProfile',
      DELETE: 'deleteSettingProfile'
    },
    PLANGUAGE: {
      LIST: 'listSettingProfileLanguage',
      READ: 'readSettingProfileLanguage',
      CREATE: 'createSettingProfileLanguage',
      UPDATE: 'updateSettingProfileLanguage',
      DELETE: 'deleteSettingProfileLanguage'
    },
    PRELATIONSHIP: {
      LIST: 'listSettingProfileRelationship',
      READ: 'readSettingProfileRelationship',
      CREATE: 'createSettingProfileRelationship',
      UPDATE: 'updateSettingProfileRelationship',
      DELETE: 'deleteSettingProfileRelationship'
    },
    PACCREF: {
      LIST: 'listSettingProfileAccRef',
      READ: 'readSettingProfileAccRef',
      CREATE: 'createSettingProfileAccRef',
      UPDATE: 'updateSettingProfileAccRef',
      DELETE: 'deleteSettingProfileAccRef'
    },
    REASON: {
      LIST: 'listSettingReasons',
      READ: 'readSettingReasons',
      CREATE: 'createSettingReasons',
      UPDATE: 'updateSettingReasons',
      DELETE: 'deleteSettingReasons'
    },
    RATESETS: {
      LIST: 'listSettingRateSet',
      READ: 'readSettingRateSet',
      CREATE: 'createSettingRateSet',
      UPDATE: 'updateSettingRateSet',
      DELETE: 'deleteSettingRateSet'
    },
    RATESETS_PACE: {
      LIST: 'listSettingRateSet',
      READ: 'readSettingRateSet',
      CREATE: 'createSettingRateSet',
      UPDATE: 'updateSettingRateSet',
      DELETE: 'deleteSettingRateSet'
      // LIST: 'listSettingRateSetsPace',
      // READ: 'readSettingRateSetsPace',
      // CREATE: 'createSettingRateSetsPace',
      // UPDATE: 'updateSettingRateSetsPace',
      // DELETE: 'deleteSettingRateSetsPace'
    },
    RATESET_IMPORT: {
      LIST: 'listSettingRateSetImport',
      READ: 'readSettingRateSetImport',
      // CREATE: 'createSettingRateSetImport',
      UPDATE: 'updateSettingRateSetImport',
      // DELETE: 'deleteSettingRateSetImport'
    },
    RATESET_IMPORT_PACE: {
      LIST: 'listSettingRateSetImport',
      READ: 'readSettingRateSetImport',
      UPDATE: 'updateSettingRateSetImport'
      // LIST: 'listSettingRateSetImportPace',
      // READ: 'readSettingRateSetImportPace',
      // UPDATE: 'updateSettingRateSetImportPace'
    },
    RATESET_GROUPINGS_PACE: {
      LIST: 'listSettingRateSet',
      READ: 'readSettingRateSet',
      CREATE: 'createSettingRateSet',
      UPDATE: 'updateSettingRateSet',
      DELETE: 'deleteSettingRateSet'
      // LIST: 'listSettingRateSetGroupingsPace',
      // READ: 'readSettingRateSetGroupingsPace',
      // CREATE: 'createSettingRateSetGroupingsPace',
      // UPDATE: 'updateSettingRateSetGroupingsPace',
      // DELETE: 'deleteSettingRateSetGroupingsPace'
    },
    CATS: {
      LIST: 'listSettingCategories',
      READ: 'readSettingCategories',
      CREATE: 'createSettingCategories',
      UPDATE: 'updateSettingCategories',
      DELETE: 'deleteSettingCategories'
    },
    CATEGORIES_PACE: {
      LIST: 'listSettingCategories',
      READ: 'readSettingCategories',
      CREATE: 'createSettingCategories',
      UPDATE: 'updateSettingCategories',
      DELETE: 'deleteSettingCategories'
      // LIST: 'listSettingCategoriesPace',
      // READ: 'readSettingCategoriesPace',
      // CREATE: 'createSettingCategoriesPace',
      // UPDATE: 'updateSettingCategoriesPace',
      // DELETE: 'deleteSettingCategoriesPace'
    },
    INV_IMPORT: {
      LIST: 'listSettingInvoiceImport',
      // READ: '',
      CREATE: 'createSettingInvoiceImport',
      // UPDATE: '',
      // DELETE: ''
    },
    OTHERS: {
      LIST: 'listSettingOther',
      UPDATE: 'updateSettingOther',
    }
  }
}
