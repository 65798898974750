import { request, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/pm-rate-set-category-configs-pace'

export default {
  getAll (filter) {
    return request(`${url}${queryString.stringify({ filter: JSON.stringify(filter) })}`)
  },
  saveAll (values) {
    return request(`${url}/all`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  }
}
