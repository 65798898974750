import { request, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/pm-rate-set-categories-pace'

export default {
  get (id) {
    return request(`${url}/${id}`)
  },
  getAll (filter) {
    return request(`${url}${queryString.stringify({ filter: JSON.stringify(filter) })}`)
  },
  getAllUniques (filter) {
    return request(`${url}/uniques${queryString.stringify({ filter: JSON.stringify(filter) })}`)
  },
  listByPage (page, size, filter, sorting, text) {
    return request(`${url}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  save (id, values) {
    return request(`${url}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  }
}
