import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { fetchInvoices } from '../../../states/actions/invoice'
// import { fetchTotalPending } from '../../../states/actions'
import { InvoiceMenu, InvoiceFilterType, InvoiceListType, InvoiceStatus, Permissions } from '../../../constants'
import { auth, common, exportFile, formatter, validator } from '../../../util'
import { authService } from '../../../services'

// UI
import { Button, Page, Pager, ControlLabel, SearchInput } from '../../../components'
import notify from '../../../components/Notification'

import './styles.css'

import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Icon from 'antd/lib/icon'
import Skeleton from 'antd/lib/skeleton'
import Tooltip from 'antd/lib/tooltip'

const { RangePicker } = DatePicker

const pageSize = 20

const displayFormat = 'DD/MM/YYYY'

const getFilterParams = (filter, value = '') => {
  if (!validator.isObject(filter)) {
    filter = {}
  }

  if (value.indexOf(' ') >= 0) {
    const words = value.split(' ')

    if (Array.isArray(words)) {
      filter.$and = []

      for (let i = 0; i < words.length; i++) {
        if (words[i]) {
          filter.$and.push({
            $or: [
              { id_numbering: { condition: 'ilike', value: `%${words[i]}%` } },
              { client_first_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { client_last_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { client_acc_ref: { condition: 'ilike', value: `%${words[i]}%` } },
              { client_ndis_number: { condition: 'ilike', value: `%${words[i]}%` } },
              { provider_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { provider_acc_ref: { condition: 'ilike', value: `%${words[i]}%` } },
              { provider_abn: { condition: 'ilike', value: `%${words[i]}%` } },
              { string_invoice_date: { condition: 'ilike', value: `%${words[i]}%` } },
              { string_invoice_created_date: { condition: 'ilike', value: `%${words[i]}%` } },
              { string_last_received_date: { condition: 'ilike', value: `%${words[i]}%` } },
              { string_payment_date: { condition: 'ilike', value: `%${words[i]}%` } },
              { invoice_number: { condition: 'ilike', value: `%${words[i]}%` } },
              { status: { condition: 'ilike', value: `%${words[i]}%` } }
            ]
          })
        }
      }
    }
  } else {
    if (Array.isArray(filter.$and)) {
      delete filter.$and
    }
  }

  return filter
}

export class InvoiceList extends Component {
  constructor (props) {
    super(props)
    // const { jobs: { list, total }, loading } = props
    this.state = {
      jobs: { list: [], total: 0 },
      currentPage: 1,
      filter: {},
      filterType: '',
      filterParam: InvoiceFilterType.FILTER_INCOMPLETED,
      list: [],
      loading: false,
      searching: false,
      searchText: '',
      showJobModal: false,
      showEndDate: true,
      sort: {},
      total: 0,
      isGenerating: false,
      isShowDateRangePicker: false
    }
    this.onSearchName = debounce(this.onSearchName, 500)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { invoices: { list, total }, loading, location, match, fetchInvoices } = nextProps

    const { type = InvoiceListType.INV_LIST_ALL } = common.getPath(match)
    const { page = 1, q, status = InvoiceFilterType.FILTER_INCOMPLETED } = common.getQueryStringSearchParams(location.search)
    const filter = getFilterParams(prevState.filter, q)

    if (type) {
      if (type === InvoiceListType.INV_LIST_PM) {
        filter.is_sdb_invoice = { condition: '=', value: false }
      } else if (type === InvoiceListType.INV_LIST_STD) {
        filter.is_sdb_invoice = { condition: '=', value: true }
      } else {
        delete filter.is_sdb_invoice
      }
    } else {
      delete filter.is_sdb_invoice
    }

    if (status === InvoiceFilterType.FILTER_INCOMPLETED) {
      filter.status = { condition: '<>', value: 'inv-closed' }
    } else if (status === InvoiceFilterType.FILTER_CLOSED) {
      filter.status = { condition: '=', value: 'inv-closed' }
    } else if (status === InvoiceFilterType.FILTER_ALL) {
      delete filter.status
    }

    if (prevState.filterType !== type) {
      fetchInvoices({ loading: true, currentPage: 1, pageSize, filter, sort: prevState.sort, searchText: filter.$and ? '' : prevState.searchText })
    }

    const state = {
      ...prevState,
      list,
      currentPage: page && !isNaN(parseInt(page)) ? parseInt(page) : prevState.currentPage || 1,
      filter,
      filterParam: status || InvoiceFilterType.FILTER_INCOMPLETED,
      filterType: type,
      loading,
      searchText: q || '',
      total
    }

    if (list !== prevState.list) {
      state.searching = false
    }
    return state
  }

  componentDidMount () {
    const { currentPage, filter, loading, searchText, sort } = this.state
    this.fetchInvoices({ currentPage, filter, loading, searchText, sort })
  }

  /** Search by date currently only search using job_start_date */
  onFilterInvoice = (e) => {
    const { filter, loading, searchText, sort } = this.state
    const filterValue = e.target.value

    if (filterValue === InvoiceFilterType.FILTER_INCOMPLETED) {
      filter.status = { condition: '<>', value: 'inv-closed' }
    } else if (filterValue === InvoiceFilterType.FILTER_CLOSED) {
      filter.status = { condition: '=', value: 'inv-closed' }
    } else if (filterValue === InvoiceFilterType.FILTER_ALL) {
      delete filter.status
    }

    this.setState({ filter, filterParam: filterValue })
    this.redirectUrl({ page: 1, q: searchText, status: filterValue })
    this.fetchInvoices({ currentPage: 1, filter, loading: true, searchText })
  }

  onSearchName (value) {
    const { filter, filterParam, loading, sort } = this.state
    this.setState({ searching: true })

    const newFilter = getFilterParams(filter, value)

    this.redirectUrl({ page: 1, q: value, status: filterParam })
    this.fetchInvoices({ currentPage: 1, filter: newFilter, loading, searchText: value, sort })
    this.setState({ searchText: value, currentPage: 1 })
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  /** query = { page, q }  */
  redirectUrl = (query) => {
    const { history, location } = this.props
    const params = new URLSearchParams(query)

    history.replace({ pathname: location.pathname, search: params.toString() })
  }

  getTitle () {
    const { filterType } = this.state

    if (filterType === InvoiceListType.INV_LIST_PM) {
      return 'PM Invoices'
    } else if (filterType === InvoiceListType.INV_LIST_STD) {
      return 'Standard Invoices'
    }

    return 'Invoices'
  }

  render () {
    const { currentPage, filterParam, isGenerating, isShowDateRangePicker, list, loading, searching, searchText, total } = this.state

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={InvoiceMenu} backLink='/' countData={this.props.totalPending} />
        </Page.Left>
        <Page.Content full>
          <Page.Header title={this.getTitle()}>
            { this.hasAccess(Permissions.INVOICE.INFO.LIST)
              ? <Button feedback={isGenerating} onClick={() => this.toggleExportSelect()}>
                EXPORT
              </Button>
              : null }

            { this.hasAccess(Permissions.INVOICE.INFO.CREATE)
                ? <Link to='/invoices/add'>
                  <div className='btn'>
                    New Invoice
                  </div>
                </Link>
                : null }
          </Page.Header>

          { this.hasAccess(Permissions.INVOICE.INFO.LIST)
            ? <Page.Filter>
              <Row gutter={8}>
                <Col lg={8}>
                  <ControlLabel>Date, Participant, Provider, Invoice Number, Status</ControlLabel>
                  <SearchInput placeholder='Search' onChange={(v) => this.onSearchName(v)} value={searchText} isSearching={searching} />
                </Col>

                <Col lg={16} style={{display: 'flex', flexiDirection: 'horizontal', justifyContent: 'flex-end'}}>
                  { isShowDateRangePicker
                    ? <RangePicker format='DD/MM/YYYY' open={isShowDateRangePicker} onChange={(d1) => this.export(d1)}/>
                    : <Col lg={24}>
                      <div style={{marginLeft: '12.5pt'}}>
                        <ControlLabel>Invoices Completion</ControlLabel>
                      </div>
                      <Radio.Group onChange={this.onFilterInvoice} value={filterParam} style={{ marginLeft: 20 }}>
                        <Radio.Button value={InvoiceFilterType.FILTER_INCOMPLETED}>Incompleted</Radio.Button>
                        <Radio.Button value={InvoiceFilterType.FILTER_CLOSED}>Closed</Radio.Button>
                        <Radio.Button value={InvoiceFilterType.FILTER_ALL}>All</Radio.Button>
                      </Radio.Group>
                    </Col> }
                </Col>
              </Row>
            </Page.Filter>
            : null }


          <div className='invoices'>
            <Skeleton loading={loading} active>
              {validator.isNotEmptyArray(list) && list.map((item) => {
                const {
                  client_id: clientId,
                  client_ref_id: clientRefId,
                  client_name: clientName,
                  created_at: createdAt,
                  id,
                  id_numbering: idNumbering,
                  invoice_date: invDate,
                  invoice_number: invNumber,
                  last_received_date: lastReceivedDate,
                  payment_date: paymentDate,
                  provider_id: providerId,
                  provider_ref_id: providerRefId,
                  provider_name: providerName,
                  qty,
                  received_subtotal: rcvSubtotal,
                  ref_id: refId,
                  status,
                  status_name: statusName,
                  status_color: statusColor,
                  subtotal
                } = item

                const statusStyle = statusColor ? { backgroundColor: `${statusColor}` } : {}

                return (
                  <div className={`list-item`} key={id}>
                    <Row>
                      <Col lg={23}>
                        <Row style={{ borderBottom: '1px dotted #ccc', paddingBottom: 2 }}>
                          <Col lg={2}>
                            <div className='name title'>{idNumbering ? idNumbering.toUpperCase() : `JID-${refId}`}</div>
                          </Col>
                          <Col lg={6}>
                            <Row>
                              <Col lg={10}>
                                <div className='subtitle'>Process Date</div>
                              </Col>
                              <Col lg={11}>
                                <div className='name date'>{formatter.toDate(createdAt, displayFormat)}</div>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={8}>
                            <Row>
                              <Col lg={6}>
                                <div className='subtitle'>Provider</div>
                              </Col>
                              <Col lg={14}>
                                <div className='name'><a href={`/providers/${providerRefId}/info`} rel='noopener noreferrer' target='_blank'>{providerName}</a></div>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={8}>
                            <Row>
                              <Col lg={8}>
                                <div className='subtitle'>Participant</div>
                              </Col>
                              <Col lg={14}>
                                <div className='name'><a href={`/participants/${clientRefId}/info`} rel='noopener noreferrer' target='_blank'>{clientName}</a></div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row style={{ paddingTop: '6px' }}>
                          <Col lg={9}>
                            <Row>
                              <Col lg={6}>
                                <div className='header date'>Invoice No</div>
                              </Col>
                              <Col lg={17}>
                                <div className='value date'>{invNumber}</div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={6}>
                                <div className='header date'>Invoice Date</div>
                              </Col>
                              <Col lg={17}>
                                <div className='value date'>{formatter.toDate(invDate, displayFormat)}</div>
                              </Col>
                            </Row>
                          </Col>
                          {/* <Col lg={5}>
                            <Row>
                              <Col lg={10}>
                                <div className='header date'>Invoice Date</div>
                              </Col>
                              <Col lg={12}>
                                <div className='value date'>{formatter.toDate(invDate, displayFormat)}</div>
                              </Col>
                            </Row>
                          </Col> */}
                          <Col lg={6}>
                            <Row>
                              <Col lg={12}>
                                <div className='header inv-amount'>Total Inv Amt</div>
                              </Col>
                              <Col lg={7}>
                                <div className='value inv-amount'>{formatter.toPrice(subtotal)}</div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={12}>
                                <div className='header rcv-amount'>Total Rcv Amt</div>
                              </Col>
                              <Col lg={7}>
                                <div className='value rcv-amount'>{formatter.toPrice(rcvSubtotal)}</div>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6}>
                            <Row>
                              <Col lg={14}>
                                <div className='header date'>Last Rcv Date</div>
                              </Col>
                              <Col lg={10}>
                                <div className='value date'>{lastReceivedDate ? formatter.toDate(lastReceivedDate, displayFormat) : 'N/A'}</div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={14}>
                                <div className='header date'>Last Payment Date</div>
                              </Col>
                              <Col lg={10}>
                                <div className='value date'>{paymentDate ? formatter.toDate(paymentDate, displayFormat) : 'N/A'}</div>
                              </Col>
                            </Row>
                          </Col>
                          {/* <Col lg={1} /> */}
                          <Col lg={3}>
                            <div className={'status'} style={statusStyle}>
                              {statusName ? statusName : formatter.capitalize(status)}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={1} className='action-icon'>
                        { this.hasAccess(Permissions.INVOICE.INFO.READ)
                          ? <Link to={`/invoices/${refId}/info`}>
                            <div style={{ color: '#D66E00' }}>
                              <Tooltip mouseLeaveDelay={0} title='Manage invoice'>
                                <Icon type='form' />
                              </Tooltip>
                            </div>
                          </Link>
                          : null }
                      </Col>
                    </Row>
                  </div>
                )
              })}
            </Skeleton>
          </div>

          { !loading
            ? <Pager
              current={currentPage}
              size={pageSize}
              total={total}
              totalText={`Total ${total} invoices`}
              onChange={this.changePage}
              style={{ marginTop: '15px' }}
            />
            : null }

        </Page.Content>
      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, filterParam, searchText, sort } = this.state
    this.redirectUrl({ page: currentPage, q: searchText, status: filterParam })
    this.fetchInvoices({ currentPage, filter, searchText, sort })
  }

  fetchInvoices = async ({ loading = true, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    if (!this.hasAccess(Permissions.INVOICE.INFO.LIST)) return

    try {
      const { fetchInvoices } = this.props
      this.setState({ currentPage })
      fetchInvoices({ loading, currentPage, pageSize, filter, sort, searchText: filter.$and ? '' : searchText })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load invoices successfully. Please try again later.')
    }
  }

  toggleExportSelect = () => {
    this.setState({ isShowDateRangePicker: !this.state.isShowDateRangePicker })
  }

  async export (date) {
    this.setState({ isShowDateRangePicker: false, isGenerating: true })

    if (validator.isNotEmptyArray(date) && date.length >= 2) {
      const data = {
        start_date: date[0],
        end_date: date[1]
      }

      const r = await exportFile.fetchExport('invoice', data)
    } else {
      notify.error('Unable to export invoices', 'Invalid date range selected.')
    }

    setTimeout(() => {
      this.setState({ isGenerating: false })
    }, 2000)
  }

}

const mapDispatchToProps = {
  fetchInvoices
}

const mapStateToProps = (state) => {
  return { ...state.Invoice }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(InvoiceList))
